import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getNode } from '../../../global.component';

@Injectable({
  providedIn: 'root',
})
export class StatsService {

  constructor(private http: HttpClient) {}

  stats(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v2/stats/GetStatViewRequest', body, httpOptions);
  }

  kpiCampaign(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/ListStatKPIsRequest', body, httpOptions);
  }

  statsCampaign(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v2/stats/GetCompareStatViewRequest', body, httpOptions);
  }

  simStats(body){
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v2/simstats/ListSimStatVOsRequest', body, httpOptions);
  }

  getStats = async (body) => this.stats(body).toPromise();
  getKpiCampaign = async (body) => this.kpiCampaign(body).toPromise();
  getStatsCampaign = async (body) => this.statsCampaign(body).toPromise();
  getSimStats = async (body) => this.simStats(body).toPromise();
}
