import { updateAppGlobals } from '../global.component';

const hostname = window.location.hostname;

export const validateTypeResource = (keyword, type) => {
  const account = 'account';
  delete keyword[account];
  
  // validate if node exist
  if (localStorage.getItem('node')) {
    const isNode = Object.keys(keyword).find(key => validateNode(key, type) === localStorage.getItem('node'));
    if (isNode) { return true; }
  }

  // else
  const node = Object.keys(keyword).find(key => key.split('-').length === type);
  node && (updateAppGlobals(validateNode(node, type)));
  return node;
};

export const validateNode = (key, type) => {
  let finalNode;
  const splitNode = key.split('-');
  if (type === 5) {
    // finalNode = `${getNetwork()}/aircast-api/${splitNode[3]}-${splitNode[4]}`;
    if(hostname.endsWith('dev.mobiera.io'))
      finalNode = `https://${splitNode[3]}-${splitNode[4]}.aircast.dev.mobiera.io`;
    else if(hostname.endsWith('mobiera.io'))
      finalNode = `https://${splitNode[3]}-${splitNode[4]}.aircast.mobiera.io`;
  } else {
    // finalNode = `http://${splitNode[3]}-${splitNode[4]}-${splitNode[5]}.aircast.tst:8080`;
    finalNode = `https://${splitNode[3]}-${splitNode[4]}.aircast.dev.mobiera.io`;
  }
  finalNode += '/aircast-slave-api-rest-server-war';

  return finalNode;
};

export const operationSelected = (operation) => {
  updateAppGlobals(operation.url);
  window.localStorage.removeItem('home');
  window.localStorage.removeItem('menu');
  window.localStorage.removeItem('timezone');
  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export const getNetwork = () => {
  if (window.location.href.indexOf('http://localhost:4200') > -1) {
    const localProd = false;
    return localProd?'https://mobiera.com':'https://test.mobiera.com';
  }
  else if (window.location.href.indexOf('https://mobiera.com') > -1) {
    return 'https://mobiera.com';
  } else if (window.location.href.indexOf('https://aircast.int') > -1) {
    return 'https://aircast.int';
  } else if (window.location.href.indexOf('https://192.168.253.73') > -1) {
    return 'https://mobiera.com';
  } else if (hostname.endsWith('dev.mobiera.io')) {
    return 'https://auth.dev.mobiera.io';
  } else if (hostname.endsWith('mobiera.io')) {
    return 'https://auth.mobiera.io';
  } else {
    return 'https://test.mobiera.com';
  }
};

