import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { validateForm } from '../../../utils/formUtils';
import { mapKeys } from 'lodash';

import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { inputValidation } from '../../../utils/dataUtils';


@Component({
  selector: 'app-smsc-bind-configuration',
  templateUrl: './smsc-bind-configuration.component.html',
  styleUrls: ['./smsc-bind-configuration.component.css']
})
export class SmscBindConfigurationComponent implements OnInit, OnChanges {

  @Input() data;
  @Input() voName;
  @Input() state;
  @Input() setBinds;
  @Input() restore;
  @Output() getBinds = new EventEmitter();
  @Output() setEditing = new EventEmitter();
  @Output() getOptions = new EventEmitter();

  showSmsc = false;
  DescribeVO;
  fields = 'fields';
  values = 'values';
  newData = {};
  newBinds = [];

  @ViewChildren('Input') inputs: QueryList<[]>;

  constructor(private introspectionService: IntrospectionService) { }

  ngOnInit() {
    this.getDescribeVORequest();
    this.newBinds = this.data.slice(0);
    this.newBinds.map((binds) => binds.deleted = false);
  }

  async ngOnChanges() {
    this.setBinds && this.getBinds.emit(await this.validateBinds());
    this.restore && (this.newBinds = this.data.slice(0));
  }

  getOptionsChild(field) {
    this.getOptions.emit(field);
  }

  async validateBinds() {
    this.newBinds.map(
      (item, index) => mapKeys(
        item, (value, key) => this.newData[key + '-' + index] = value
      )
    );
    this.newBinds = this.newBinds.filter((bind) => !bind.deleted);
    await validateForm(this.inputs, this.newData);
    this.newBinds.map(
      (item, index) => (mapKeys(
        item, (value, key) => item[key] = this.newData[key + '-' + index]
      ), delete item.deleted)
    );
    const hasEmpty = await validateForm(this.inputs, this.newData);
    return !hasEmpty ? this.newBinds : false;
  }

  async getDescribeVORequest() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.voName);
    this.showSmsc = true;
    this.DescribeVO = resDescribe[this.fields];
  }

  isRequired(input) {
    return input ? true : false;
  }

  inputValidation(id) {
    return inputValidation(id);
  }

  removeSmsc(index) {
    this.setEditing.emit();
    this.newBinds[index].deleted = !this.newBinds[index].deleted;
  }

  addSmsc() {
    this.setEditing.emit();
    const newBind = {};
    this.DescribeVO.map((item) => newBind[item.name] = '');
    this.newBinds.push(newBind);
  }

  showError(data) {
    let info = 'Invalid Data';
    data.minSize && (info = info + ', min size: ' + data.minSize);
    data.maxSize && (info = info + ' and max size: ' + data.maxSize);
    data.minValue && (info = info + ', min value: ' + data.minValue);
    data.maxValue && (info = info + ' and max value: ' + data.maxValue);
    return info;
  }

}
