import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HeaderModule,
  BodySimpleModule,
  CardGridModule,
  CardModule,
  StateSelectModule,
  SnackbarModule,
  ButtonSimpleModule,
  InputSimpleModule,
  SelectOptionModule,
  RadioButtonModule,
  DatepickerModule,
  TextareaSimpleModule,
  SelectMultipleModule,
  InputFileModule,
  ProgressBarModule,
  TablePrimaryModule,
  SlideToggleModule,
  CheckboxModule,
  SelectMultipleSearchModule
} from 'turing';

import { GoogleChartsModule } from 'angular-google-charts';

import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './app.authguard';
import { initializer } from './utils/app-init';

import { UniversalTraductorService } from './services/universal-traductor.service';
import { EndpointsService } from './endpoints/endpoints.service';
import { PricepointsService } from './pricepoints/pricepoints.service';
import { IdentifiersService } from './identifiers/identifiers.service';
import { VaServicesService } from './vaservices/vaservices.service';
import { LandingsService } from './landings/landings.service';
import { AppletsService } from './applets/applets.service';
import { TpRulesService } from './tp-rules/tp-rules.service';
import { SimProfilesService } from './sim-profiles/sim-profiles.service';
import { IntrospectionService } from './services/introspection/introspection.service';
import { ParametersService } from './parameters/parameters.service';
import { SmppAccountsService } from './smpp-accounts/smpp-accounts.service';
import { ListService } from './services/list/list.service';
import { StatsService } from './common/section/stats/stats.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { EndpointsComponent } from './endpoints/endpoints.component';
import { EndpointComponent } from './endpoints/endpoints-list/endpoint/endpoint.component';
import { EndpointsListComponent } from './endpoints/endpoints-list/endpoints-list.component';
import { IdentifiersComponent } from './identifiers/identifiers.component';
import { IdentifierComponent } from './identifiers/identifiers-list/identifier/identifier.component';
import { IdentifiersListComponent } from './identifiers/identifiers-list/identifiers-list.component';
import { PricepointsComponent } from './pricepoints/pricepoints.component';
import { PricepointComponent } from './pricepoints/pricepoints-list/pricepoint/pricepoint.component';
import { PricepointsListComponent } from './pricepoints/pricepoints-list/pricepoints-list.component';
import { VaServicesComponent } from './vaservices/vaservices.component';
import { VaServiceComponent } from './vaservices/vaservices-list/vaservice/vaservice.component';
import { VaServicesListComponent } from './vaservices/vaservices-list/vaservices-list.component';
import { LandingsComponent } from './landings/landings.component';
import { LandingComponent } from './landings/landings-list/landing/landing.component';
import { LandingsListComponent } from './landings/landings-list/landings-list.component';
import { AppletsComponent } from './applets/applets.component';
import { AppletsListComponent } from './applets/applets-list/applets-list.component';
import { AppletComponent } from './applets/applets-list/applet/applet.component';
import { SectionComponent } from './common/section/section.component';
import { ListComponent } from './common/list/list.component';
import { TypeComponent } from './common/type/type.component';
import { CardComponent } from './common/card/card.component';
import { TpRulesComponent } from './tp-rules/tp-rules.component';
import { TpRulesListComponent } from './tp-rules/tp-rules-list/tp-rules-list.component';
import { TpRuleComponent } from './tp-rules/tp-rules-list/tp-rule/tp-rule.component';
import { SimProfilesComponent } from './sim-profiles/sim-profiles.component';
import { SimProfilesListComponent } from './sim-profiles/sim-profiles-list/sim-profiles-list.component';
import { SimProfileComponent } from './sim-profiles/sim-profiles-list/sim-profile/sim-profile.component';
import { ParametersComponent } from './parameters/parameters.component';
import { ParametersFormComponent } from './parameters/parameters-form/parameters-form.component';
import { SmppAccountsComponent } from './smpp-accounts/smpp-accounts.component';
import { SmppAccountsListComponent } from './smpp-accounts/smpp-accounts-list/smpp-accounts-list.component';
import { SmppAccountComponent } from './smpp-accounts/smpp-accounts-list/smpp-account/smpp-account.component';
import { SmscBindConfigurationComponent } from './common/section/smsc-bind-configuration/smsc-bind-configuration.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignsListComponent } from './campaigns/campaigns-list/campaigns-list.component';
import { CampaignComponent } from './campaigns/campaigns-list/campaign/campaign.component';
import { AddComponent } from './common/add/add.component';
import { SleepyFlowActionsComponent } from './common/section/sleepy-flow-actions/sleepy-flow-actions.component';
import { TextComponent } from './common/section/sleepy-flow-actions/text/text.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { EventComponent } from './schedule/event/event.component';
import { FilesComponent } from './files/files.component';
import { FilesListComponent } from './files/files-list/files-list.component';
import { FileComponent } from './files/files-list/file/file.component';
import { AddFileComponent } from './files/files-list/file/add-file/add-file.component';
import { StatsComponent } from './common/section/stats/stats.component';
import { StatsViewComponent } from './stats-view/stats-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SimsComponent } from './sims/sims.component';
import { RamCommandComponent } from './common/section/ram-command/ram-command.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartCardComponent } from './dashboard/chart-card/chart-card.component';
import { AdvertisingAdsComponent } from './common/section/advertising-ads/advertising-ads.component'
import { MenuAdComponent } from './common/section/advertising-ads/menu-ad/menu-ad.component'
import { SubscriptionsService } from './subscriptions/subscriptions.service';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SubscriptionsListComponent } from './subscriptions/subscriptions-list/subscriptions-list.component';
import { ListAndViewComponent } from './common/list-and-view/list-and-view.component';
import { SimStatsComponent } from './sim-stats/sim-stats.component';
// import { SelectMultipleSearchModule } from './common/select-multiple-search/select-multiple-search.module';
// import { SelectOptionModule } from './common/select-option/select-option.module';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  listPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EndpointsComponent,
    EndpointComponent,
    EndpointsListComponent,
    IdentifiersComponent,
    IdentifierComponent,
    IdentifiersListComponent,
    PricepointsComponent,
    PricepointComponent,
    PricepointsListComponent,
    VaServicesComponent,
    VaServiceComponent,
    VaServicesListComponent,
    LandingsComponent,
    LandingComponent,
    LandingsListComponent,
    AppletsComponent,
    AppletsListComponent,
    AppletComponent,
    SectionComponent,
    ListComponent,
    ListAndViewComponent,
    TypeComponent,
    CardComponent,
    TpRulesComponent,
    TpRulesListComponent,
    TpRuleComponent,
    SimProfilesComponent,
    SimProfilesListComponent,
    SimProfileComponent,
    ParametersComponent,
    ParametersFormComponent,
    SmppAccountsComponent,
    SmppAccountsListComponent,
    SmppAccountComponent,
    SmscBindConfigurationComponent,
    CampaignsComponent,
    CampaignsListComponent,
    CampaignComponent,
    AddComponent,
    SleepyFlowActionsComponent,
    TextComponent,
    ScheduleComponent,
    EventComponent,
    FilesComponent,
    FilesListComponent,
    FileComponent,
    AddFileComponent,
    StatsComponent,
    StatsViewComponent,
    NotFoundComponent,
    SimsComponent,
    RamCommandComponent,
    DashboardComponent,
    ChartCardComponent,
    AdvertisingAdsComponent,
    MenuAdComponent,
    SubscriptionsComponent,
    SubscriptionsListComponent,
    SimStatsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HeaderModule,
    BodySimpleModule,
    CardGridModule,
    CardModule,
    StateSelectModule,
    SnackbarModule,
    ButtonSimpleModule,
    InputSimpleModule,
    SelectOptionModule,
    RadioButtonModule,
    DatepickerModule,
    TextareaSimpleModule,
    SelectMultipleModule,
    InputFileModule,
    ProgressBarModule,
    TablePrimaryModule,
    SlideToggleModule,
    CheckboxModule,
    GoogleChartsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FullCalendarModule,
    SelectMultipleSearchModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    AuthGuard,
    UniversalTraductorService,
    EndpointsService,
    PricepointsService,
    VaServicesService,
    IdentifiersService,
    LandingsService,
    AppletsService,
    TpRulesService,
    SimProfilesService,
    ParametersService,
    SmppAccountsService,
    IntrospectionService,
    ListService,
    StatsService,
    SubscriptionsService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
