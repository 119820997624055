import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../../../global.component';
import { backTreeNavigation } from '../../../utils/navigationUtils';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  constructor(private http: HttpClient, private router: Router) {}

  adStateSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  ad(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    // console.log(body);
    return this.http.post(getNode() + '/api/pub/v1/campaign/GetAdRequest', body, httpOptions );
  }

  adSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    // console.log(body);
    return this.http.post(getNode() + '/api/pub/v1/campaign/SaveAdRequest', body, httpOptions);
  }

  getAd = async (body) => this.ad(body).toPromise();

}
