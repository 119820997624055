import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../global.component';
import { backTreeNavigation } from '../utils/navigationUtils';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private http: HttpClient, private router: Router) {}

  campaignsList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/ListCampaignsRequest', body, httpOptions);
  }

  campaignDelete(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/DeleteCampaignRequest', body, httpOptions);
  }

  campaignStateSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  campaign(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/GetCampaignRequest', body, httpOptions );
  }

  campaignSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    console.log(body);
    return this.http.post(getNode() + '/api/pub/v1/campaign/SaveCampaignRequest', body, httpOptions);
  }

  stkActionDatasSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/SaveStkActionDatasRequest', body, httpOptions);
  }

  campaignClone(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/CloneCampaignRequest', body, httpOptions);
  }

  clone(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/CloneEntityRequest', body, httpOptions);
  }

  campaignFilter(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/GetCampaignFilterRequest', body, httpOptions);
  }

  campaignPreviewRequest(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v2/stats/GetCampaignPreviewRequest', body, httpOptions );
  }

  campaignTest(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/TestCampaignRequest', body, httpOptions);
  }

  recalcAvailableUsers(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign/UpdateAvailableUsersRequest', body, httpOptions);
  }

  getCampaignsList = async (body) => this.campaignsList(body).toPromise();

  getCampaign = async (body) => this.campaign(body).toPromise().then(res => res).catch(() => backTreeNavigation(this.router));

  getCampaignFilters = async (body) => this.campaignFilter(body).toPromise();

  getCampaignPreviewRequest = async (body) => this.campaignPreviewRequest(body).toPromise();
}
