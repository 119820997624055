import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { FilesService } from './files.service';
import { NodeService } from '../services/node/node.service';
import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  @ViewChildren('Input') inputs: QueryList<[]>;
  @ViewChild('snackbar', { static: false }) snackbar;

  section = false;
  sectionId = 'file';
  image = 'file';
  title;
  description;
  loadingDataList = true;

  filesId = 'files';
  fileId = 'file';
  options = [];
  list;
  showEdit = false;
  fields;
  loadFile = false;
  fileLoading = false;
  progresBar = 0;
  results = '_results';
  chunks = [];
  idName = 'id';
  searchAttributeId = 'searchAttribute';
  searchAttribute = '';
  type = 'ALL';
  resultText;
  time = 3000;
  resultService = 'result';
  filterList = [];
  showUploadSection = false;

  constructor(
    private router: Router, 
    private filesService: FilesService, 
    private nodeService: NodeService,
    private introspectionService: IntrospectionService
  ) { }

  ngOnInit() {
    this.getFilesInfo();
  }

  async getFilesInfo() {
    this.title = 'Files';
    this.section = true;
    this.verifyLists();
    this.setMenuFiles();
    const listFileSave = await this.nodeService.resourceAccess('list-file-save');
    if (listFileSave) this.showUploadSection = true;
  }

  setMenuFiles() {
    const menuFiles = document.getElementById('menuFiles');
    setTimeout(() => {
      const sectionElement = document.getElementById(this.sectionId);
      if (sectionElement) {
        sectionElement.insertBefore(menuFiles, sectionElement.firstChild);
        if (menuFiles.parentElement.id) menuFiles.style.display = 'block';
        else this.setMenuFiles();
      } else this.setMenuFiles();
    }, 100);
  }
  
  async verifyLists() {
    const saveFileList = await this.nodeService.resourceAccess('list-file-save');
    const saveFileApplet = await this.nodeService.resourceAccess('applet-file-save');
    (saveFileList || saveFileApplet) && (this.showEdit = true);
    const res = await this.introspectionService.getIntrospectionDescribe('UploadedFileVO');
    this.fields = res['fields'];
    this.description = res['main'].description;
    await this.getFilters() && this.getData();
  }

  async getData() {
    let body = {};
    this.filterList.forEach(filter => {
      const filterValue = localStorage.getItem(filter.name);
      body[filter.name] = (!filterValue || filterValue === 'ALL') ? null : filterValue;
    });
    const res = await this.filesService.getFilesList(body);
    this.list = res[this.filesId];
    if (this.list)  this.loadingDataList = false;
  }

  changeFilter = (name, value) => {
    localStorage.setItem(name, value);
    this.getData();
  }

  changeOption($event) {
    this.type = $event.name;
    localStorage.setItem('filter_type_file', this.type);
    this.getData();
  }

  changeSearchAttribute($event) {
    this.searchAttribute = ($event.target as HTMLInputElement).value;
    localStorage.setItem('filter_searchAttributeId', this.searchAttribute);
    this.getData();
  }

  addItem() {
    this.loadFile = !this.loadFile;
  }

  async uploadFinish(id) {
    this.loadFile = false;
    this.showSnackbar('Created File');
    const newFile = await this.filesService.getFile({id});
    this.list.splice(0,0,newFile[this.fileId]);
  }

  showSnackbar = (text) => {
    console.log(text);
    this.resultText = text;
    this.snackbar.snackbarShow();
  }

  callMenu = () => {
    const menu = document.getElementById('menu').style;
    if (menu.visibility === 'hidden') {
      menu.visibility = 'visible';
      menu.opacity = '1';
    } else {
      menu.visibility = 'hidden';
      menu.opacity = '0';
    }
  }

  delete = () => {
    this.callMenu();
    this.filesService.fileDelete({}).subscribe(
      (res) => {
        if (res[this.resultService] === 'OK') {
          this.showSnackbar('Files Deleted');
          this.getData();
        }
      },
      (err) => console.log(err)
    );
  }

  async getFilters() {
    const filters = await this.introspectionService.getIntrospectionDescribe('ListFilesRequest');
    this.filterList = filters['fields'];
    this.filterList.map ( async (filter, index) => { 
      const widgetType = filter.widgetType;
      if ( widgetType ){
        if ( widgetType === 'SELECT' && filter.className ){
          const options = await this.introspectionService.getIntrospectionSection(filter.className);
          filter.options = options['values'];
          filter.options.unshift({ name: 'ALL', value: 'ALL' });
          if (!localStorage.getItem(filter.name) || !this.filterValid(localStorage.getItem(filter.name), filter.options)) localStorage.setItem(filter.name, 'ALL');
        }
      }
    });
    return true;
  }

  filterSelected = (name) => { 
    return localStorage.getItem(name);
  };

  filterValid = (value, options) => {
    let valid = false;
    options.forEach ( option => {
      if (option.value === value){
        valid = true;
        return;
      }
    });
    return valid;
  };

}


