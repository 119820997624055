import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { ScheduleService } from '../schedule.service';

import { inputValidation, showError, formatDate, validateDate } from '../../utils/dataUtils';
import { validateForm, detectChangesForm, setNullInputSimple } from '../../utils/formUtils';
import { getLong, getValue } from '../../utils/sectionUtils';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  constructor(private scheduleService: ScheduleService, private router: Router,) { }

  results = '_results';
  data = {};

  @Input() action;
  @Input() dataCalendar;
  @Input() fieldsEvent = [];
  @Input() fieldsCkeckbox = [];
  @Input() new;
  @Input() stateAvailable = [];
  @Input() preview;
  @Input() arg;
  @Output() closeFormEvent = new EventEmitter();
  @Output() saveSchedule = new EventEmitter();
  @Output() deleteSchedule = new EventEmitter();
  @Output() showSnackbar = new EventEmitter();
  @Output() getEntityList = new EventEmitter();

  @ViewChildren('Input') inputs: QueryList<[]>;

  formatDate = formatDate;
  getValue = getValue;
  getLong = getLong;

  ngOnInit(): void {
    const dataString = JSON.stringify(this.dataCalendar);
    this.data = JSON.parse(dataString);
    setTimeout(() => {
      detectChangesForm(
        this.inputs[this.results],
        () => {},
        () => {},
        this.setCheckbox
      );
    }, 1000);
  }

  isRequired = (input) => input ? true : false;
  inputValidation = (id) => inputValidation(id);
  showError = (data) => showError(data);
  setNullInputSimple = (inputs, data) => setNullInputSimple(inputs, data);
  
  checkboxFalse(field) {
    this.data[field.name] === undefined && this.setCheckbox(field.name, false);
    return true;
  }

  checkboxValue(field) {
    if ( this.data[field.name] === undefined || this.data[field.name] === '' || this.data[field.name] === null ){
      if ( field.required ){
        this.setCheckbox(field.name, false);
      }
      return false;
    }
    return this.data[field.name];
  }

  setCheckbox = (id, value) => {
    this.data[id] = value;
  }

  closeForm() {
    this.closeFormEvent.emit();
  }

  async saveForm() {
    const hasEmpty = await validateForm(this.inputs, this.data);
    !hasEmpty && this.saveEvent();
  }

  async deleteForm() {
    this.deleteEvent();
  }

  saveEvent() {
    const fromTs = 'fromTs';
    const toTs = 'toTs';
    this.data[fromTs] = formatDate(validateDate(this.data[fromTs]), 'YYYY-MM-DDTHH:mm:ss', 2) + 'Z';
    this.data[toTs] = formatDate(validateDate(this.data[toTs]), 'YYYY-MM-DDTHH:mm:ss', 2) + 'Z';
    this.setNullInputSimple(this.inputs, this.data);
    this.saveSchedule.emit(this.data);
  }

  deleteEvent() {
    this.deleteSchedule.emit(this.dataCalendar.id);
  }

  getOptionsChild(field, searchAttribute) {
    if (this.new){
      this.getEntityList.emit({field, searchAttribute});
    }
    else
      field.options = [];
  }  

  changeSelect(field) {
    setTimeout(() => {
      const input = this.inputs[this.results].find( (input) => input.selectOptionId == field.name);
      this.data[input.selectOptionId] = input.selectOptionIdFinal;
    }, 300);
  }

}

