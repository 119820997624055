import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IntrospectionService } from '../services/introspection/introspection.service';
import { NodeService } from '../services/node/node.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  showDataService = false;
  cardGridIdHome = 'card-grid-home';
  cardGridSizeHome = [
    {
      screen: 'init',
      row: '7',
    },
    {
      screen: 4000,
      row: '6',
    },
    {
      screen: 1600,
      row: '5',
    },
    {
      screen: 1300,
      row: '4',
    },
    {
      screen: 1040,
      row: '3',
    },
    {
      screen: 768,
      row: '2',
    },
    {
      screen: 540,
      row: '1',
    },
  ];
  cardGridDatadHome = [];
  values = 'values';
  node = 'node';

  constructor(
    private router: Router,
    private introspectionService: IntrospectionService,
    private nodeService: NodeService,
  ) {}

  ngOnInit() {
    JSON.parse(localStorage.getItem('home'))
      ? (this.cardGridDatadHome = JSON.parse(localStorage.getItem('home')), this.showDataService = true)
      : this.getHome();
  }

  async getHome() {
    const res = await this.introspectionService.getIntrospectionSection('UIMenuEntry');
    const node = await this.nodeService.getNode();
    res[this.values].map((menu) => {
      if (node[this.node].menuEntries.find(value => value === menu.name)){
        this.cardGridDatadHome.push({
          title: menu.label,
          name: menu.value.toLowerCase(),
          image: 'assets/images/' + menu.value.toLowerCase() + '.svg',
          description1: menu.description,
          sizeImage: '51',
          sizeBox: 280,
        });
      }
    });
    localStorage.setItem('home', JSON.stringify(this.cardGridDatadHome));
    this.showDataService = true;
  }

  selectOption = ($event) => {
    this.configDataRoute($event);
  }

  configDataRoute = (url) => {
    document.getElementById('display-black').style.display = 'block';
    this.router.navigate(['/' + url]);
  }
}
