import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  imageType;

  @Input() loadingSectionList;
  @Input() section;
  @Input() image;
  @Input() sectionId;
  @Input() title;
  @Input() description;

  constructor() { }

  ngOnInit() {
    this.imageType  = 'assets/images/' + this.image + '.svg';
  }

}
