import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getNode } from '../../global.component';


@Injectable({
  providedIn: 'root'
})
export class IntrospectionService {

  constructor(private http: HttpClient) { }

  introspectionSection(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/introspection/DescribeEnumRequest', body, httpOptions);
  }

  introspectionDescribe(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/introspection/DescribeVORequest', body, httpOptions);
  }

  getIntrospectionSection = async (enumName) => this.introspectionSection({ enumName }).toPromise();

  getIntrospectionDescribe = async (voName) => this.introspectionDescribe({ voName }).toPromise();
}
