import { findingfield } from './sectionUtils';
import { base64ToHex } from './dataUtils';
import { includes, difference, isEmpty } from 'lodash';
import { AsyncPipe } from '@angular/common';
import { async } from '@angular/core/testing';

declare var jQuery: any;

export const detectChangesForm = (
  inputs,
  change,
  validateInputs,
  setCheckbox
) => {
  inputs.map((input) => {
    jQuery('#' + input.inputSimpleId).on('input', () => change());
    setTimeout(() => {
      jQuery('.select-option-selected').on('click', () => change());
      jQuery('.select-multiple-option').on('click', () => change());
      jQuery('.calendar-number-day-hover').on('click', () => change());
    }, 1000);
    jQuery(document).on('click', '.select-option-item', () => validateInputs());
    jQuery(document).on('click', '.radio-buttons', (event) => (
      setDataCheckbox(event.target.id, setCheckbox),
      setTimeout(() => {
        validateInputs();
      }, 100)
    ));
    jQuery(document).on('click', '.checkbox', (event) => (
      setDataCheckboxOnlyOne(event.target.id, setCheckbox)
    ));
  });
};

export const formReset = (sections, inputs, data) => {
  sections.map((section) =>
    section.field.map((field) =>
      inputs.map((input) => {
        input.inputSimpleId === field.name && (input.inputSimpleData = data[field.name]);
        input.inputSimpleId === field.name && field.widgetType === 'HEX_TEXT' && (input.inputSimpleData = base64ToHex(data[field.name]));
        input.selectOptionId === field.name && (input.selectOptionPlaceholder = data[field.name]);
        input.selectMultipleId === field.name && (input.selectMultipleSelected = data[field.name]);
        input.datepickerId === field.name && (input.DatepickerInput1 = data[field.name]);
      })
    )
  );
};

export const validateForm = async (inputs, data) => {
  return await validateHasEmpty(inputs, data).then((result) => includes(result, true))
};

export const validateHasEmpty = (inputs, data) => {
  const results = '_results';
  let hasEmpty = false;
  return Promise.all(inputs[results].map( async (input) =>  {
    // console.log(input.inputSimpleId + ' : ' + input.inputSimpleData + ' : ' + input.hasError);
    await validateInput(input);
    // special validation
    if (input.inputSimpleId && !input.inputSimpleData) {
      input.inputSimpleId.indexOf('cancelAction') > -1 && (data[input.inputSimpleId] = null);
      input.inputSimpleId.indexOf('okAction') > -1 && (data[input.inputSimpleId] = null);
      input.inputSimpleId.indexOf('backAction') > -1 && (data[input.inputSimpleId] = null);
      input.inputSimpleId.indexOf('timeoutAction') > -1 && (data[input.inputSimpleId] = null);
      input.inputSimpleId.indexOf('okStatLabel') > -1 && (data[input.inputSimpleId] = null);
      input.inputSimpleId.indexOf('timeoutStatLabel') > -1 && (data[input.inputSimpleId] = null);
      input.inputSimpleId.indexOf('cancelStatLabel') > -1 && (data[input.inputSimpleId] = null);
      input.inputSimpleId.indexOf('backStatLabel') > -1 && (data[input.inputSimpleId] = null);
    }

    if (input.inputSimpleId && !input.hasError && (!input.inputSimpleRequired || input.inputSimpleData)){
      data[input.inputSimpleId] = input.inputSimpleData;
    }
    if (input.textareaSimpleId && !input.hasError && (!input.textareaSimpleRequired || input.textareaSimpleData)){
      data[input.textareaSimpleId] = input.textareaSimpleData;
    }

    // input.inputSimpleData && !input.hasError && (data[input.inputSimpleId] = input.inputSimpleData);
    input.DatepickerInput1 && !input.hasError && (data[input.datepickerId] = input.DatepickerInput1);
    // input.textareaSimpleData && !input.hasError && (data[input.textareaSimpleId] = input.textareaSimpleData);
    input.selectMultipleSelected && input.selectMultipleSelected.map(
      (option) => option === 'null' && input.selectMultipleSelected.splice(option, 1)
    );
    input.selectMultipleSelected && !input.hasError && (data[input.selectMultipleId] = input.selectMultipleSelected);

    input.selectOptionPlaceholder &&
      !input.hasError &&
      (data[input.selectOptionId] = input.value ? input.value : input.selectOptionPlaceholder);
    input.selectOptionPlaceholder === '' && (data[input.selectOptionId] = null);

    input.inputSimpleRequired && input.hasError && (hasEmpty = true);
    input.textareaSimpleRequired && input.hasError && (hasEmpty = true);
    input.selectOptioRequired && input.hasError && (hasEmpty = true);
    input.datepickerRequired && input.hasError && (hasEmpty = true);

    return Promise.resolve(hasEmpty);
  }));
};

export const validateInput = (input) => {
  if (input.inputSimpleId && input.inputSimpleRequired && (input.inputSimpleData === '' || input.inputSimpleData === null)) {
    document.getElementById(input.inputSimpleId).focus();
    return new Promise(resolve => setTimeout(resolve, 500));
  } else if (input.textareaSimpleId && input.textareaSimpleRequired && (input.textareaSimpleData === '' || input.textareaSimpleData === null)) {
    document.getElementById(input.textareaSimpleId).focus();
    return new Promise(resolve => setTimeout(resolve, 500));
  } else if (input.selectOptionId && input.selectOptioRequired &&
    (input.selectOptionPlaceholder === undefined || input.selectOptionPlaceholder === '' || input.selectOptionPlaceholder === null)) {
    document.getElementById(input.selectOptionId).style.display = 'block';
    document.getElementById(input.selectOptionId).focus();
    document.getElementById(input.selectOptionId).style.display = 'none';
    return new Promise(resolve => setTimeout(resolve, 500));
  } else {
    return true;
  }
};

export const validateInputsWithFields = (inputs, sections, fields, indicator = undefined) => {
  const results = '_results';
  inputs[results].forEach((input) => {
    if (input.inputSimpleData) {
      fields.forEach(
        (field) => {
          indicator !== undefined && field.name + '-' + indicator === input.inputSimpleId && (field.data = input.inputSimpleData);
          indicator === undefined && field.name === input.inputSimpleId && (field.data = input.inputSimpleData);
        }
      );
    }
    // TODO: Revisar esto
    if (input.selectOptionPlaceholder === '') {
      fields.forEach(
        (field) => {
          indicator !== undefined && field.name + '-' + indicator === input.selectOptionId && (field.data = '');
          indicator === undefined && field.name === input.selectOptionId && (field.data = '');
        }
      );
    }
    if (input.selectOptionPlaceholder) {
      fields.forEach(
        (field) => {
          indicator !== undefined && field.name + '-' + indicator === input.selectOptionId && (field.data = input.selectOptionPlaceholder);
          indicator === undefined && field.name === input.selectOptionId && (field.data = input.selectOptionPlaceholder);
        }
      );
    }
  });
  validateFields(sections, fields);
};

export const validateFields = (sections, sectionFields) => {
  sections.forEach((section) =>
    section.field.forEach((field) =>
      field.displayWhen
      ? field.displayWhen.forEach(
          (displayWhen) => {
            field.show = findingfield(displayWhen.conditions, sectionFields);

            section.field.forEach((fieldWithShow) => {
              field.displayWhen.forEach((displayWhenWithShow) => {
                displayWhenWithShow.conditions.forEach((condition) => {
                  if (condition.field === fieldWithShow.name) {
                    !fieldWithShow.show && (field.show = false);
                  }
                });
              });
            });
          }
        )
      : (field.show = true))
  );
  return sections;
};

export const setDataCheckbox = (id, setCheckbox) => {
  let replace = id.replace('-content', '');
  let isNull = true;
  const radioButton = document.getElementById(replace) as HTMLInputElement;
  if (replace.search('true') > -1) {
    replace = replace.replace('true', '');
    !radioButton.checked && setCheckbox && (setCheckbox(replace.replace('true', ''), !radioButton.checked), isNull = false);
  }
  if (replace.search('false') > -1) {
    replace = replace.replace('false', '');
    !radioButton.checked && setCheckbox && (setCheckbox(replace, false), isNull = false);
  }
  replace = replace.replace('false', '');
  isNull && setCheckbox && setCheckbox(replace, null);
};

export const setDataCheckboxOnlyOne = (id, setCheckbox) => {
  let idCheckbox = id.split('-');
  if (idCheckbox[0]){
    const checkbox = document.getElementById(idCheckbox[0]) as HTMLInputElement;
    setCheckbox && setCheckbox(idCheckbox[0],checkbox.checked);
  }
};

export const filterOptions = (field, options) => {
  let newOptions = [];
  if (field.filter && options) {
    if (field.filter.logic === 'INCLUDE') {
      options.map( (item) => {
          if (includes(field.filter.values, item[field.filter.field])) newOptions.push(item);
        });
    }
    else if (field.filter.logic === 'EXCLUDE') {
      options.map( (item) => {
        if (includes(field.filter.values, item[field.filter.field])) newOptions.push(item);
      });
      newOptions = difference(field.options, newOptions);
    }
  }
  else {
    newOptions = options;
  }
  options = [];
  if (newOptions.length > 0) {
    if (!field.required && field.widgetType !== 'SELECT_MULTIPLE') options.unshift({ name: '', value: 'null' });
    newOptions.map((item) => { item.value = item.id; options.push(item); });
  }
  field.options = options;
};

export const getFilterField = (field) => {
  let filter = {};
  if (field.filter) {
    let key = field.filter.logic.toLowerCase() + field.filter.field.charAt(0).toUpperCase() + field.filter.field.slice(1) + 's';
    filter[key] = field.filter.values;
  };
  return filter;
};

export const allowedOptions = (field, options) => {
  let newOptions = [];
  if ( field.allowedValues && options){
    options.map( (option) => {
      if (field.allowedValues.find(value => value === option.name)) newOptions.push(option);
   });
  }
  else {
    newOptions = options;
  }
  field.options = newOptions;
};

export const setNullInputSimple = (inputs, data, nullTextArea=false) => {
  inputs.forEach ( async (input) => {
    if ( input.inputSimpleId && !input.inputSimpleRequired 
      && data[input.inputSimpleId] == "" ){
        console.log(input.inputSimpleId);
        data[input.inputSimpleId] = null;
    }
    if ( nullTextArea && input.textareaSimpleId && !input.textareaSimpleRequired 
      && data[input.textareaSimpleId] == "" ){
        console.log(input.textareaSimpleId);
        data[input.textareaSimpleId] = null;
    }
  });
};

export const setNullArrays = (data) => {
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key]) && data[key].length === 0)
      data[key] = null;
  });
};
