import { Component, OnInit } from '@angular/core';

import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-pricepoints',
  templateUrl: './pricepoints.component.html',
  styleUrls: ['./pricepoints.component.css'],
})
export class PricepointsComponent implements OnInit {
  section = false;
  sectionId = 'pricepoints';
  image = 'pricepoint';
  title;
  description;
  dataVO = 'PricepointVO';
  loadingDataList = true;
  main = 'main';
  dataFields;
  fields = 'fields';

  constructor( private introspectionService: IntrospectionService ) { }

  ngOnInit() {
    this.getDataInfo();
  }

  async getDataInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    this.title = resDescribe[this.main].pluralLabel;
    this.description = resDescribe[this.main].description;
    this.section = true;
    this.dataFields = resDescribe[this.fields];
  }

  showView() {
    this.loadingDataList = false;
  }
}
