import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getNode } from '../global.component';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {

  constructor(private http: HttpClient) {}

  parametersList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/parameter/ListParametersRequest', body, httpOptions);
  }

  parameter(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/parameter/GetParameterRequest', body, httpOptions);
  }

  parameterSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/parameter/SaveParametersRequest', body, httpOptions);
  }

  getParametersList = async (type) => this.parametersList({ type }).toPromise();

  getParameter = async (name) => this.parameter({ name }).toPromise();

}
