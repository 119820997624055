import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';

import { UniversalTraductorService } from './services/universal-traductor.service';
import { IntrospectionService } from './services/introspection/introspection.service';
import { NodeService } from './services/node/node.service';
import { ParametersService } from './parameters/parameters.service';

import { formatDate, validateDate } from './utils/dataUtils';
import { validateTypeResource, operationSelected, getNetwork } from './utils/nodeUtils';
import * as moment from 'moment';
import { isNull } from 'util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  userDetails: KeycloakProfile;
  arrayMenuHeader = [];
  loadingView = true;
  headerBreadcrumbs;
  returnUrl;
  dataUser = {};
  auditHeaderValidation = false;
  pendingTaskHeaderValidation = false; //transclusion
  headerApps;
  imagePerfil;
  values = 'values';
  parameter = 'parameter';
  main = 'main';
  submenu = {
    tacho: { name: 'Loading...', color: 'transparent' },
    actual: {name: 'Loading...', id: '', color: 'transparent'},
    operations: []
  };
  node = 'node';
  actual = 'actual';
  tacho = 'tacho';
  currentTps = 'currentTps';
  totalTps = 'totalTps';
  color = 'color';
  timeout = 600000;
  nodes = 'nodes';
  operations = 'operations';
  headerDataUser = {name: 'loading', email: 'loading'};
  messageNode;
  serviceStatus = 'serviceStatus';
  currentYear = new Date().getFullYear();
  nodeWorking = true;
  network = getNetwork();
  operationSelected = operationSelected;
  //statsRedirected = statsRedirected;

  constructor(
    private router: Router,
    private universalTraductorService: UniversalTraductorService,
    private introspectionService: IntrospectionService,
    private nodeService: NodeService,
    private parametersService: ParametersService,
    private keycloakService: KeycloakService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.changeBreadcrumb();
    });
  }

  async ngOnInit() {
    await this.validateNode();
  }

  async initApp() {
    await this.thisNode();
    await this.nodeList();
    await this.tachoThisNode();
    this.initUser();

    this.changeBreadcrumb();
    this.configLocalStorage();
  }

  async validateNode() {
    const hostname = window.location.hostname;
    const resourceAccess = await this.keycloakService.getKeycloakInstance().resourceAccess;
    let isNode;

    if (window.location.href.indexOf('https://mobiera.com') > -1){
      isNode = validateTypeResource(resourceAccess, 5);
    }
    else if ( window.location.href.indexOf('https://aircast.int') > -1 ) {
      isNode = validateTypeResource(resourceAccess, 5);
    }
    else if ( window.location.href.indexOf('https://192.168.253.73') > -1 ) {
      isNode = validateTypeResource(resourceAccess, 5);
    }
    else if (hostname.endsWith('mobiera.io')) {
      isNode = validateTypeResource(resourceAccess, 5);
    }
    else if (window.location.href.indexOf('http://localhost:4200/') > -1) {
      isNode = validateTypeResource(resourceAccess, 5);
    }
    else {
      isNode = validateTypeResource(resourceAccess, 6);
    }

    if (!isNode) {
      this.messageNode = 'Esta Network no tiene nodos, seleccione otra';
    } else {
      this.initApp();
    }
  }

  async thisNode() {
    const nodeStatus = await this.nodeService.getNodeStatus({verbose: false});
    const sanity = await this.introspectionService.getIntrospectionSection('ServiceSanity');
    const status = sanity[this.values].find(({name}) => name = nodeStatus[this.serviceStatus].serviceSanity);

    const node = await this.nodeService.getNode().then(res => res).catch(() => (this.nodeWorking = false, this.nodeList()));
    this.submenu[this.actual] = {
      name: node[this.node].label,
      id: node[this.node].clientId,
      url: node[this.node].rootUrl,
      color: `#${status.rgbColor}`
    };
  }

  async tachoThisNode(){
    const tacho = await this.nodeService.getTacho().then(res => res).catch(() => {console.log ('Error nodeService.getTacho'); return; });
    if (tacho) {
      this.submenu[this.tacho] = {
        name: tacho[this.currentTps] + ' / ' + tacho[this.totalTps],
        color: tacho[this.color]
      };
    }
    setTimeout(() => {  
      this.tachoThisNode();
    }, this.timeout);
  }

  async nodeList() {
    const nodeList = await this.nodeService.getNodeList();
    const nodes = nodeList[this.nodes].map((node) => node.clientId !== this.submenu[this.actual].id && node);
    this.submenu[this.operations] = [];
    nodes.forEach((node) => node && this.submenu[this.operations].push({name: node.label, id: node.clientId, url: node.rootUrl}));
  }

  async initUser() {
    console.log(await this.keycloakService.isLoggedIn());
    if (await this.keycloakService.isLoggedIn()) {
      this.userDetails = await this.keycloakService.loadUserProfile();
      this.headerDataUser.name = `${this.userDetails.firstName} ${this.userDetails.lastName}`;
      this.headerDataUser.email = this.userDetails.email;
    }
  }

  configLocalStorage() {
    localStorage.getItem('states') ? this.loadingView = false : this.getStates();
    localStorage.getItem('menu') ? (this.arrayMenuHeader = JSON.parse(localStorage.getItem('menu'))) : this.getHome();
    localStorage.getItem('timezone') ? this.loadingView = false : this.getTimezone();
  }

  async getHome() {
    const node = await this.nodeService.getNode();
    const res = await this.introspectionService.getIntrospectionSection('UIMenuEntry');
    res[this.values].map((menu) => {
      if ( node[this.node].menuEntries.find(value => value === menu.name) ){
        this.arrayMenuHeader.push({
          title: menu.value.toLowerCase(),
          name: menu.label,
          image: 'assets/images/' + menu.value.toLowerCase() + '.svg',
          value: menu.value.toLowerCase()
        });
      }
    });
    localStorage.setItem('menu', JSON.stringify(this.arrayMenuHeader));
  }

  async getStates() {
    const res = await this.introspectionService.getIntrospectionSection('EntityState');
    localStorage.setItem('states', JSON.stringify(res[this.values]));
    this.loadingView = false;
  }

  async getTimezone() {
    const res = await this.parametersService.getParameter('STRING_NODE_TIMEZONE');
    const timezone = JSON.stringify(res[this.parameter].value);
    localStorage.setItem('timezone', timezone);
    this.loadingView = false;
  }

  changeBreadcrumb = async () => {
    if ( isNull(localStorage.getItem('home')) ) { this.router.navigate(['/home']); return;}
    const headerBreadcrumbs = this.router.url.replace('/', '');
    this.headerBreadcrumbs = [{
      // name: await this.universalTraductorService.switchLanguage('Header.Home'),
      name: 'Home',
      redirect: 'home'
    }];

    if (headerBreadcrumbs !== 'home' && headerBreadcrumbs !== '') {
      const menuEntries = JSON.parse(localStorage.getItem('home'));
      // Separamos el array por cadenas
      const arrayProcess = headerBreadcrumbs.split('/');
      let rutes = arrayProcess[0];
      for (let i = 0; i < arrayProcess.length; i++) {
        // Iniciamos cada palabra en mayuscula
        let name;
        name = arrayProcess[i];
        if (name === 'add') name = 'New';
        else {
          const optionMenu = menuEntries.find(value => value.name === name);
          name = (optionMenu) ? optionMenu.title : name;
        } 

        // verificamos si es una ruta anidada
        if ( i > 0 ) rutes = rutes + '/' + arrayProcess[i];
        if (isNaN(name)) {
          let add;
          if (name === 'New' && this.headerBreadcrumbs[1]) {
            add = arrayProcess[0];
            add && this.getNewEntityLabel(add, rutes);
          }
          this.headerBreadcrumbs.push({
            // name: await this.universalTraductorService.switchLanguage('Header.' + name),
            name: name,
            redirect: rutes
          });
        } else {
          this.pushBreadcrumb(rutes, name);
        }
      }
    }
  }

  async getNewEntityLabel(title, rutes) {
    let typeNameArray = title.split('_');
    let typeVO = '';
    typeNameArray.map((type) => typeVO += type.charAt(0).toUpperCase() + type.slice(1));
    typeVO = typeVO + 'VO';
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(typeVO);
    this.headerBreadcrumbs[2] = {name: resDescribe[this.main].newEntityLabel, redirect: rutes};
  }

  pushBreadcrumb(rutes, id) {
    setTimeout(() => {  
      const breadcrumb = JSON.parse(localStorage.getItem('id'));
      if (breadcrumb && id == breadcrumb.id){
        if (!this.headerBreadcrumbs.some((item) => item.name === breadcrumb.name)) {
          if (rutes.includes(breadcrumb.section) && breadcrumb.id == id) {
              this.headerBreadcrumbs.push({name: breadcrumb.name, redirect: rutes});
          }
        }
      }
      else this.pushBreadcrumb(rutes, id);
    }, 1000);
  }

  headerPrincipal = () =>  this.router.navigate(['/home']);

  async statsRedirected () {
    window.localStorage.setItem('action', 'smppAccounts');
    this.router.navigate(['/stats']);
    setTimeout(() => {
      window.localStorage.removeItem('action');
    }, 3000);
  }

  selectOption = ($event) => this.router.navigate(['/' + $event]);

  logout = async () => {
    window.localStorage.removeItem('home');
    window.localStorage.removeItem('menu');
    window.localStorage.removeItem("runId")
    await this.keycloakService.logout();
  }

  // myAccountMenuHeader = () => window.location.href = `${this.network}/auth/realms/mobiera/account/`;
  myAccountMenuHeader = () => window.location.href = `${this.network}/realms/mobiera/account/`;

  selectetwork = () => this.network ? false : true;

  changeNetwork(link) {
    let newLink =this.network;
    if (newLink.endsWith('dev.mobiera.io')) {
      newLink = this.network.replace('dev.mobiera.io', 'mobiera.io');
    } else if (newLink.endsWith('mobiera.io')) {
      newLink = this.network.replace('mobiera.io', 'dev.mobiera.io');

    }
    window.location.replace(newLink);
  }

}
