import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UniversalTraductorService {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
  }

  dataTranslate;

  setDefaultLanguage(componentLanguage) {
    if (componentLanguage === 'en') {
      componentLanguage = 'en';
    }
    this.translate.setDefaultLang(componentLanguage);
  }

  async switchLanguage(data) {
    const ret = this.translate.get(data).toPromise();
    // console.log(data + '=>' + await ret);
    return ret;
  }
}
