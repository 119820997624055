import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { mapKeys } from 'lodash';

import { ParametersService } from '../parameters.service';
import { NodeService } from '../../services/node/node.service';

import { openCard } from '../../utils/uiUtils';
import { detectChangesForm, formReset, validateForm } from '../../utils/formUtils';
import { showSection } from '../../utils/sectionUtils';

@Component({
  selector: 'app-parameters-form',
  templateUrl: './parameters-form.component.html',
  styleUrls: ['./parameters-form.component.css'],
})
export class ParametersFormComponent implements OnInit {
  showEdit = false;
  hasParameters = false;
  parametersId = 'parameters';
  result = 'result';
  triggerHeader = false;
  triggerSnackbar = false;
  parameters = [];
  editing = false;
  results = '_results';
  fields = [];
  newFields = [];
  sectionEditedText = '';
  isOpen = false;

  i;
  id;

  showSection = showSection;

  @Output() showForm = new EventEmitter();
  @Input() dataFields;
  @Input() parameterTypes;

  @ViewChildren('Input') inputs: QueryList<[]>;
  @ViewChild('sectionSnackbar', { static: false }) sectionSnackbar;

  constructor(
    private parametersService: ParametersService,
    private router: Router,
    private nodeService: NodeService
  ) {}

  ngOnInit() {
    this.validateAccess();
  }

  validateAccess = async () => {
    let hasRol = await this.nodeService.resourceAccess('parameter-list');
    !hasRol && this.router.navigate(['home']);
    hasRol = await this.nodeService.resourceAccess('parameter-view');
    !hasRol && this.router.navigate(['home']);
    hasRol = await this.nodeService.resourceAccess('parameter-save');
    this.showEdit = hasRol ? true : false;
    this.getParameters();
  }

  getParameters = async () => {
    this.parameters = [];
    this.parameterTypes.forEach((data) => this.parameters.push({data}));

    this.parameters.forEach(async (paramenter, index) => {
      const res = await this.parametersService.getParametersList(paramenter.data.value);
      paramenter.field = res[this.parametersId];
      res[this.parametersId].forEach(
        (parameter) => (
          (this.fields[parameter.name] = parameter.value),
          (this.newFields[parameter.name] = parameter.value)
        )
      );
      index === this.parameters.length - 1 && this.active();
    });
  }

  active() {
    this.openSection();
    this.triggerHeader = true;
    this.parameters.length > 0 && this.detectChangesForm();
    this.showForm.emit();
    !this.isOpen && this.openCard();
    this.isOpen = true;
  }

  openSection = () => document.getElementById('section0').click();

  detectChangesForm() {
    setTimeout(() => {
      (this.hasParameters = true),
        detectChangesForm(
          this.inputs[this.results],
          () => this.showButton(),
          () => {},
          () => {}
        );
    }, 1000);
  }

  showButton = () => this.editing = true;
    // this.hideEditing.emit(true);

  openCard = () => openCard(this.parametersId);

  cardSelectClick = (id) => this.router.navigate(['/parameter', id]);

  resetHeader = () => this.triggerHeader = false;

  resetSnackbar = () => this.triggerSnackbar = false;

  showError(field) {
    let info = 'Invalid Data';
    if (field.indexOf('LONG') === 0 || field.indexOf('INTEGER') === 0) {
      info += ', must be a number';
    }
    if (field.indexOf('BOOLEAN') === 0) {
      info += ', must be a boolean';
    }
    return info;
  }

  inputValidation(field) {
    if (field.indexOf('LONG') === 0 || field.indexOf('INTEGER') === 0) {
      return new RegExp(/^\d+$/);
    }
    if (field.indexOf('BOOLEAN') === 0) {
      return new RegExp('^(1|0)$');
    }
  }

  cancel() {
    formReset(this.parameters, this.inputs[this.results], this.fields);
    this.editing = false;
    setTimeout(() => {
      this.validateInputs();
    }, 100);
  }

  validateInputs = () => this.inputs[this.results].map((input) => document.getElementById(input.inputSimpleId).focus());

  validateForm() {
    this.validateInputs();
    setTimeout(async () => {
      const hasEmpty = await validateForm(this.inputs, this.newFields);
      !hasEmpty && this.saveParameter();
    }, 500);
  }

  saveParameter() {
    const dataSave = [];
    mapKeys(
      this.newFields,
      (value, key) =>
        value !== this.fields[key] && dataSave.push({ name: key, value })
    );
    dataSave.length > 0 && this.saveService(dataSave);
  }

  saveService(dataSave) {
    this.parametersService.parameterSave({ parameters: dataSave }).subscribe(
      () => {
        this.showSnackbar('Parameters Saved');
        this.getParameters();
        this.editing = false;
      },
      (err) => {
        this.showSnackbar(err.error.errorMessage);
        console.log(err);
      }
    );
  }

  showSnackbar(text) {
    this.sectionEditedText = text;
    this.triggerSnackbar = true;
    this.sectionSnackbar.snackbarShow();
  }

  isRequired = (input) => input ? true : false;

  replace = (text) => text.replace(/_/g, ' ');

}
