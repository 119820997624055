import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../global.component';
import { backTreeNavigation } from '../utils/navigationUtils';

@Injectable({
  providedIn: 'root'
})
export class SimProfilesService {

  constructor(private http: HttpClient, private router: Router) {}

  simProfilesList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/sim_profile/ListSimProfilesRequest', body, httpOptions);
  }

  simProfileDelete(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/sim_profile/DeleteSimProfileRequest', body, httpOptions);
  }

  simProfileStateSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  simProfile(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/sim_profile/GetSimProfileRequest', body, httpOptions);
  }

  simProfileSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/sim_profile/SaveSimProfileRequest', body, httpOptions);
  }

  clone(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/CloneEntityRequest', body, httpOptions);
  }

  getSimProfilesList = async (body) => this.simProfilesList(body).toPromise();

  getSimProfile = async (body) => this.simProfile(body).toPromise().then(res => res).catch(() => backTreeNavigation(this.router));
}
