import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TpRulesService } from '../../tp-rules.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { textFormat } from '../../../utils/textUtils';
import { openCard } from '../../../utils/uiUtils';
import { circleColorState, stateFormat, stateSelected, findState } from '../../../utils/stateUtils';
import { organizeSection } from '../../../utils/sectionUtils';

@Component({
  selector: 'app-tp-rule',
  templateUrl: './tp-rule.component.html',
  styleUrls: ['./tp-rule.component.css']
})
export class TpRuleComponent implements OnInit {

  constructor(
    public tpRulesService: TpRulesService,
    public introspectionService: IntrospectionService,
    private route: ActivatedRoute
  ) {}

  resultService = 'result';
  tpRule = 'tpRule';
  type = 'tp_rule';
  hasData = false;
  dataTpRule;
  id;
  params = 'params';
  stateSelectArray = [];
  stateSelect = [];
  tpRuleEditedText = '';
  initTpRule = false;
  sections = [];
  values = 'values';
  fields = 'fields';
  hasState = false;
  states = [];
  header = [];
  triggerSnackbar = false;
  snackbarTime = 5000;

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.route.paramMap.subscribe((params) => {
      this.id = params[this.params].id;
    });
    this.tpRuleSections();
  }

  async tpRuleSections() {
    const resSection = await this.introspectionService.getIntrospectionSection('TpRuleSection');
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('TpRuleVO');
    this.sections = organizeSection(
      resSection[this.values],
      resDescribe[this.fields]
    );
    this.hasState = findState(this.sections);
    this.getTpRule();
    resDescribe[this.fields].find((element) => {
      element.section === 'HEADER' && this.header.push(element.name);
    });
  }

  getTpRule = async () => {
    const res = await this.tpRulesService.getTpRule({ id: this.id });
    this.dataTpRule = res[this.tpRule];
    this.hasData = true;
    this.functionSelectArray();
    !this.initTpRule && (this.initTpRule = true, openCard(this.dataTpRule.id));
  }

  functionSelectArray() {
    this.stateSelectArray = [];
    this.stateSelect = [];
    let newName;
    for (const allowedTransitionStates of this.dataTpRule.allowedTransitionStates) {
      newName = textFormat(allowedTransitionStates);
      this.stateSelectArray.push(
        stateFormat(newName, circleColorState(newName, this.states), this.dataTpRule.id )
      );
    }
    newName = textFormat(this.dataTpRule.state);
    this.stateSelectArray.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataTpRule.id)
    );
    this.stateSelect.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataTpRule.id)
    );
    this.stateSelectArray.splice(this.stateSelectArray.length - 1);
  }

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect[0],
      this.tpRulesService.tpRuleStateSave({id, type: 'TP_RULE', state: $event.name.toUpperCase(), }),
      this.resultService,
      this.tpRulesService.tpRule({ id }),
      this.getTpRule,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.tpRuleEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  hideEditing(active) {
    active ? this.stateSelect[0].disabled = true : this.stateSelect[0].disabled = false;
  }

  resetSnackbar() {
    this.triggerSnackbar = false;
  }

  saveTpRule({data, component}) {
    this.tpRulesService.tpRuleSave({ tpRule: data }).subscribe(
      () => {
        this.showSnackbar('TpRule Saved');
        this.getTpRule();
        component.editing = false;
        this.hideEditing(false);
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  }
}
