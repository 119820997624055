import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { mapKeys } from 'lodash';

import { IntrospectionService } from '../../../../services/introspection/introspection.service';
import { CampaignsService } from '../../../..//campaigns/campaigns.service';

import { validateForm, detectChangesForm } from '../../../../utils/formUtils';
import { inputValidation, showError } from '../../../../utils/dataUtils';
import { stateActive } from '../../../../utils/stateUtils';


@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['../sleepy-flow-actions.component.css']
})
export class TextComponent implements OnInit, OnChanges {

  @Input() texts;
  @Input() subClassName;
  @Input() setTexts;
  @Input() restore;
  @Input() index;
  @Input() name;
  @Input() required = false;
  @Input() actionId;
  @Output() getTexts = new EventEmitter();
  @Output() showSnackbar = new EventEmitter();
  @Output() showButton = new EventEmitter();
  @Output() getOptions = new EventEmitter();

  showText = false;
  fieldsText = 'fields';
  values = 'values';
  fields;
  selects = [];
  newTexts;
  newData = {};
  states = [];
  stateSelectPrincipal = [];
  stateSelectArray = [];
  result = 'result';
  results = '_results';
  showSelects = [];
  allowedTransitionStates = 'allowedTransitionStates';
  snackbarText = '';
  isRequiredForThisData = false;

  @ViewChildren('Input') inputs: QueryList<[]>;
  @ViewChild('textSnackbar', { static: false }) textSnackbar;

  constructor(private introspectionService: IntrospectionService, private campaignsService: CampaignsService) { }

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.getDescribeVORequest(this.subClassName);
    this.newTexts = this.texts.slice(0);
    this.stateActive();
    setTimeout(() => {
      this.newTexts.length < 1 && this.required && this.addItem();
    }, 1000);
    this.initApp();
  }

  initApp() {
    setTimeout(() => {
      detectChangesForm(
        this.inputs[this.results],
        () => this.showButton.emit(),
        () => {},
        null
      );
    }, 500);
  }

  ngOnChanges() {
    this.setTexts && this.getTexts.emit({data: this.validateTexts(), name: this.name});
    this.restore && (this.newTexts = this.texts.slice(0));
  }

  getOptionsChild(field) {
    this.getOptions.emit(field);
  }

  async validateTexts() {
    this.newTexts.map(
      (item, index) => mapKeys(
        item, (value, key) => {
          const id = item.id ? item.id : index;
          this.newData[key + '-' + id] = value;
        }
      )
    );
    const hasEmpty = await validateForm(this.inputs, this.newData);
    validateForm(this.inputs, this.newData);
    this.newTexts.map(
      (item, index) => mapKeys(
        item, (value, key) => {
          const id = item.id ? item.id : index;
          item[key] = this.newData[key + '-' + id];
        }
      )
    );
    if (this.required && this.newTexts.length < 1) {
      this.isRequiredForThisData = true;
      return false;
    }
    return !hasEmpty ? [this.newTexts, this.index] : false;
  }

  async getDescribeVORequest(subClassName) {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(subClassName);
    this.fields = resDescribe[this.fieldsText];
    this.fields.map(
      (item) =>
        item.widgetType === 'SELECT' &&
        item.classType !== 'NATIVE' &&
        this.introspection(item, this.selects)
    );
    this.showText = true;
  }

  async introspection(widgetType, array) {
    const res = await this.introspectionService.getIntrospectionSection(widgetType.className);
    !widgetType.required && res[this.values].unshift({ name: '', value: 'null' });
    array[widgetType.className] = res[this.values];
  }

  isRequired = (input) => input ? true : false;

  inputValidation = (id) => inputValidation(id);

  showError = (data) => showError(data);

  validateSelect(data, id) {
    if (id.className === 'Long') {
      return data === undefined ? '' : console.log('error');
    }
    return data === undefined ? '' : data;
  }

  getValue(data, id) {
    if (id.className === 'Long') {
      return data === undefined ? '' : console.log('error2');
    }
    return data === undefined ? '' : data;
  }

  isObject = (data) => typeof data === 'object' && true;

  // forIn(field) {
  //   const data = [];
  //   mapKeys(field, (value, key) => data.push({value, key}));
  //   return data;
  // }

  addItem() {
    const newitem = {};
    this.fields.map((item) => {
      item.name === 'data' ?  newitem[item.name] = '' : newitem[item.name] = null;
      item.name === 'actionId' && (newitem[item.name] = this.actionId);
    });
    this.newTexts.push(newitem);
    this.initApp();
    this.isRequiredForThisData = false;
  }

  delete(i) {
    this.showButton.emit();
    this.newTexts.splice(i, 1);
    this.stateSelectPrincipal.splice(i, 1);
    this.stateSelectArray.splice(i, 1);
    if (this.required && this.newTexts.length < 1) {
      this.isRequiredForThisData = true;
    }
  }

  stateActive() {
    const getStateActive = stateActive(this.newTexts, this.stateSelectPrincipal, this.states);
    this.stateSelectArray = getStateActive.stateSelectArray;
    this.stateSelectPrincipal = getStateActive.stateSelectPrincipal;
  }

  // showSnackbar(text) {
  //   this.snackbarText = text;
  //   this.textSnackbar.snackbarShow();
  // }

  stateSelected($event, id, i) {
    this.stateSelectPrincipal[i].disabled = true;
    if (this.newTexts[i].state !== $event.name.toUpperCase()) {
      this.campaignsService.campaignStateSave({id, type: 'STK_ACTION_DATA', state: $event.name.toUpperCase()}).subscribe(
        (res) => {
          this.newTexts[i].state = $event.name.toUpperCase();
          this.newTexts[i].allowedTransitionStates = res[this.allowedTransitionStates];
          this.stateActive();
          this.showSnackbar.emit('State Saved');
          this.stateSelectPrincipal[i].disabled = false;
        },
        (err) => {
          this.showSnackbar.emit(err.error.errorMessage);
          this.stateSelectPrincipal[i].hidden = true;
          setTimeout(() => {
            this.stateSelectPrincipal[i].hidden = false;
          }, 10);
          this.stateSelectPrincipal[i].disabled = false;
        }
      );
    }
  }

}
