import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

import { SmppAccountsService } from '../smpp-accounts.service';

import { openCard } from '../../utils/uiUtils';
import { stateFormat, circleColorState, stateSelected, stateActive } from '../../utils/stateUtils';
import { backTreeNavigation } from '../../utils/navigationUtils';

@Component({
  selector: 'app-smpp-accounts-list',
  templateUrl: './smpp-accounts-list.component.html',
  styleUrls: ['./smpp-accounts-list.component.css']
})
export class SmppAccountsListComponent implements OnInit {

  hasSmppAccounts = false;
  smppAccountsId = 'smppAccounts';
  section = 'smpp_account';
  result = 'result';
  smppAccountsList;
  stateSelectArray = [];
  stateSelect = [];
  initSmppAccount = false;
  smppAccountsEditedText = '';
  states = [];
  triggerHeader = false;
  triggerSnackbar = false;
  snackbarTime = 5000;
  dataListFilterInstrospection = 'ListSmppAccountsRequest';
  body = {};

  @Output() showList = new EventEmitter();
  @Input() dataFields;

  constructor(
    private smppAccountsService: SmppAccountsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    // this.getSmppAccounts();
  }

  getSmppAccounts = async (body = null) => {
    if (body) this.body = body;
    const res = await this.smppAccountsService.getSmppAccountsList(this.body).then(data => data).catch(() => backTreeNavigation(this.router, true));
    this.smppAccountsList = res[this.smppAccountsId];
    this.getStates();
    this.hasSmppAccounts = true;
    this.showList.emit();
    !this.initSmppAccount && this.openCard();
    this.triggerHeader = true;
  }

  openCard() {
    this.initSmppAccount = true;
    openCard(this.smppAccountsId);
  }

  stateSelected({$event, id, i}) {
    this.stateSelectArray[i] = [stateFormat($event.name, circleColorState($event.name, this.states), id)];
    stateSelected(
      this.stateSelect[i],
      this.smppAccountsService.smppAccountStateSave({id, type: this.section.toUpperCase(), state: $event.name.toUpperCase()}),
      this.result,
      this.smppAccountsService.smppAccount({ id }),
      this.getSmppAccounts,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.smppAccountsEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  getStates() {
    const getStateActive = stateActive(this.smppAccountsList, this.stateSelect, this.states);
    this.stateSelectArray = getStateActive.stateSelectArray;
    this.stateSelect = getStateActive.stateSelectPrincipal;
  }

  cardSelectClick = (id) => this.router.navigate(['/' + this.section, id]);

  resetHeader = () => this.triggerHeader = false;

  resetSnackbar = () => this.triggerSnackbar = false;

  changeFilter = (body) => this.getSmppAccounts(body);

  cloneProcess = (body) => {
    body['type'] = this.section.toUpperCase();
    this.smppAccountsService.clone(body).subscribe(
      () => {
        this.showSnackbar(this.section.charAt(0).toUpperCase() + this.section.slice(1)   + ' Cloned');
        this.getSmppAccounts();
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  };

}
