import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

import { LandingsService } from '../landings.service';

import { openCard } from '../../utils/uiUtils';
import { stateFormat, circleColorState, stateSelected, stateActive } from '../../utils/stateUtils';
import { backTreeNavigation } from '../../utils/navigationUtils';

@Component({
  selector: 'app-landings-list',
  templateUrl: './landings-list.component.html',
  styleUrls: ['../landings.component.css'],
})
export class LandingsListComponent implements OnInit {
  hasData = false;
  dataId = 'landings';
  section = 'landing';
  result = 'result';
  dataList;
  stateSelectArray = [];
  stateSelect = [];
  init = false;
  editedText = '';
  states = [];
  triggerHeader = false;
  snackbarTime = 5000;
  triggerSnackbar = false;
  i;
  id;
  dataListFilterInstrospection = 'ListLandingsRequest';

  @Output() showList = new EventEmitter();
  @Input() dataFields;

  constructor(
    private dataService: LandingsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
  }

  getDataList = async (body = {}) => {
    const res = await this.dataService.getDataList(body).then(data => data).catch(() => backTreeNavigation(this.router, true));
    this.dataList = res[this.dataId];
    this.stateActive();
    this.hasData = true;
    this.showList.emit();
    if (!this.init) this.openCard();
    this.triggerHeader = true;
}

  openCard() {
    this.init = true;
    openCard(this.dataId);
  }

  stateSelected({$event, id, i}) {
    this.stateSelectArray[i] = [stateFormat($event.name, circleColorState($event.name, this.states), id)];
    stateSelected(
      this.stateSelect[i],
      this.dataService.stateSave({id, state: $event.name.toUpperCase()}),
      this.result,
      this.dataService.data({ id }),
      this.getDataList,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.editedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  stateActive() {
    const getStateActive = stateActive(this.dataList, this.stateSelect, this.states);
    this.stateSelectArray = getStateActive.stateSelectArray;
    this.stateSelect = getStateActive.stateSelectPrincipal;
  }

  cardSelectClick = (id) => this.router.navigate(['/' + this.section, id]);

  resetHeader = () => this.triggerHeader = false;

  resetSnackbar = () => this.triggerSnackbar = false;

  changeFilter = (body) => this.getDataList(body);

  cloneProcess = (body) => {
    body['type'] = this.section.toUpperCase();
    this.dataService.clone(body).subscribe(
      () => {
        this.showSnackbar(this.section.charAt(0).toUpperCase() + this.section.slice(1)   + ' Cloned');
        this.getDataList();
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  };

}
