import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { mapKeys } from 'lodash';

import { IntrospectionService } from '../services/introspection/introspection.service';
import { SimsService } from './sims.service';

import { openCard } from '../utils/uiUtils';
import { inputValidation, showError, formatDate, validateDateTime } from '../utils/dataUtils';
import { detectChangesForm, validateForm, allowedOptions} from '../utils/formUtils';
import { validateLabels, organizeSection, showSection, validateClose } from '../utils/sectionUtils';

@Component({
  selector: 'app-sims',
  templateUrl: './sims.component.html',
  styleUrls: ['./sims.component.css']
})
export class SimsComponent implements OnInit {

  section = false;
  sectionId = 'sim';
  image = 'sim';
  title;
  description;
  dataVO = 'SimVO';
  typeSaction = 'SimSection';
  loadingDataList = true;
  main = 'main';
  simsId = 'sims';
  fields = 'fields';
  results = '_results';
  fieldsData = [];
  data = [];
  showForm = false;
  values = 'values';
  sections = [];
  newSections = [];
  initSections = [];
  editing = false;
  sectionEditedText = '';
  triggerSnackbar = false;

  formatDate = formatDate;
  showSection = showSection;

  @ViewChildren('Input') inputs: QueryList<[]>;

  @ViewChild('sectionSnackbar', { static: false }) sectionSnackbar;

  constructor(private introspectionService: IntrospectionService, private simsService: SimsService) { }

  ngOnInit(): void {
    this.section = true;
    this.loadingDataList = false;
    this.getVo();
    openCard(this.sectionId);
  }

  async getVo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    const resSection = await this.introspectionService.getIntrospectionSection(this.typeSaction);
    this.getSections(resSection, resDescribe);
    this.title = this.description = 'Sims';
    // this.title = resDescribe[this.main].pluralLabel;
    // this.description = resDescribe[this.main].description;
    this.fieldsData = resDescribe[this.fields];
    this.sections = validateLabels([{field: this.fieldsData}], this.fieldsData);
  }

  getSections(resSection, resDescribe) {
    this.initSections = organizeSection(
      resSection[this.values],
      resDescribe[this.fields]
    );
    this.initSections.map(
      (section) =>
        section.value !== 'HEADER' && this.newSections.push(section)
    );
  }

  async getSim() {
    this.showForm = false;
    this.editing = false;
    let simType = this.inputs[this.results].find((field, index) => index > 0 && field.isChecked);
    simType && (simType = simType.radioButtonContent);
    const input = this.inputs[this.results][0].inputSimpleData;

    if (simType && input) {
      const body = {};
      body[simType.toLowerCase()] = input;
      const sim = await this.simsService.getSim(body);
      this.data = sim[this.sectionId];
      this.showForm = true;
      this.detectChangesForm();
      validateClose(this.newSections, this.data);
    }
  }

  isRequired = (input) => input ? true : false;

  inputValidation = (id) => inputValidation(id);

  showError = (data) => showError(data);

  getValue = (data) =>  data === undefined ? '' : data;

  detectChangesForm() {
    setTimeout(() => {
        detectChangesForm(
          this.inputs[this.results],
          () => this.showButton(),
          () => {},
          () => {}
        );
    }, 1000);
  }

  showButton = () => this.editing = true;

  cancel = () => {
    this.showForm = false;
    this.editing = false;
  }

  validateForm() {
    this.validateInputs();
    setTimeout(async () => {
      const hasEmpty = await validateForm(this.inputs, this.fieldsData);
      !hasEmpty && this.getFields();
    }, 200);
  }

  validateInputs = () => this.inputs[this.results].map((input) => document.getElementById(input.inputSimpleId).focus());

  getFields() {
    const dataSave = [];
    mapKeys(
      this.fieldsData,
      (value, key) => {
        const regex = /(\d{2})[-](\d{2})[-](\d{4})/;
        const format = typeof value !== 'object' && value.match(regex) ? true : false;
        format && (value = `${validateDateTime(value)}Z`);
        format && (value = `${formatDate(value, 'YYYY-MM-DDTHH:mm:ss')}Z`);
        value !== this.fields[key] && (typeof value !== 'object' || value === null) && dataSave.push({ name: key, value });
      }
    );
    dataSave.length > 0 && this.saveSim(dataSave);
  }

  saveSim(data) {
    data.forEach(({name, value}, index) => index > 0 && (this.data[name] = value == "" ? null : value));
    this.simsService.saveSim({ sim: this.data }).subscribe(
      () => {
        this.showSnackbar('Sim Saved');
        this.editing = false;
      },
      (err) => {
        this.showSnackbar(err.error.errorMessage);
        console.log(err);
      }
    );
  }

  showSnackbar(text) {
    this.sectionEditedText = text;
    this.triggerSnackbar = true;
    this.sectionSnackbar.snackbarShow();
  }

  datetimeFormat = (text) => formatDate(text, 'DD-MM-YYYY HH:mm:ss', 2);

  showSectionEditing(section) {
    if (document.getElementById(section.name + '-content')) {
      return document.getElementById(section.name + '-content').clientHeight > 0;
    }
    return false;
  }

  getOptions = (field) => {
    if (!field.inProcess){
      field.inProcess = true;
      field.options = [];
      if ((field.widgetType === 'SELECT' && field.classType === 'ENUM') || (field.widgetType === 'SELECT_MULTIPLE' && field.subClassType === 'ENUM')) this.getEnumIntrospection(field);
      // else this.getEntityList(field);
    }
  }; 

  async getEnumIntrospection(field) {
    const res = await this.introspectionService.getIntrospectionSection((field.widgetType === 'SELECT_MULTIPLE') ? field.subClassName : field.className);
    if (!field.required && field.widgetType !== 'SELECT_MULTIPLE') res[this.values].unshift({ name: '', value: 'null' });
    const options = res[this.values];
    allowedOptions(field, options);
  }

}
