import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../global.component';
import { backTreeNavigation } from '../utils/navigationUtils';

@Injectable({
  providedIn: 'root',
})
export class LandingsService {

  constructor(private http: HttpClient, private router: Router) {}

  dataList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/landing/ListLandingsRequest', body, httpOptions);
  }

  stateSave(body) {
    body['type'] = 'LANDING';
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  data(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/landing/GetLandingRequest', body, httpOptions);
  }

  dataSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/landing/SaveLandingRequest', body, httpOptions);
  }

  clone(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/CloneEntityRequest', body, httpOptions);
  }

  getDataList = async (body) => this.dataList(body).toPromise();

  getData = async (body) => this.data(body).toPromise().then(res => res).catch(() => backTreeNavigation(this.router));
}
