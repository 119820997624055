import { Component, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { mapKeys } from 'lodash';

import { IntrospectionService } from '../../services/introspection/introspection.service';
import { AppletsService } from '../../applets/applets.service';
import { CampaignsService } from '../../campaigns/campaigns.service';
import { EndpointsService } from '../../endpoints/endpoints.service';
import { VaServicesService } from '../../vaservices/vaservices.service';
import { PricepointsService } from '../../pricepoints/pricepoints.service';
import { IdentifiersService } from '../../identifiers/identifiers.service';
import { SimProfilesService } from '../../sim-profiles/sim-profiles.service';
import { SmppAccountsService } from '../../smpp-accounts/smpp-accounts.service';
import { TpRulesService } from '../../tp-rules/tp-rules.service';

import { openCard } from '../../utils/uiUtils';
import { organizeSection } from '../../utils/sectionUtils';
import { LandingsService } from '../../landings/landings.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddComponent implements AfterViewInit {
  typeNamePath;
  typeName;
  image;
  section;
  typeVO;
  showAdd = false;
  browserUrlTree = 'browserUrlTree';
  values = 'values';
  fieldsName = 'fields';
  state = 'state';
  editedText = '';
  triggerSnackbar = false;
  sections;
  data = {};
  applet = 'applet';
  campaign = 'campaign';
  endpoint = 'endpoint';
  vaService = 'vaService';
  pricepoint = 'pricepoint';
  identifier = 'identifier';
  landing = 'landing';
  simProfile = 'simProfile';
  smppAccount = 'smppAccount';
  tpRule = 'tpRule';
  snackbarTime = 5000;
  main = 'main';
  result = 'result';
  information;

  @ViewChild('typeSnackbar', { static: false }) typeSnackbar;

  constructor(
    private router: Router,
    public introspectionService: IntrospectionService,
    public appletsService: AppletsService,
    public campaignsService: CampaignsService,
    public endpointsService: EndpointsService,
    public vaServicesService: VaServicesService,
    public pricepointsService: PricepointsService,
    public identifiersService: IdentifiersService,
    public simProfilesService: SimProfilesService,
    public smppAccountsService: SmppAccountsService,
    public tpRulesService: TpRulesService,
    public landingsService: LandingsService
  ) {}

  ngAfterViewInit() {
    this.getNames();
    this.getSection();
  }

  getNames() {
    this.typeNamePath = this.router[this.browserUrlTree].root.children.primary.segments[0].path;
    this.image = 'assets/images/' + this.typeNamePath + '.svg';
    let typeNameArray = this.typeNamePath.split('_');
    this.typeName = '';
    typeNameArray.map((type) => this.typeName += type.charAt(0).toUpperCase() + type.slice(1));
    this.typeVO = this.typeName + 'VO';
    this.section = this.typeName + 'Section';
    this.typeName = this.typeName.charAt(0).toLowerCase() + this.typeName.slice(1);
  }

  async getSection() {
    const resSection = await this.introspectionService.getIntrospectionSection(this.section);
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.typeVO);
    this.information = resDescribe[this.main];
    this.sections = organizeSection(resSection[this.values], resDescribe[this.fieldsName]);
    resDescribe[this.fieldsName].map((item) => 
      (item.name !== 'binds')
        ? (item.defaultValue ? this.data[item.name] = item.defaultValue : this.data[item.name] = '')
        : (this.data[item.name] = [])
    );
    this.openCard();
  }

  openCard() {
    this.data[this.state] = 'EDITING';
    this.showAdd = true;
    openCard(this.typeName);
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    console.log('showSnackbar ADD: ' + snackbar.text);
    this.editedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    console.log(this.snackbarTime);
    this.typeSnackbar.snackbarShow();
  }

  showSnackbarOld = (text, isError = false) => {
    this.editedText = text;
    this.snackbarTime = (isError)? 15000 : 5000;
    setTimeout ( () => {
        this.typeSnackbar.snackbarShow();
    }, 500);
  }

  hideEditing(active) {
    // console.log(active)
  }

  saveData({ data, component }) {
    delete data.id;
    mapKeys(data, (value, key) => value === '' && delete data[key]);
    switch(this.typeName) { 
      case this.applet: {
        this.saveEntityData(this.appletsService.appletSave({ applet: data }));
        break;
      }
      case this.campaign: {
        this.saveEntityData(this.campaignsService.campaignSave({ campaign: data }));
        break;
      }
      case  this.endpoint: {
        this.saveEntityData(this.endpointsService.endpointSave({ endpoint: data }));
        break;
      }
      case this.vaService: {
        this.saveEntityData(this.vaServicesService.dataSave({ vaService: data }));
        break;
      }
      case  this.pricepoint: {
        this.saveEntityData(this.pricepointsService.dataSave({ pricepoint: data }));
        break;
      }
      case  this.identifier: {
        this.saveEntityData(this.identifiersService.dataSave({ identifier: data }));
      }
      case this.landing: {
        this.saveEntityData(this.landingsService.dataSave({ landing: data }));
        break;
      }
      case this.simProfile: {
        this.saveEntityData(this.simProfilesService.simProfileSave({ simProfile: data }));
        break;
      }
      case  this.smppAccount: {
        this.saveEntityData(this.smppAccountsService.smppAccountSave({ smppAccount: data }));
        break;
      }
      case  this.tpRule: {
        this.saveEntityData(this.tpRulesService.tpRuleSave({ tpRule: data }));
      }
      default: { 
        this.showSnackbarErr("Invalid type: " + this.typeName);
        console.log("Invalid type: " + this.typeName); 
        break;              
      } 
    }
  }

  saveEntityData(serviceDataSave){
    serviceDataSave.subscribe(
      (res) => {
        if (res[this.result] === 'OK') {
          if (this.typeName == this.campaign) localStorage.setItem("isNew", 'true');
          this.showSnackbar(this.information.singularLabel + ' Saved');
          setTimeout(() => {
            this.router.navigate(['/' + this.typeNamePath, res[this.typeName].id]);
          }, 2000);
        }
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  }

}
