import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NodeService } from '../../services/node/node.service';
import { IntrospectionService } from '../../services/introspection/introspection.service';
import { getHeader } from '../../utils/headerUtils';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit, OnChanges {
  imageType;
  header = [];
  hasState = false;
  showEdit = false;
  showView = false;
  filterList = [];


  @Input() hasData;
  @Input() sectionList;
  @Input() section;
  @Input() image;
  @Input() sectionEditedText;
  @Input() snackbarTime;
  @Input() stateSelect;
  @Input() dataFields;
  @Input() stateSelectArray;
  @Input() triggerHeader;
  @Input() triggerSnackbar;
  @Input() isClone = false;
  @Input() isMenu = false;
  @Input() dataListFilterInstrospection;
  @Output() stateSelected = new EventEmitter();
  @Output() cardSelectClick = new EventEmitter();
  @Output() resetHeader = new EventEmitter();
  @Output() resetSnackbar = new EventEmitter();
  @Output() callListFilter = new EventEmitter();
  @Output() cloneProcess = new EventEmitter();
  @Output() processActionMenu = new EventEmitter();
    
  @ViewChild('sectionSnackbar', { static: false }) sectionSnackbar;

  constructor(
    private router: Router,
    private nodeService: NodeService,
    private introspectionService: IntrospectionService
  ) {}

  async ngOnInit() {
    this.imageType = 'assets/images/' + this.image + '.svg';
    this.validateAccess();
    if ((!this.filterList || isEmpty(this.filterList)) && this.dataListFilterInstrospection){
      await this.getFilters() && this.changeFilter();
    }
  }

  async ngOnChanges() {
    setTimeout(() => {
      this.triggerHeader && this.dataFields && (this.getHeader(), this.resetHeader.emit());
      this.triggerSnackbar && (this.activeSnackbar(), this.resetSnackbar.emit());
    }, 500);
  }

  validateAccess = async () => {
    let hasRol = await this.nodeService.resourceAccess(this.section + '-list');
    !hasRol && this.router.navigate(['home']);
    hasRol = await this.nodeService.resourceAccess(this.section + '-save');
    this.showEdit = hasRol ? true : false;
    hasRol = await this.nodeService.resourceAccess(this.section + '-view');
    this.showView = hasRol ? true : false;
  }

  activeSnackbar = () => {
    this.sectionSnackbar.snackbarShow();
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.sectionEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.activeSnackbar();
  }

  getHeader() {
    this.dataFields.find((element) => (this.hasState = getHeader(element)));
    this.dataFields.find((element) => { element.section === 'HEADER' && this.header.push(element.name); });
  }

  findHeader = (data) => this.header.some((element) => element === data);

  stateSelectedFunction = ($event, id, i) => this.stateSelected.emit({$event, id, i});

  cardSelect = (data) => this.cardSelectClick.emit(data.id);

  addItem = () => this.router.navigate(['/' + this.section + '', 'add']);

  callMenu = (id) => {
    const menu = document.getElementById(`menu-${id}`).style;
    if (menu.visibility === 'hidden') {
      menu.visibility = 'visible';
      menu.opacity = '1';
    } else {
      menu.visibility = 'hidden';
      menu.opacity = '0';
    }
  }

  clone = (id) => {
    this.callMenu(id);
    this.cloneProcess.emit({id});
  }

  filterSelected = (name) => { 
    return localStorage.getItem(name);
  }
  
  changeFilter = (name = null, value = null) => {
    if ( name !== null ) localStorage.setItem(name, value);
    let body = {};
    if (this.dataListFilterInstrospection){
      this.filterList.forEach(filter => {
        const filterValue = localStorage.getItem(filter.name);
        body[filter.name] = (!filterValue || filterValue === 'ALL') ? null : filterValue;
      });
    }
    this.callListFilter.emit(body);
  }

  changeFilterChekbox = (name, $event) => {
    if ($event.target.tagName === 'INPUT') {
      const radioButton = (document.querySelector(`input[type="radio"][name="${name}"]:checked`) as HTMLInputElement)?.value;
      const value = (radioButton === undefined) ? null : (radioButton === 'true');
      this.changeFilter(name, value);
   }
  }

  async getFilters() {
    const filters = await this.introspectionService.getIntrospectionDescribe(this.dataListFilterInstrospection);
    this.filterList = filters['fields'];
    this.filterList.map ( async (filter, index) => { 
      const widgetType = filter.widgetType;
      if ( widgetType ){
        if ( widgetType === 'SELECT' && filter.className && filter.className != "Long"){
          const options = await this.introspectionService.getIntrospectionSection(filter.className);
          filter.options = options['values'];
          filter.options.unshift({ name: 'ALL', value: 'ALL' });
          if (!localStorage.getItem(filter.name) || !this.filterValid(localStorage.getItem(filter.name), filter.options)) localStorage.setItem(filter.name, 'ALL');
        }
        if ( widgetType === 'CHECKBOX' ){
          filter.value = null;
          localStorage.setItem(filter.name, null);
        } 
      }
    });
    return true;
  }

  filterValid = (value, options) => {
    let valid = false;
    options.forEach ( option => {
      if (option.value === value){
        valid = true;
        return;
      }
    });
    return valid;
  };

  process = (action, id, index) => {
    this.callMenu(id);
    this.processActionMenu.emit({action, id, index});
  };
  
  hasMenu = (item) => {
    let hasMenu = false;
    this.dataFields.forEach(field => {
      if (field.section == "MENU" && item[field.name]){
        hasMenu = true;
      }
    });
    return hasMenu;
  };

}
