import { Component, OnInit } from '@angular/core';

import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit {

  section = false;
  sectionId = 'campaigns';
  image = 'campaign';
  title;
  description;
  loadingDataList = true;
  main = 'main';
  fields = 'fields';
  dataFields;

  constructor( private introspectionService: IntrospectionService ) { }

  ngOnInit() {
    this.getCampaignsInfo();
  }

  async getCampaignsInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('CampaignVO');
    this.title = resDescribe[this.main].pluralLabel;
    this.description = resDescribe[this.main].description;
    this.section = true;
    this.dataFields = resDescribe[this.fields];
  }

  showView() {
    this.loadingDataList = false;
  }

}
