import { Component, AfterViewInit, Input, ViewChildren, QueryList, OnChanges, Output, EventEmitter } from '@angular/core';
import { find, mapKeys } from 'lodash';

import { NodeService } from '../../../services/node/node.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';
import { EndpointsService } from '../../../endpoints/endpoints.service';
import { CampaignsService } from '../../../campaigns/campaigns.service';
import { validateForm, detectChangesForm, validateInputsWithFields } from '../../../utils/formUtils';
import { inputValidation, showError } from '../../../utils/dataUtils';
import { validateLabels, getName } from '../../../utils/sectionUtils';
import { inputChange } from '../../../utils/uiUtils';

@Component({
  selector: 'app-sleepy-flow-actions',
  templateUrl: './sleepy-flow-actions.component.html',
  styleUrls: ['./sleepy-flow-actions.component.css']
})
export class SleepyFlowActionsComponent implements AfterViewInit, OnChanges {

  @Input() actions;
  @Input() state;
  @Input() fieldsSection;
  @Input() restore;
  @Input('isNew') isNew = false;
  @Output() getSFA = new EventEmitter();
  @Output() setEditing = new EventEmitter();
  @Input() setSFA;
  @Input() show;
  @Output() showSnackbar = new EventEmitter();
  @Output() callDisplay = new EventEmitter();
  @Output() getOptions = new EventEmitter();

  @ViewChildren('Input') inputs: QueryList<[]>;

  newActions = [];
  fields = 'fields';
  showSection = false;
  values = 'values';
  results = '_results';
  setTexts = false;
  setInputs = false;
  selectsSFA;
  dataActions  = [];
  actionsMock = [];
  types = [];
  getName = getName;
  
  constructor(
    private introspectionService: IntrospectionService,
    private nodeService: NodeService
  ) { }

  ngAfterViewInit() {
    this.initApp();
  }

  async initApp() {
    this.actions.map((action, index) => this.getDescribeVORequest(action, index));
    this.newActions = this.actions.slice(0);
    this.actionsMock = this.actions.slice(0);
    this.stkActionType();
    const hasRol = await this.nodeService.resourceAccess('campaign-save');
    if (hasRol) {
      this.validateLogs();
    } else {
      this.state = 'NOEDIT';
      this.showSection = true;
    }
  }

  ngOnChanges() {
    this.setSFA && this.getSFA.emit(this.validateSFA());
    this.restore && (
      this.actions = this.actionsMock.slice(0),
      this.actions.map((action, index) => this.getDescribeVORequest(action, index)),
      this.newActions = this.actionsMock.slice(0)
      );
  }

  getOptionsChild(field) {
    this.getOptions.emit(field);
  }

  async validateSFA() {
    const newData = await this.validateNewData();
    // agregar time para validar el form
    const hasEmpty = await validateForm(this.inputs, newData);

    this.newActions = this.newActions.filter((bind) => !bind.deleted);
    validateForm(this.inputs, newData);
    this.newActions.map(
      (item, index) => (mapKeys(
        item, (value, key) => item[key] = newData[key + '-' + index]
      ), delete item.deleted)
    );

    this.newActions.map(item => (mapKeys(item, (value, key) => key === 'okAction' && value === '' && (item.okAction = null))));
    if(this.newActions.length === 0) return false
    return !hasEmpty ? this.newActions : false;
  }

  async validateNewData() {
    this.setTexts = true;
    this.setInputs = true;
    const newData = {};
    this.newActions.map(
      (item, index) => mapKeys(
        item, (value, key) => newData[key + '-' + index] = value
      )
    );
    return newData;
  }

  async stkActionType() {
    const resDescribe = await this.introspectionService.getIntrospectionSection('StkActionType');
    this.selectsSFA = resDescribe[this.values];
  }

  async getDescribeVORequest(action, i, isNew = false) {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(action.type);
    isNew
      ? (this.newActions[i] = this.addType(action, resDescribe[this.fields]))
      : (this.newActions[i] = this.validateFields(action, resDescribe[this.fields]));
    this.dataActions[i] = resDescribe[this.fields];

    this.dataActions[i].map((data) => {
      data.data = this.newActions[i][data.name];
      data.label = data.label;
    });
    validateLabels([{field: this.dataActions[i]}], this.dataActions[i]);

    setTimeout(() => {
      this.callDisplay.emit([{field: this.dataActions[i]}]);
    }, 500);
  }

  validateInputsWithFields() {
    this.dataActions.forEach((data, index) => {
      validateInputsWithFields(
        this.inputs, [{field: this.dataActions[index]}], [...this.dataActions[index], ...this.fieldsSection], index
      );
    });
    inputChange();
  }

  addType(action, fields) {
    const provisionAction = {name: action.name, type: action.type, new: true};
    fields.forEach(element => {
      element.mode === 'READ_WRITE' &&
        element.name !== 'name' &&
        (element.name !== 'text' && element.name !== 'inputs' && element.name !== 'title' && element.name !== 'items') &&
        (provisionAction[element.name] = '');
      element.mode === 'READ_WRITE' &&
       (element.name === 'text' || element.name === 'inputs' || element.name === 'title' || element.name === 'items') &&
       (provisionAction[element.name] = []);
    });
    return provisionAction;
  }

  validateFields(action, fields) {
    fields.forEach(element => {
      element.mode === 'READ_WRITE' &&
      !action[element.name] &&
      (action[element.name] = '');
    });
    return action;
  }

  async validateLogs() {
    this.showSection = true;
    setTimeout(() => {
      detectChangesForm(
        this.inputs[this.results],
        () => this.setEditing.emit(),
        () => this.validateInputsWithFields(),
        null
      );
      this.validateInputsWithFields();
    }, 100);
  }

  showButton = () => this.setEditing.emit();

  isRequired = (input)  => input ? true : false;

  getValue(data, id) {
    if (id.className === 'Long') {
      return data === undefined ? '' : this.getValue2(data, id.targetClassName);
    }
    return data === undefined ? '' : data;
  }

  getValue2(id, targetClassName) {
    if (targetClassName === 'EndpointVO') {
      const endpoint = find(id.options, (log) => log.id === id);
      return endpoint && endpoint.id;
    }
  }

  inputValidation = (id) => inputValidation(id);

  showError = (data) => showError(data);

  addItem(i = null) {
    i !== null && this.newActions.splice(i, 0, {name: null, type: null, new: true});
    i !== null && this.dataActions.splice(i, 0, []);
    i === null && this.newActions.push({name: null, type: null, new: true});
    i === null && this.dataActions.push([]);
  }

  changePosition(i, move) {
    const validate = move === 1 ? this.newActions.length - 1 : i > 0;
    if (validate) {
      const action = this.newActions[i];
      const data = this.dataActions[i];
      this.delete(i);
      this.newActions.splice(i + move, 0, action);
      this.dataActions.splice(i + move, 0, data);
    }
  }

  delete(i) {
    this.newActions.splice(i, 1);
    this.dataActions.splice(i, 1);
    this.setEditing.emit();
    delete this.types[i];
  }

  validateSelection(field, i) {
    this.newActions[i].type = field.name;
    this.types[i] = field.label;
    this.getDescribeVORequest(this.newActions[i], i, true);
  }

  async getTexts({data, name}) {
    const position = await data;
    this.newActions[position[1]][name] = position[0];
    this.setTexts = false;
  }

  validateCheckbox(name, value, index) {
    let returnValue = false;
    this.newActions[index][name] === '' && (this.newActions[index][name] = false);
    this.inputs[this.results].forEach(input => {
      if (input.nativeElement) {
        const replace = input.nativeElement.id.replace(`-checkbox${index}`, '');
        const radioButton = (document.querySelector(`input[type="radio"][name="${replace}${index}"]:checked`) as HTMLInputElement)?.value;
        const valueRadioButton = radioButton && radioButton.replace(replace, '');
        if (replace === name) {
          if (valueRadioButton !== undefined) {
            this.newActions[index][name] = valueRadioButton  === 'true' ? true : false;
          }
          returnValue = value === this.newActions[index][name];
        }
      }
    });
    return returnValue;
  }

  showSnackbarS(text) {
    this.showSnackbar.emit(text)
  }

}
