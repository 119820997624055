import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { Router } from '@angular/router';

declare var jQuery: any;
declare var $: any;  

import { formatDate, getEvents } from '../utils/dataUtils';
import { activeCalendar } from '../utils/uiUtils';
import { stateSelected, stateActive } from '../utils/stateUtils';

import { ScheduleService } from './schedule.service';
import { CampaignsService } from '../campaigns/campaigns.service';
import { IntrospectionService } from '../services/introspection/introspection.service';
import { NodeService } from '../services/node/node.service';
import { getFilterField } from '../utils/formUtils';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})

export class ScheduleComponent implements OnInit {
  year = formatDate(new Date(), 'YYYY', 1);
  showCalendar = false;
  campaign = 'campaign';
  campaigns = 'campaigns';
  name = 'name';
  events = [];
  calendarOptions: CalendarOptions = {
    selectable: true,
    editable: true,
    eventContent: (arg: any) => {
      const campaignSchedule = arg.event.extendedProps.campaignSchedule;
      const describeSchedule = arg.event.extendedProps.describeSchedule;

      let strPreview = '';
      if (campaignSchedule && describeSchedule) {
        describeSchedule.forEach ( field => {
          if (field.section === "KPIS" && field.widgetType === "TEXT" && campaignSchedule[field.name] !== undefined){
            if (field.name === 'todayCtr') strPreview += '&ensp;' + field.label + ':&ensp;<label class="porcent" style="color:#' + campaignSchedule.todayCtrPercentColor + ';">' + campaignSchedule[field.name] + '</label>';
            else if (field.name === 'allTimeCtr') strPreview += '&ensp;' + field.label + ':&ensp;<label class="porcent" style="color:#' + campaignSchedule.allTimeCtrPercentColor + ';">' + campaignSchedule[field.name] + '</label>';
            else strPreview += '&ensp;' + field.label + ': ' + campaignSchedule[field.name];
          }
        });
      }

      let divEvent = document.createElement('div');
      divEvent.setAttribute('style', "margin-left: 20px; justify-content: space-between; display: flex;");
      divEvent.innerHTML = '<div style="display: flex;">' + arg.event.title + '</div><div style="display: flex;">' + strPreview + '</div';

      let divButtonEdit = document.createElement('div');
      divEvent.appendChild(divButtonEdit);
      this.showState = false;

      if (this.hasRolSave){
        divButtonEdit.innerHTML = 
          '<i class="material-icons card-icon" id="campaigns-icon">keyboard_arrow_down</i>'
        divButtonEdit.setAttribute('style', "float: right;margin-right: 20px;");
        divButtonEdit.addEventListener("click", (e: Event) => {
          let iButton =  divButtonEdit.firstChild as HTMLElement;
          if (!this.showForm){
            iButton.innerHTML = 'keyboard_arrow_up';
            this.editEvent(arg, divEvent as HTMLElement);
          }
          else{
            iButton.innerHTML = 'keyboard_arrow_down';
            this.closeFormEvent();
          }
        });

        setTimeout( () => {
          let nodeDot = ((divEvent.parentNode as HTMLElement).parentNode as HTMLElement).getElementsByClassName('fc-list-event-graphic')[0];
          let iButtonState = nodeDot.getElementsByTagName('i')[0];
          if (!iButtonState){
            iButtonState = document.createElement('i');    
            nodeDot.appendChild(iButtonState);
            iButtonState.addEventListener("click", (e: Event) => {
              iButtonState.style.display = 'none';
              (nodeDot.firstChild as HTMLElement).style.display = '';
              this.showState = false;
              e.stopPropagation();
            });
          }
          iButtonState.setAttribute('class', "material-icons card-icon");
          iButtonState.innerHTML = 'keyboard_arrow_up';
          iButtonState.style.display = 'none';
          nodeDot.addEventListener("click", (e: Event) => {
            iButtonState.style.display = 'inline';
            this.viewChangeState(arg, e.currentTarget as HTMLElement);
          });
        }, 500);
      }

      let arrayOfDomNodes = [ divEvent ];
      return { domNodes: arrayOfDomNodes };
    },
    nowIndicator: true,
    height: 'auto',
    customButtons: {
      save: {
        text: 'New',
        click: this.addEvent.bind(this)
      },
    },
    buttonText: {
      today: 'Today',
      listDay: 'Day',
      listWeek: 'Week',
      listMonth: 'Month'
    },
    headerToolbar: {
      start: 'today,prev,next,save',
      center: 'title',
      end: 'listMonth,listWeek,listDay'
    },
    initialView: 'listWeek',
    displayEventEnd: true,
    events: this.events,
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },
    fixedWeekCount: false,
    slotLabelFormat: { hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false },
    allDaySlot: false
  };
  describeSchedule;
  fieldsEvent = [];
  fieldsCkeckbox = [];
  daysForRow = 3;
  houresForRow = 4;
  actionEvent = 'Create New Campaign Schedule';
  showForm = false;
  eventEdit = {};
  new = true;
  campaignSchedule = 'campaignSchedule';
  stateAvailable = [];
  values = 'values';
  id = 'id';
  time = 3000;
  editedText = '';
  showEdit = false;
  states = [];
  arg;
  stateSelect = [];
  stateSelectArray = [];
  showState = false;
  resultService = 'result';
  campaignId;
  hasRolSave = false;
  campaingsSelectList;
  getCampaingsSelectListInProcess = false;

  @ViewChild('scheduleSnackbar', { static: false }) scheduleSnackbar;
  @ViewChild('calendar') calendarComponent :  FullCalendarComponent;

  constructor(
    private scheduleService: ScheduleService,
    private campaignsService: CampaignsService,
    private introspectionService: IntrospectionService,
    private router: Router,
    private nodeService: NodeService
  ) {}

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect,
      this.campaignsService.campaignStateSave({ id, type: 'CAMPAIGN', state: $event.name.toUpperCase(), }),
      this.resultService,
      this.campaignsService.campaign({ id }),
      this.getCurrentDates,
      this.showSnackbar
    );
  }

  getCurrentDates = () => {
    this.currentDates();
    this.showState = false;
  };

  preventSelection(el) {
    console.log('preventSelection');
  } 

  viewChangeState (arg, parentState) {
    if (parentState.tagName === 'SPAN') parentState = parentState.parentNode as HTMLElement;
    parentState.firstElementChild.style.display = "none";

    if (this.showState){
      let stateOld = document.getElementById('state');
      if (stateOld){
        stateOld.style.display = 'none';
        if (!stateOld.parentElement.id){
          stateOld.parentElement.getElementsByTagName('i')[0].click();
        }
        if (parentState === stateOld.parentElement) return;
      }
    }

    this.showState = false;
    this.stateSelect = arg.event.extendedProps.stateSelect;
    this.stateSelectArray = arg.event.extendedProps.stateSelectArray;
    this.campaignId = arg.event.extendedProps.campaignId;
    this.showState = true;
    setTimeout( () => {
      let state = document.getElementById('state');
      parentState.appendChild(state);
      state.addEventListener("click", (e: Event) => {
        e.stopPropagation();
      });      
      state.style.display = '';
    }, 200);    
  }

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.validateAccess();
  }

  validateAccess = async () => {
    let hasRol = await this.nodeService.resourceAccess('scheduler-list');
    !hasRol && this.router.navigate(['home']);
    hasRol = await this.nodeService.resourceAccess('scheduler-view');
    !hasRol && this.router.navigate(['home']);
    if (!hasRol) {
      this.calendarOptions.eventClick = () => {};
    }
    hasRol = await this.nodeService.resourceAccess('scheduler-save');
    this.hasRolSave = hasRol ? true : false;
    if (!this.hasRolSave) this.calendarOptions.customButtons = {};
    this.initApplication();
  }

  initApplication() {
    this.getEvents();
    this.detectMoveCalendar();
    this.getIntrospection();
    this.getStates();
  }

  async getEvents() {
    let nowDate = new Date();
    const dayOfWeek = nowDate.getDay();
    while (nowDate.getDay() !== 0){
      nowDate.setDate(nowDate.getDate() - 1);
    }

    const date = {
      fromTs: formatDate(nowDate.setDate(nowDate.getDate()), 'YYYY-MM-DD', 1) + 'T00:00:00Z',
      toTs: formatDate(nowDate.setDate(nowDate.getDate() + 7), 'YYYY-MM-DD', 1) + 'T00:00:00Z'
    };

    const events = await this.scheduleService.getScheduleList(date);
    this.calendarOptions.events = await getEvents(events, date, () => this.getIntrospection(), (campaign) => this.getStateActive(campaign));
    this.showCalendar = true;
  }

  detectMoveCalendar() {
    setTimeout(() => {
      if (jQuery('.fc-prev-button').length > 0){
        jQuery('.fc-prev-button').on('click', () => this.currentDates());
        jQuery('.fc-next-button').on('click', () => this.currentDates());
        jQuery('.fc-listMonth-button').on('click', () => this.currentDates());
        jQuery('.fc-listWeek-button').on('click', () => this.currentDates());
        jQuery('.fc-listDay-button').on('click', () => this.currentDates());
        jQuery('.fc-today-button').on('click', () => this.currentDates());
      }
      else this.detectMoveCalendar();
    }, 1000);
  }

  async currentDates() {
    this.showForm=false;
    this.showState=false;
    let calendarApi = this.calendarComponent.getApi();

    const date = {
      fromTs: formatDate(calendarApi.view.activeStart, 'YYYY-MM-DD', 0) + 'T00:00:00Z',
      toTs: formatDate(calendarApi.view.activeEnd, 'YYYY-MM-DD', 0) + 'T00:00:00Z'
    };

    const events = await this.scheduleService.getScheduleList(date);
    this.calendarOptions.events = await getEvents(events, date, () => this.getIntrospection(), (campaign) => this.getStateActive(campaign));
    this.showCalendar = true;
  }

  getCampaign = async (id) => { 
    const campaign = (await this.campaignsService.getCampaign({ id }))[this.campaign];
    return campaign;//.title;
  }

  getPreview = async (id) => {
    const campaignId = id;
    const preview = await this.campaignsService.getCampaignPreviewRequest({ campaignId });
    if(preview['campaignPreview']){
      return preview['campaignPreview'];
    } else {
      return false;
    }
  }

  getStateActive = (campaign) => {
    const campaigns = [];
    campaigns.push(campaign);
    return stateActive(campaigns, [], this.states);
  }

  async editEvent(arg, divButtonEdit) {
    if (this.showForm) this.closeFormEvent();
    this.arg = arg.event['_instance'].range;
    const id = arg.event.id;
    this.eventEdit = arg.event.extendedProps.campaignSchedule;
    this.actionEvent = '';
    this.new = false;
    this.editEvent[this.id] = Number(id);
    this.initEvent(divButtonEdit.parentNode, true);
  }

  initEvent(divParent, isEdit){
    this.showForm = true;
    setTimeout( () => {
      let divEvent = document.getElementById('event');
      if (isEdit) divParent.appendChild(divEvent);
      else  divParent.insertBefore(divEvent, divParent.firstChild);
      divEvent.style.display = '';
      divEvent.style.userSelect = 'all';

      let inputList = Array.prototype.slice.call(divEvent.getElementsByTagName('input'));
      inputList.forEach(element => {
          element.addEventListener("mousedown", (e: Event) => {
          e.stopImmediatePropagation();
        });   
      });
    }, 500);
  }

  closeFormEvent () {
    let formEvent = document.getElementById('event');
    if (formEvent){
      let parentFormEvent = formEvent.parentNode as HTMLElement;
      if (parentFormEvent){
        parentFormEvent.removeChild(formEvent);
        (parentFormEvent.getElementsByTagName('i')[0]) && (parentFormEvent.getElementsByTagName('i')[0].innerHTML = 'keyboard_arrow_down');
      }
    }
    this.showForm = false;
    activeCalendar(true);
  };

  addEvent() {
    if (this.showForm){ this.closeFormEvent(); return;}
    this.actionEvent = 'Create New Campaign Schedule';
    this.new = true;
    let nowDate = new Date();
    this.eventEdit = {
      fromTs: formatDate(nowDate, 'YYYY-MM-DD', 1) + 'T00:00:00Z',
      toTs: formatDate(nowDate.setDate(nowDate.getDate() + 1), 'YYYY-MM-DD', 1) + 'T00:00:00Z'
    };
    this.initEvent(document.getElementsByClassName('fc-list')[0], false);
  }

  getEntityList = async ($event) => {
    const field = $event.field;
    const searchAttribute = $event.searchAttribute;
    if (!field.inProcess){
      field.inProcess = true;
      field.options = [];
      const entityVO = field.targetClassName;
      let options = [];
      const filterField = getFilterField(field);
      if (searchAttribute != null){
        filterField['searchAttribute'] =  searchAttribute;
      }
  
      switch (entityVO){
        case 'CampaignVO': {
          const res = await this.campaignsService.getCampaignsList(filterField);
          field.options = await res[this.campaigns];
          break;
        }
      }
     
      field.inProcess = false;
    }
  };
  
  async getIntrospection() {
    if (!this.describeSchedule) {
      this.describeSchedule = await this.introspectionService.getIntrospectionDescribe('CampaignScheduleVO');
      if (this.fieldsEvent.length === 0)
        this.setFieldsIntrospection(this.describeSchedule.fields)
    }
    return this.describeSchedule;
  }

  setFieldsIntrospection(describeSchedule) {
    let fieldsDays = [];
    let fieldsHoures = [];
    let rowDays = [];
    let rowHoures = [];
    describeSchedule.forEach( field => {
      if (field.mode === 'READ_WRITE'){
        if (field.widgetType === 'CHECKBOX'){
          if (field.section === "HOURS") {
            field.width = ( 1/this.houresForRow * 100 ) + '%';
            rowHoures.push(field);
            if (rowHoures.length == this.houresForRow ){
              fieldsHoures.push(rowHoures.slice());
              rowHoures = [];
            } 
          }
          else if (field.section === "DAYS"){
            field.width = ( 1/this.daysForRow * 100 ) + '%';
            rowDays.push(field);
            if (rowDays.length == this.daysForRow ){
              fieldsDays.push(rowDays.slice());
              rowDays = [];
            } 
          }
        }
        else this.fieldsEvent.push(field);
      }
    });
    fieldsDays.push(rowDays.slice());
    this.fieldsCkeckbox = fieldsDays.concat(fieldsHoures);
  }

  saveSchedule(body) {
    this.scheduleService.saveSchedule({ campaignSchedule: body }).subscribe(
      (res) => {
        // this.getEvents();
        this.currentDates();
        this.closeFormEvent();
        this.showSnackbar('Schedule Saved');
      },
      (err) => {
        console.log(err);
        this.showSnackbar(err.error.errorMessage);
      }
    );
  }

  deleteSchedule(body) {
    this.scheduleService.deleteSchedule({ id: body }).subscribe(
      (res) => {
        this.currentDates();
        this.closeFormEvent();
        this.showSnackbar('Schedule Deleted');
      },
      (err) => {
        console.log(err);
        this.showSnackbar(err.error.errorMessage);
      }
    );
  }

  async getStates() {
    this.stateAvailable = (await this.introspectionService.getIntrospectionSection('EntityState'))[this.values];
  }

  showSnackbar = (text) => {
    this.editedText = text;
    this.scheduleSnackbar.snackbarShow();
  }

}
