import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EndpointsService } from '../endpoints/endpoints.service';
import { VaServicesService } from '../vaservices/vaservices.service';
import { PricepointsService } from '../pricepoints/pricepoints.service';
import { IdentifiersService } from '../identifiers/identifiers.service';
import { CampaignsService } from '../campaigns/campaigns.service';
import { SmppAccountsService } from '../smpp-accounts/smpp-accounts.service';
import { StatsService } from '../common/section/stats/stats.service';
import { IntrospectionService } from '../services/introspection/introspection.service';

import { convertToLocalTime, formatDate, validateDate } from '../utils/dataUtils';
import { openCard } from '../utils/uiUtils';

import * as moment from 'moment';
import { LandingsService } from '../landings/landings.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-stats-view',
  templateUrl: './stats-view.component.html',
  styleUrls: ['./stats-view.component.css']
})
export class StatsViewComponent implements OnInit {

  title = 'Stats';
  description = 'Stats';
  stats = false;
  statsId = 'stats';
  loadingDataList = true;
  selectOptionList = [
    {
      name: 'Campaigns',
      id: 'Campaigns'
    },
    {
      name: 'Endpoints',
      id: 'Endpoints'
    },
    {
      name: 'Smpp Account',
      id: 'Smpp Account'
    },
    {
      name: 'VaServices',
      id: 'VaServices'
    },
    {
      name: 'Pricepoints',
      id: 'Pricepoints'
    },
    {
      name: 'Identifiers',
      id: 'Identifiers'
    },
    {
      name: 'Landings',
      id: 'Landings'
    }
  ];
  displayOptions = false;
  displayToggle = false;
  entityOptions = [];
  endpoints = 'endpoints';
  identifiers = 'identifiers';
  pricepoints = 'pricepoints';
  vaservices = 'vaServices';
  landings = 'landings';
  option;
  showStats = false;
  results = '_results';
  optionSelected;
  kpis = 'kpis';
  arrayStats = [];
  tablePrimaryTextAlign = [];
  tablePrimaryHead = [];
  tablePrimaryBody = [];
  tablePrimaryArrayComponents = [];
  values = 'values';
  path;
  statView = 'statView';
  campaigns = 'campaigns';
  smppAccounts = 'smppAccounts';
  toggle = false;
  chartType = 'Line';
  params = 'params';
  placeholderEntity = null;
  placeholderOption = null

  @ViewChildren('datepicker') datepicker: QueryList<[]>;

  constructor(
    private endpointsService: EndpointsService,
    private vaServicesService: VaServicesService,
    private pricepointsService: PricepointsService,
    private identifiersService: IdentifiersService,
    private campaignsService: CampaignsService,
    private smppAccountsService: SmppAccountsService,
    private statsService: StatsService,
    private introspectionService: IntrospectionService,
    private landingsService: LandingsService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.stats = true;
    this.loadingDataList = false;
    openCard(this.statsId);
    if(window.localStorage.getItem('action') === 'smppAccounts') {
      this.initSmppStats();
      window.localStorage.removeItem('action');
    }
  }

  initSmppStats () {
    this.placeholderEntity = "Smpp Account"
    this.changeEntity({name: this.placeholderEntity});
    this.setAllEntityOption();
  }

  setAllEntityOption () {
    if (!isEmpty(this.entityOptions)){
      this.placeholderOption = "All";
      this.changeOption({name: this.placeholderOption});
      this.setDatesForm();
    }
    else setTimeout(() => this.setAllEntityOption(), 1000);
  }

  setDatesForm () {
    if (!isEmpty(this.arrayStats)){
      const start = formatDate(moment(formatDate(new Date(), 'DD-MM-YYYY', 1), "DD-MM-YYYY").add(-1, 'days'), 'DD-MM-YYYY');
      const end = formatDate(moment(start, "DD-MM-YYYY").add(1, 'days'), 'DD-MM-YYYY');
      this.datepicker[this.results][1].DatepickerInput1 = start;
      this.datepicker[this.results][2].DatepickerInput1 = end;
      this.validateForm();
    }
    else setTimeout(() => this.setDatesForm(), 1000);
  }

  changeEntity(event) {
    this.displayOptions = false;
    this.getEntityOptions(event.name);
    this.placeholderOption = null;
    this.displayOptions = true;
  }

  getEntityOptions = async (option, searchAttribute = null) => {
    this.showStats = false;
    this.option = option;

    const filterField = {};
    if (searchAttribute != null){
      filterField['searchAttribute'] =  searchAttribute;
    }

    if (option === 'Endpoints'){
      this.entityOptions = (await this.endpointsService.getEndpointsList(filterField))[this.endpoints];
      this.path = 'ENDPOINT';
    } 
    else if (option === 'Identifiers'){
      this.entityOptions = (await this.identifiersService.getDataList(filterField))[this.identifiers];
      this.path = 'IDENTIFIER';
    } 
    else if (option === 'Pricepoints'){
      this.entityOptions = (await this.pricepointsService.getDataList(filterField))[this.pricepoints];
      this.path = 'PRICEPOINT';
    } 
    else if (option === 'VaServices'){
      this.entityOptions = (await this.vaServicesService.getDataList(filterField))[this.vaservices];
      this.path = 'VA_SERVICE';
    } 
    else if (option === 'Landings'){
      this.entityOptions = (await this.landingsService.getDataList(filterField))[this.landings];
      this.path = 'LANDING';
    } 
    else if (option === 'Campaigns'){
      this.entityOptions = (await this.campaignsService.getCampaignsList(filterField))[this.campaigns];
      this.path = 'CAMPAIGN';
    }
    else if (option === 'Smpp Account'){
      this.entityOptions = (await this.smppAccountsService.getSmppAccountsList(filterField))[this.smppAccounts];
      this.entityOptions.unshift({label : 'All', name : 'All', id : 0, title : 'All'});
      this.path = 'SMPP_ACCOUNT';
    }

  }

  changeOption = async (event) => {
    const optionSelected = this.entityOptions.find(option => option.name === event.name);
    this.optionSelected = optionSelected.id;
    this.displayToggle = true;
    this.arrayStats = [];
    this.option === 'Campaigns' ?  this.setStatsCampaign(this.optionSelected) : this.setStats(this.option);
  }

  async setStatsCampaign(id) {
    const kpis = await this.statsService.getKpiCampaign({id});
    kpis[this.kpis].forEach(({type, stat, label, entityFk}) => {
      this.arrayStats.push({type, stat, label, entityFk});
      this.tablePrimaryTextAlign.push('center');
      this.tablePrimaryArrayComponents.push(['normal']);
    });
  }

  async setStats(statIntrospection) {
    statIntrospection = statIntrospection === 'Smpp Account' ? 'SmppAccount' : statIntrospection.substring(0,statIntrospection.length-1);
    const stats = await this.introspectionService.getIntrospectionSection(`${statIntrospection}Stat`);
    stats[this.values].forEach((stat) => {
      if (stat.showByDefault) {
        this.arrayStats.push(stat.name);
        this.tablePrimaryTextAlign.push('center');
        this.tablePrimaryArrayComponents.push(['normal']);
      }
    });
  }

  validateForm = () => {
    this.showStats = false;
    this.toggle = this.datepicker[this.results][0].valueSlideToggle ? true : false;
    let date1 = this.datepicker[this.results][1].DatepickerInput1;
    let date2 = this.datepicker[this.results][2].DatepickerInput1;
    if (date1 && date2) {
      date1 = convertToLocalTime(validateDate(date1));
      date2 = convertToLocalTime(validateDate(date2));
      if (this.option === 'Campaigns') {
        const body = {
          from: date1,
          to: date2,
          statResultType: 'LIST_AND_SUM',
          statGranularity: 'DAY',
          kpis: this.arrayStats
        };
        this.getStats(body);
      } else {
        const body = {
          from: date1,
          to: date2,
          statResultType: 'LIST_AND_SUM',
          statGranularity: 'HOUR',
          entityFks: this.optionSelected === 0 ? null: [this.optionSelected],
          statClass: this.path,
          statEnums: this.arrayStats
        };
        this.getStats(body);
      }
    }
  }

  async getStats(body) {
    let stats;
    if (this.path === 'CAMPAIGN') {
      stats = await this.statsService.getStatsCampaign(body);
    } else {
      stats = await this.statsService.getStats(body);
    }
    this.tablePrimaryHead = stats[this.statView].statLabels;
    this.tablePrimaryBody = stats[this.statView].stats;
    this.tablePrimaryBody.forEach((date) => date[0] = formatDate(date[0], 'YYYY-MM-DD HH:mm:ss'));
    if (this.toggle) {
      this.tablePrimaryBody = this.tablePrimaryBody.map((section) =>
        section.map((item, index) => index > 0 ? Number(item) : item)
      );
    } else {
      let sum = stats[this.statView].sum;
      sum.forEach((element, index) => {
        sum[index] = parseFloat(element);
      });
      sum[0] = 'Total';
      this.tablePrimaryBody.push(sum);
    }
    if (stats) this.showStats = true;
  }

  changeType = (event) => this.chartType = event.name;

}
