import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';

import { getNode } from '../../global.component';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(private http: HttpClient, private keycloakService: KeycloakService) { }

  tacho() {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.get(getNode() + '/tachometer', httpOptions);
    
  }

  node() {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/node/GetThisNodeRequest', {}, httpOptions);
  }

  nodeList() {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/node/ListNodesRequest', {}, httpOptions);
  }

  nodeStatus(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ServiceStatusRequest', body, httpOptions);
  }

  nodeDashboard() {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/node/DashboardRequest', {}, httpOptions);
  }

  getTacho = async () => this.tacho().toPromise();

  getNode = async () => this.node().toPromise();

  getNodeList = async () => this.nodeList().toPromise();

  getNodeStatus = async (body) => this.nodeStatus(body).toPromise();

  resourceAccess = async (accessRol) => {
    const node = 'node';
    const clientId = (await this.getNode())[node].clientId;
    const roles =  await this.keycloakService.getKeycloakInstance().resourceAccess[clientId].roles;
    accessRol = accessRol.replace('_','-');
    const hasRol = roles.find((rol) => rol === accessRol);
    return hasRol;
  }

  getNodeDashboard = async () => this.nodeDashboard().toPromise();
}
