import { Component, OnInit } from '@angular/core';
import { IntrospectionService } from '../services/introspection/introspection.service';


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css'],
})
export class SubscriptionsComponent implements OnInit {
  hasSection = false;
  sectionId = 'subscriptions';
  section = 'subscription_event';
  image = 'subscription_event';
  title;
  description;
  loadingDataList = true;
  dataVO = 'SubscriptionEvent';
  main = 'main';
  dataFields;
  fields = 'fields';
  sectionShow = {label: 'Basic Information' , value: 'BASIC_INFORMATION'};

  constructor( private introspectionService: IntrospectionService ) { }

  ngOnInit() {
    this.getDataInfo();
  }

  async getDataInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    this.title = resDescribe[this.main].pluralLabel;
    this.description = resDescribe[this.main].description;
    this.hasSection = true;
    this.dataFields = resDescribe[this.fields];
  }

  showView() {
    this.loadingDataList = false;
  }
}
