import { Component, AfterViewInit, Input, ViewChildren, QueryList, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { mapKeys } from 'lodash';

import { NodeService } from '../../../services/node/node.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';
import { AdsService } from './advertising-ads.service';
import { validateForm, detectChangesForm, validateInputsWithFields, validateInput, setNullInputSimple } from '../../../utils/formUtils';
import { inputValidation, showError } from '../../../utils/dataUtils';
import { validateLabels, getValue } from '../../../utils/sectionUtils';
import { inputChange } from '../../../utils/uiUtils';
import { stateActive } from '../../../utils/stateUtils';

@Component({
  selector: 'app-advertising-ads',
  templateUrl: './advertising-ads.component.html',
  styleUrls: ['./advertising-ads.component.css']
})
export class AdvertisingAdsComponent implements AfterViewInit, OnChanges {
  
  @Input() data;
  @Input() fieldsSection;
  @Input() restore;
  @Input('isNew') isNew = false;
  @Output() getAds = new EventEmitter();
  @Output() setEditing = new EventEmitter();
  @Input() setAds;
  @Input() show;
  @Output() showSnackbarAds = new EventEmitter();
  @Output() callDisplay = new EventEmitter();
  @Output() validateForm = new EventEmitter();
  @Output() getOptions = new EventEmitter();

  @ViewChildren('Input') inputs: QueryList<[]>;
  @ViewChild('adsSnackbar', { static: false }) adsSnackbar;

  state;
  newAds = [];
  fields = 'fields';
  showSection = false;
  values = 'values';
  results = '_results';
  setItems = false;
  setInputs = false;
  dataAds  = [];
  adType;
  editing = false;
  stateSelect = [];
  stateSelectArray = [];
  states = [];
  resultService = 'result';
  editedText = '';
  editingState = 'EDITING';
  adsEditedText = '';
  snackbarTime = 5000;
  setNullInputSimple = (inputs, data, nullTextArea) => setNullInputSimple(inputs, data, nullTextArea);
  getValue = getValue;

  constructor(
    private introspectionService: IntrospectionService,
    private nodeService: NodeService,
    private adsService: AdsService
  ) { }

  ngAfterViewInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.initApp();
  }

  async initApp() {
    this.adType = this.data.adType;
    this.data.ads && this.data.ads.map((ad, index) => this.getDescribeVORequest(ad, index));
    const hasRol = await this.nodeService.resourceAccess('campaign-save');
    if (hasRol) this.setChangesDetect();
    else this.state = 'NOEDIT';
    this.showSection = true;    
  }

  async ngOnChanges() {
    if (this.setAds){
      const valAds = await this.validateAds();
      this.getAds.emit(valAds);
    }
    if (this.restore) {
      this.newAds = [];
      this.dataAds = [];
      this.stateSelect = [];
      this.stateSelectArray = [];
      this.initApp();
    }

  }

  getOptionsChild(field) {
    this.getOptions.emit(field);
  }

  async validateAds(i = 0) {
    const newData = await this.validateNewData();
    let hasEmpty = await validateForm(this.inputs, newData);
    if(hasEmpty) return false;
    this.setNullInputSimple(this.inputs, newData, true);
    this.newAds.map( (item, index) => {
      mapKeys( item, (value, key) => {
        item[key] = newData[key + '-' + index];
      });
    });
    return this.newAds;
  }

  async validateNewData() {
    this.setItems = true;
    this.setInputs = true;
    const newData = {};
    this.newAds.map(
      (item, index) => mapKeys(
        item, (value, key) => newData[key + '-' + index] = value
      )
    );
    return newData;
  }

  async getDescribeVORequest(ad, i = null) {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(ad.type);
    if (i == null){
      this.newAds.unshift(ad);
      this.dataAds.unshift([]);
      i = 0;
    }
    this.newAds[i] = this.validateFields(ad, resDescribe[this.fields]);
    this.dataAds[i] = resDescribe[this.fields];
    const replace = (this.stateSelect.length === this.newAds.length) ? 1 : 0;
    this.getStateActive(this.newAds[i], i, replace);
    this.dataAds[i].map((data) => {
      data.data = this.newAds[i][data.name];
      data.label = data.label;
    });
    validateLabels([{field: this.dataAds[i]}], this.dataAds[i]);
    setTimeout(() => {
      this.callDisplay.emit([{field: this.dataAds[i]}]);
    }, 500);
    return true;
  }
  
  getStateActive(ad: any, i: any, replace: any) {
    const types = [];
    types.push(ad);
    const getStateActive = stateActive(types, [], this.states);
    this.stateSelect.splice(i, replace, getStateActive.stateSelectPrincipal[0]);
    this.stateSelectArray.splice(i, replace, getStateActive.stateSelectArray[0]);
  }

  validateInputsWithFields() {
    this.dataAds.forEach((data, index) => {
      validateInputsWithFields(
        this.inputs, [{field: this.dataAds[index]}], [...this.dataAds[index], ...this.fieldsSection], index
      );
    });
    inputChange();
  }

  validateFields(ads, fields) {
    fields.forEach(element => {
      element.mode === 'READ_WRITE' &&
      !ads[element.name] &&
      (ads[element.name] = '');
    });
    return ads;
  }

  async setChangesDetect() {
    setTimeout(() => {
      detectChangesForm(
        this.inputs[this.results],
        () => this.setEditingAds(event),
        () => this.validateInputsWithFields(),
        null
      );
    }, 1000);
  }

  showButton = (index) => {
    if (this.data.state !== this.editingState && this.newAds[index]) {
      this.newAds[index].editing = true;
    }
  }

  setEditingAds (e) {
    this.validateRequiredAds();
    if (this.data.state !== this.editingState){
      const idElement = e.target.id;
      if (idElement) {
        const splitId = idElement.split("-");
        if (splitId[1] > -1 && this.newAds[splitId[1]])
          this.newAds[splitId[1]].editing = true;
        if (e.target.type === 'radio') 
          this.validateCheckbox(splitId[0], splitId[2], splitId[1]);
      }
    }
  }

  checkboxFalse(name, i) {
    (this.newAds[i][name] === undefined || this.newAds[i][name] === '') && this.setCheckbox(name, false, i);
    return true;
  }
  
  setCheckbox = (name, value, i) => {
    this.newAds[i][name] = value;
  }

  isRequired = (input)  => input ? true : false;

  inputValidation = (id) => inputValidation(id);

  showError = (data) => showError(data);

  async addItem(i) {
    let newAd = {type: this.adType, new: true, state: this.editingState, campaignFk: this.data.id, editing: false};
    const returnDescribe = await this.getDescribeVORequest(newAd);
    if (returnDescribe) {
      this.showAd(0);
      this.setChangesDetect();
    }
  }

  validateInput = (input) => validateInput(input);

  validateSelection(field) {
    this.data[this.fieldsSection[1].name] =  field.name;
    this.adType = field.name;
  }

  async getItems({data, name}) {
    const position = await data;
    this.newAds[position[1]][name] = position[0];
    this.setItems = false;
  }

  validateCheckbox(name, value, index) {
    let returnValue = false;
    this.newAds[index][name] === '' && (this.newAds[index][name] = false);
    this.inputs[this.results].forEach(input => {
      if (input.nativeElement) {
        const replace = input.nativeElement.id.replace(`-checkbox-${index}`, '');
        const radioButton = (document.querySelector(`input[type="radio"][name="${replace}-${index}"]:checked`) as HTMLInputElement)?.value;
        const valueRadioButton = radioButton && radioButton.replace(replace+'-'+index+'-', '');
        if (replace === name) {
          if (valueRadioButton !== undefined) {
            this.newAds[index][name] = valueRadioButton  === 'true' ? true : false;
          }
          returnValue = value === this.newAds[index][name];
        }
      }
    });
    return returnValue;
  }

  showSnackbarAd(text, isError = false) {
    console.log('showSnackbarAd ADS');
    this.adsEditedText = text;
    this.snackbarTime = (isError)? 15000 : 5000;
    this.adsSnackbar.snackbarShow();
  }

  showAd = (i) => {
    this.newAds[i].display = !this.newAds[i].display;
    this.newAds[i].displayCreatives = this.newAds[i].display;
  };
  
  showCreatives = (i) => {
    this.newAds[i].displayCreatives = !this.newAds[i].displayCreatives;
  }

  showStats = (i) => {
    this.newAds[i].displayStats = !this.newAds[i].displayStats;
  }

  showSectionAd = (i, sectionName) => {
    const up = document.getElementById(i + '-' + sectionName + '-up');
    const down = document.getElementById(i + '-' + sectionName + '-down');
    const contentSection = document.getElementById(i + '-content-' + sectionName);
    if (contentSection && contentSection.style.display === 'none') {
      contentSection && (contentSection.style.display= 'block');
      up && (up.style.display = 'block');
      down && (down.style.display = 'none');
    } else {
      contentSection && (contentSection.style.display = 'none');
      up && (up.style.display = 'none');
      down && (down.style.display = 'block');
    }
  };
  
  async stateSelectedFunction($event, ad, i) {
    const params = [$event, ad, i];
    const newState = ($event === 'ARCHIVED') ? 'ARCHIVED' :  $event.name.toUpperCase();
    const id = ad.id;
    this.adsService.adStateSave({ id, type: 'AD', state: newState }).subscribe(
      async (res) => {
        if (res[this.resultService] === 'OK') {
          this.showSnackbarAd('Ad State Changed');
          this.newAds[i].state = await res['state'];
          this.newAds[i].allowedTransitionStates = await res['allowedTransitionStates'];
          this.getStateActive(this.newAds[i], i, 1);
        }
      },
      (err) => {
        console.log(err);
        this.showSnackbarAd(err.error.errorMessage, true);
        this.getStateActive(this.newAds[i], i, 1);
      }
    );
  }

  getAd = async () => {
    this.newAds.map((ad, index) => this.getDescribeVORequest(ad, index));
  }

  stopPropagation($event){
    $event.stopImmediatePropagation();
  }

  async saveAd(i) {
    const newAds = await this.validateAds();
    const ad = await newAds[i];
    if (ad) {
      this.adsService.adSave({ ad: await ad }).subscribe(
        async (res) => {
          if (res[this.resultService] === 'OK') {
            const adSave = await res['ad'];
            this.showSnackbarAd('Ad Saved');
            this.newAds[i] = adSave;
            this.newAds[i].editing = false;
            this.newAds[i].display = true;
            this.newAds[i].displayCreatives = true;
            this.newAds[i].displayStats = false;
            this.getStateActive(this.newAds[i], i, 1);
            this.data.ads = this.newAds;
          }
        },
        (err) => {
          this.showSnackbarAd(err.error.errorMessage, true);
          console.log(err);
        }
      );
    }
  }

  cancel(i) {
    if (this.newAds[i]){
      if (this.newAds[i].new) this.delete(i);
      else{
        this.newAds[i].editing = false;
        this.getDescribeVORequest(this.newAds[i], i);
        this.showAd(i);
      }
     }
  }

  delete(i) {
    if (i !== null){
      this.newAds.splice(i, 1);
      this.dataAds.splice(i, 1);
      this.stateSelect.splice(i, 1);
      this.stateSelectArray.splice(i, 1);    }
  }

  validateRequiredAds = () => {
    this.inputs[this.results].map( async (input) =>  {
      if (input.inputSimpleId) {
        input.hasError = input.inputSimpleRequired && (input.inputSimpleData === '' || input.inputSimpleData === null);
      } else if (input.textareaSimpleId) {
        input.hasError = input.textareaSimpleRequired && (input.textareaSimpleData === '' || input.textareaSimpleData === null);
      }
    });
  };

}
