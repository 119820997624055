import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CampaignsService } from '../../campaigns.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { textFormat } from '../../../utils/textUtils';
import { openCard } from '../../../utils/uiUtils';
import {
  circleColorState,
  stateFormat,
  stateSelected,
  findState,
} from '../../../utils/stateUtils';
import { organizeSection } from '../../../utils/sectionUtils';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {

  constructor(
    public campaignsService: CampaignsService,
    public introspectionService: IntrospectionService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  resultService = 'result';
  campaign = 'campaign';
  section = 'campaign';
  hasType = false;
  dataCampaign;
  id;
  params = 'params';
  stateSelectArray = [];
  stateSelect = [];
  campaignEditedText = '';
  snackbarTime = 5000;
  initCampaign = false;
  sections = [];
  values = 'values';
  fields = 'fields';
  hasState = false;
  states = [];
  header = [];
  triggerSnackbar = false;
  showTestCampaign = false;
  isLoading = false;
  runId = null
  dataFields;

  ngOnInit() {
    if(localStorage.getItem("runId")) {
      this.runId = localStorage.getItem("runId")
    }
    if(localStorage.getItem("isNew")){
      localStorage.removeItem('isNew');
      this.showSnackbar('New Campaign Saved');
    }
    else if(localStorage.getItem("isClone")){
      localStorage.removeItem('isClone');
      this.showSnackbar('Campaign Cloned');
    }

    this.states = JSON.parse(localStorage.getItem('states'));
    this.route.paramMap.subscribe((params) => {
      this.id = params[this.params].id;
    });
    this.campaignSections();
  }

  async campaignSections() {
    const resSection = await this.introspectionService.getIntrospectionSection('CampaignSection');
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('CampaignVO');
    this.dataFields = resDescribe[this.fields];
    this.sections = organizeSection(
      resSection[this.values],
      resDescribe[this.fields]
    );
    this.hasState = findState(this.sections);
    this.getCampaign();
    resDescribe[this.fields].find((element) => {
      element.section === 'HEADER' &&
        this.header.push(element.name);
    });
  }

  getCampaign = async () => {
    const res = await this.campaignsService.getCampaign({ id: this.id });
    this.showTestCampaign = res[this.campaign].type.indexOf("API") > -1
    this.dataCampaign = res[this.campaign];
    this.hasType = true;
    this.functionSelectArray();
    !this.initCampaign && (this.initCampaign = true, openCard(this.dataCampaign.id));
  }

  getStateCampaign = async () => {
    const res = await this.campaignsService.getCampaign({ id: this.id });
    if ( res[this.campaign].state == "EDITING"){
      this.dataCampaign.ads = res[this.campaign].ads;
    }
    this.dataCampaign.state = res[this.campaign].state;
    this.dataCampaign.allowedTransitionStates = res[this.campaign].allowedTransitionStates;
    this.functionSelectArray();
  }

  functionSelectArray() {
    this.stateSelectArray = [];
    this.stateSelect = [];
    let newName;
    for (const allowedTransitionStates of this.dataCampaign
      .allowedTransitionStates) {
      newName = textFormat(allowedTransitionStates);
      this.stateSelectArray.push(
        stateFormat(
          newName,
          circleColorState(newName, this.states),
          this.dataCampaign.id
        )
      );
    }
    newName = textFormat(this.dataCampaign.state);
    this.stateSelectArray.push(
      stateFormat(
        newName,
        circleColorState(newName, this.states),
        this.dataCampaign.id
      )
    );
    this.stateSelect.push(
      stateFormat(
        newName,
        circleColorState(newName, this.states),
        this.dataCampaign.id
      )
    );
    this.stateSelectArray.splice(this.stateSelectArray.length - 1);
  }

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect[0],
      this.campaignsService.campaignStateSave({ id, type: 'CAMPAIGN', state: $event.name.toUpperCase(), }),
      this.resultService,
      this.campaignsService.campaign({ id }),
      // this.returnWithoutGet,
      this.getStateCampaign,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  returnWithoutGet () {}

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.campaignEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  hideEditing(active) {
    active ? (this.stateSelect[0].disabled = true) : (this.stateSelect[0].disabled = false);
  }

  resetSnackbar() {
    this.triggerSnackbar = false;
  }

  saveCampaign({ data, component }) {
    if (data.state === 'EDITING') {
      this.campaignsService.campaignSave({ campaign: data }).subscribe(
        () => {
          this.showSnackbar('Campaign Saved');
          this.getCampaign();
          component.editing = false;
          this.hideEditing(false);
        },
        (err) => {
          this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
          console.log(err);
        }
      );
    } else {
      this.saveStkActionData(data.sleepyFlowActions);
    }
  }

  saveStkActionData(sleepyFlowActions) {
    const actions = [];
    sleepyFlowActions.map(action => {
      if (action.text) {
        action.text.map(text => {
          (text.state === 'EDITING' || text.state === null) && actions.push(text);
        });
      }
      if (action.inputs) {
        action.inputs.map(input => {
          actions.push(input);
        });
      }
      if (action.title) {
        action.title.map(title => {
          (title.state === 'EDITING' || title.state === null) && actions.push(title);
        });
      }
      if (action.items) {
        action.items.map(item => {
          actions.push(item);
        });
      }
    });

    this.campaignsService.stkActionDatasSave({stkActionDatas: actions}).subscribe(
      (res) => {
        this.showSnackbar('Stk Action Datas Saved');
        this.getCampaign();
        this.hideEditing(false);
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error);
        console.log(err);
      }
    );
  }

  callMenu = (id) => {
    const menu = document.getElementById(`menu-${id}`).style;
    if (menu.visibility === 'hidden') {
      menu.visibility = 'visible';
      menu.opacity = '1';
    } else {
      menu.visibility = 'hidden';
      menu.opacity = '0';
    }
  }

  clone = (id) => {
    this.callMenu(id);
    this.campaignsService.campaignClone({id}).subscribe(
      (res) => {
        localStorage.setItem('isClone', 'true');
        this.router.navigate(['/' + this.section, res[this.campaign].id]);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      (err) => console.log(err)
    );
  }

  saveTest(value) {
    const body = {id: String(this.id), msisdn: value, requestId: 0}
    if(value) {
      this.isLoading = true
      this.campaignsService.campaignTest(body).subscribe(
        (res) => {
          this.showSnackbar(res["runId"]);
          localStorage.setItem("runId", value)
          this.hideEditing(false);
          this.isLoading = false
        },
        (err) => {
          if(err.error["runId"]){
            this.showSnackbarErr(err.error["runId"])
          } else {
            this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
          };
          this.hideEditing(false);
          this.isLoading = false
        }
      );
    }
  }

  processActionMenu(action, id) {
    console.log({action, id});
    if (action === 'recalcAvailableUsers') this.recalcAvailableUsers(id);
    else if (action === 'refresh') this.refresh(id);
  } 

  recalcAvailableUsers(id) {
    this.campaignsService.recalcAvailableUsers({id}).subscribe(
      async (res) => {
        this.showSnackbar('Update Available Users In Process');
        const campaignUpdate = await this.campaignsService.getCampaign({id});
        this.updateView(await campaignUpdate[this.section]);
      },
      (err) => {
        console.log(err);
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
      }
    );
  }

  async refresh(id) {
    const campaignUpdate = await this.campaignsService.getCampaign({id});
    this.updateView(await campaignUpdate[this.section]);
  }

  async updateView(campaignUpdate) {
    this.dataCampaign.description = campaignUpdate.description;
    this.dataCampaign.recalcAvailableUsers = campaignUpdate.recalcAvailableUsers;
    this.dataCampaign.refresh = campaignUpdate.refresh;
  }

}
