import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { mapKeys } from 'lodash';

import { IntrospectionService } from '../../../../services/introspection/introspection.service';
import { CampaignsService } from '../../../../campaigns/campaigns.service';

import { validateForm, detectChangesForm } from '../../../../utils/formUtils';
import { inputValidation, showError } from '../../../../utils/dataUtils';


@Component({
  selector: 'app-menu-ad',
  templateUrl: './menu-ad.component.html',
  styleUrls: ['../advertising-ads.component.css']
})
export class MenuAdComponent implements OnInit, OnChanges {
  @Input() adId;
  @Input() items;
  @Input() index;
  @Input() subClassName;
  @Input() setItems;
  @Input() restore;
  @Input() required = false;
  @Output() getItems = new EventEmitter();
  @Output() showSnackbar = new EventEmitter();
  @Output() showButton = new EventEmitter();

  showText = false;
  fieldsText = 'fields';
  values = 'values';
  fields;
  newItems = [];
  newData = {};
  result = 'result';
  results = '_results';
  isRequiredForThisData = false;

  @ViewChildren('Input') inputs: QueryList<[]>;

  constructor(private introspectionService: IntrospectionService, private campaignsService: CampaignsService) { }

  ngOnInit() {
    this.getDescribeVORequest(this.subClassName);
    if (this.items) this.newItems = this.items.slice(0);
    setTimeout(() => {
      this.newItems.length < 1 && this.required && this.addItem(0);
    }, 1000);
    this.initApp();
  }

  initApp() {
    setTimeout(() => {
      detectChangesForm(
        this.inputs[this.results],
        () => this.changeFormItem(),
        () => {},
        null
      );
    }, 500);
  }

  ngOnChanges() {
    this.setItems && this.getItems.emit({data: this.validateItems()});
    this.restore && (this.newItems = this.items.slice(0));
  }

  changeFormItem = () => {
    this.validateRequiredItems();
    this.showButton.emit(this.index);
  }

  async validateItems() {
    this.newItems.map(
      (item, index) => mapKeys(
        item, (value, key) => {
          const id = item.id ? item.id : index;
          this.newData[key + '-' + id] = value;
        }
      )
    );
    const hasEmpty = await validateForm(this.inputs, this.newData);
    validateForm(this.inputs, this.newData);
    this.newItems.map(
      (item, index) => mapKeys(
        item, (value, key) => {
          const id = item.id ? item.id : index;
          item[key] = this.newData[key + '-' + id];
        }
      )
    );
    if (this.required && this.newItems.length < 1) {
      this.isRequiredForThisData = true;
      return false;
    }
    return !hasEmpty ? [this.newItems] : false;
  }

  async getDescribeVORequest(subClassName) {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(subClassName);
    this.fields = resDescribe[this.fieldsText];
    this.showText = true;
  }

  isRequired = (input) => input ? true : false;

  inputValidation = (id) => inputValidation(id);

  showError = (data) => showError(data);

  isObject = (data) => typeof data === 'object' && true;

  addItem(i) {
    this.newItems.splice(i, 0, {adId: this.index});
    this.initApp();
    this.isRequiredForThisData = false;
  }
 
  delete(i) {
    this.newItems.splice(i, 1);
    if (this.required && this.newItems.length < 1) {
      this.isRequiredForThisData = true;
    }
    this.showButton.emit(this.index);
  }

  validateRequiredItems = () => {
    this.inputs[this.results].map( async (input) =>  {
      if (input.inputSimpleId) {
        input.hasError = input.inputSimpleRequired && (input.inputSimpleData === '' || input.inputSimpleData === null);
      } else if (input.textareaSimpleId) {
        input.hasError = input.textareaSimpleRequired && (input.textareaSimpleData === '' || input.textareaSimpleData === null);
      }
    });
  };

}
