import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnChanges } from '@angular/core';
import { mapKeys, find } from 'lodash';

import { validateForm } from '../../../utils/formUtils';
import { getName } from '../../../utils/sectionUtils';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { base64ToHex, isBase64, inputValidation } from '../../../utils/dataUtils';

@Component({
  selector: 'app-ram-command',
  templateUrl: './ram-command.component.html',
  styleUrls: ['./ram-command.component.css']
})
export class RamCommandComponent implements OnInit, OnChanges {

  @Input() data;
  @Input() state;
  @Input() voName = '';
  @Input() setRams;
  @Input() restore;
  @Output() getRams = new EventEmitter();
  @Output() setEditing = new EventEmitter();
  @Output() getOptions = new EventEmitter();

  ramCommandArray = [];
  showRamCommand = false;
  DescribeVO;
  fields = 'fields';
  values = 'values';
  newData = {};
  ramCommands = [];
  ramCommand = '';
  currentRam = '';
  results = '_results';
  selectSimProfile = [];
  getName = getName;
  
  @ViewChildren('Input') inputs: QueryList<[]>;

  constructor(private introspectionService: IntrospectionService) { }

  ngOnInit() {
    this.initApp();
  }

  initApp() {
    this.getDescribeVORequest();
    this.ramCommand = this.voName === 'DELETE' ? 'ramDeleteParameters' : 'ramInstallParameters';
    this.ramCommandArray = this.data[this.ramCommand];
    this.ramCommands = this.ramCommandArray && this.ramCommandArray.length > 0 ? this.ramCommandArray.slice(0) : [];
    this.ramCommands.map((ram) => ram.deleted = false);
    this.currentRam = this.voName;
  }


  async ngOnChanges() {
    this.voName !== this.currentRam && this.initApp();
    this.setRams && this.getRams.emit({rams: await this.validateRams(), ramCommand: this.ramCommand});
    this.restore && (this.ramCommands = this.ramCommandArray.slice(0));
  }

  getOptionsChild(field) {
    this.getOptions.emit(field);
    setTimeout(() => {
      this.selectSimProfile = field.options;
    }, 1000);
  }

  async validateRams() {
    this.ramCommands.map(
      (item, index) => mapKeys(
        item, (value, key) => this.newData[key + '-' + index] = value
      )
    );
    this.ramCommands = this.ramCommands.filter((ram) => !ram.deleted);
    await validateForm(this.inputs, this.newData);
    this.ramCommands.map(
      (item, index) => (mapKeys(
        item, (value, key) => item[key] = this.newData[key + '-' + index]
      ), delete item.deleted)
    );
    const hasEmpty = await validateForm(this.inputs, this.newData);

    if (!hasEmpty) {
      this.ramCommands.forEach(item => {
        const findSimProfile = this.selectSimProfile.find(simProfile => simProfile.name === item.simProfileFk);
        item.simProfileFk = findSimProfile.id;
      });
    }
    this.ramCommands.map(
      (item, index) => (mapKeys(
        item, (value, key) => value === null && delete item[key]
      ))
    );

    return !hasEmpty ? this.ramCommands : false;
  }

  async getDescribeVORequest() {
    const vo = this.voName === 'DELETE' ? 'RamDeleteParametersVO' : 'RamInstallParametersVO';
    this.DescribeVO = await this.introspectionService.getIntrospectionDescribe(vo);
    this.DescribeVO = this.DescribeVO[this.fields];
    this.DescribeVO.forEach(element => {
      if (element.mode === 'READ_WRITE') {
        this.ramCommands.map(ram => {
          if (!ram[element.name] && ram[element.name] !== 0 && ram[element.name] !== false) {
            ram[element.name] = null;
          }
        });
      }
    });
    this.showRamCommand = true;
  }

  isRequired(input) {
    return input ? true : false;
  }

  inputValidation(id) {
    return inputValidation(id);
  }

  removeSmsc(index) {
    this.setEditing.emit();
    this.ramCommands[index].deleted = !this.ramCommands[index].deleted;
  }

  addSmsc() {
    this.setEditing.emit();
    const newRam = {};
    this.DescribeVO.map((item) => newRam[item.name] = '');
    this.ramCommands.push(newRam);
  }

  showError(data) {
    let info = 'Invalid Data';
    data.minSize && (info = info + ', min size: ' + data.minSize);
    data.maxSize && (info = info + ' and max size: ' + data.maxSize);
    data.minValue && (info = info + ', min value: ' + data.minValue);
    data.maxValue && (info = info + ' and max value: ' + data.maxValue);
    return info;
  }

  async introspection(widgetType, array) {
    const res = await this.introspectionService.getIntrospectionSection(widgetType.className);
    !widgetType.required && res[this.values].unshift({ name: '', value: 'null' });
    array[widgetType.className] = res[this.values];
  }

  checkboxFalse(field) {
    this.data[field.name] === undefined && this.setCheckbox();
    return true;
  }

  setCheckbox = () => {
    let currentNumber = null;
    this.inputs[this.results].forEach(input => {

      if (input.selectOptionId && input.selectOptionId.indexOf('simProfileFk-') > -1) {
        currentNumber = input.selectOptionId.replace('simProfileFk-', '');
      }
      if (input.nativeElement) {
        const replace = input.nativeElement.id.replace(`-checkbox${currentNumber}`, '');
        const radioButton = (document.querySelector(`input[type="radio"][name="${replace}${currentNumber}"]:checked`) as HTMLInputElement)?.value;
        const valueRadioButton = radioButton && radioButton.replace(replace, '');
        mapKeys(this.ramCommands[currentNumber], (value, key) => {
          if (key === replace) {
            this.ramCommands[currentNumber][key] = valueRadioButton;
          }
        });
      }
    });
  }

  // setCheckbox = (data, value) => {
  //   this.DescribeVO.map((field) => field.label === data && (field.data = value));
  //   console.log(this.ramCommands)
  // }

  validateHex = (data) => (isBase64(data) ? data : base64ToHex(data));

}
