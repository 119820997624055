import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../global.component';
import { backTreeNavigation } from '../utils/navigationUtils';

@Injectable({
  providedIn: 'root',
})
export class EndpointsService {

  constructor(private http: HttpClient, private router: Router) {}

  endpointsList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/endpoint/ListEndpointsRequest', body, httpOptions);
  }

  endpointStateSave(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  endpoint(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/endpoint/GetEndpointRequest', body, httpOptions);
  }

  endpointSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/endpoint/SaveEndpointRequest', body, httpOptions);
  }

  clone(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/CloneEntityRequest', body, httpOptions);
  }

  getEndpointsList = async (body) => this.endpointsList(body).toPromise();

  getEndpoint = async (body) => this.endpoint(body).toPromise().then(res => res).catch(() => backTreeNavigation(this.router));
}
