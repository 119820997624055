import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { VaServicesService } from '../../vaservices.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { textFormat } from '../../../utils/textUtils';
import { openCard } from '../../../utils/uiUtils';
import { circleColorState, stateFormat, stateSelected, findState } from '../../../utils/stateUtils';
import { organizeSection } from '../../../utils/sectionUtils';

@Component({
  selector: 'app-vaservice',
  templateUrl: './vaservice.component.html',
  styleUrls: ['./vaservice.component.css'],
})
export class VaServiceComponent implements OnInit {

  constructor(
    public dataService: VaServicesService,
    public introspectionService: IntrospectionService,
    private route: ActivatedRoute
  ) {}

  dataSection = 'VaServiceSection';
  dataVO = 'VaServiceVO';
  resultService = 'result';
  dataId = 'vaService';
  type = 'va_service';
  hasData = false;
  data;
  id;
  params = 'params';
  stateSelectArray = [];
  stateSelect = [];
  editedText = '';
  init = false;
  sections = [];
  values = 'values';
  fields = 'fields';
  hasState = false;
  states = [];
  header = [];
  triggerSnackbar = false;
  snackbarTime = 5000;

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.route.paramMap.subscribe((params) => {
      this.id = params[this.params].id;
    });
    this.dataSections();
  }

  async dataSections() {
    const resSection = await this.introspectionService.getIntrospectionSection(this.dataSection);
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    this.sections = organizeSection(
      resSection[this.values],
      resDescribe[this.fields]
    );
    this.hasState = findState(this.sections);
    this.getData();
    resDescribe[this.fields].find((element) => {
      element.section === 'HEADER' && this.header.push(element.name);
    });
  }

  getData = async () => {
    const res = await this.dataService.getData({ id: this.id });
    this.data = res[this.dataId];
    this.hasData = true;
    this.functionSelectArray();
    !this.init && (this.init = true, openCard(this.data.id));
  }

  functionSelectArray() {
    this.stateSelectArray = [];
    this.stateSelect = [];
    let newName;
    for (const allowedTransitionStates of this.data.allowedTransitionStates) {
      newName = textFormat(allowedTransitionStates);
      this.stateSelectArray.push(
        stateFormat(newName, circleColorState(newName, this.states), this.data.id )
      );
    }
    newName = textFormat(this.data.state);
    this.stateSelectArray.push(
      stateFormat(newName, circleColorState(newName, this.states), this.data.id)
    );
    this.stateSelect.push(
      stateFormat(newName, circleColorState(newName, this.states), this.data.id)
    );
    this.stateSelectArray.splice(this.stateSelectArray.length - 1);
  }

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect[0],
      this.dataService.stateSave({id, state: $event.name.toUpperCase()}),
      this.resultService,
      this.dataService.data({ id }),
      this.getData,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.editedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  hideEditing(active) {
    active ? this.stateSelect[0].disabled = true : this.stateSelect[0].disabled = false;
  }

  resetSnackbar() {
    this.triggerSnackbar = false;
  }

  saveData({data, component}) {
    let body = {};
    body[this.dataId] = data;
    this.dataService.dataSave(body).subscribe(
      () => {
        this.showSnackbar('Data Saved');
        this.getData();
        component.editing = false;
        this.hideEditing(false);
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  }
}
