import { find } from 'lodash';

export const showSection = (name, close = false, editing = false) => {
  const basicInformation = document.getElementById(name);
  const up = document.getElementById(name + '-icon-up');
  const down = document.getElementById(name + '-icon-down');
  if (basicInformation) {
    if (basicInformation.style.height !== '0px' || close) {
      basicInformation.style.height = '0px';
      basicInformation.style.minHeight = '0px';
      up && (up.style.display = 'none');
      down && (down.style.display = 'block');
    } else {
      basicInformation.classList.remove('section');
      basicInformation.style.height = document.getElementById(name + '-content').clientHeight + 8 + 'px';
      setTimeout(() => {
        basicInformation.style.height = 'auto';
      }, 100);
      up && (up.style.display = 'block');
      down && (down.style.display = 'none');
    }
  }
};

export const organizeSection = (sections, describe) => {
  for (const section of sections) {
    section.field = [];
    for (const desc of describe) {
      desc.section === section.value && section.field.push(desc);
    }
  }
  return sections;
};

export const validateClose = (newSections, data) => {
  setTimeout(() => {
    newSections.map((section) => showSection(section.value, true));
    if ( data.state === 'EDITING' ) newSections.map((section) => showSection(section.value, false, true))
    else{
      if (document.getElementById('section0')) document.getElementById('section0').click();
      else validateClose(newSections, data);
    }
  }, 500);
};

export const isEditing = (sections, newSections) => {
  sections.map((section) => {
    const otherSections =
      section.value !== 'HEADER' &&
      section.value !== 'SERVICE_LOGS' &&
      section.value !== 'ENTITY_HISTORY'; // delete this line
    otherSections && newSections.push(section);
  });
};

export const validateSections = (sections, labels) => {
  const data = [];
  const section = sections.filter((sec) => sec.value !== 'HEADER');
  section.map((fields) =>
    fields.field.map((field) =>
      data.push({
        name: field.name,
        label: field.name,
        data: labels[field.name],
        widgetType: field.widgetType,
        className: field.className,
        required: field.required
      })
    )
  );
  return data;
};

export const validateLabels = (sections, sectionFields) => {
  sections.forEach((section) =>
    section.field.forEach((field) => {
      section.show = false;
      if (field.displayWhen) {
        field.displayWhen.forEach((displayWhen) => {
          field.show = findingfield(displayWhen.conditions, sectionFields);//, (field.name == 'listType'));
        });

        section.field.forEach((fieldWithShow) => {
          field.displayWhen.forEach((displayWhen) => {
            displayWhen.conditions.forEach((condition) => {
              if (condition.field === fieldWithShow.name) {
                !fieldWithShow.show && (field.show = false);
              }
            });
          });
        });

      } else {
        field.show = true;
      }
    })
  );
  sections.forEach((section) => {
    section.show = true;
  });
  return sections;
};

export const findingfield = (conditions, sectionFields, log = false) => {
  if (log) console.log('findingfield: ');
  let valueReturn = true;
  conditions.every((condition) => {
    if (log) console.log(condition);
    valueReturn = sectionFields.some(
      (field) => field.label === condition.field && condition.values.find((value) => String(value) === String(field.data))
    );
    if (log) console.log('valueReturn: ' + valueReturn);
    return valueReturn;
  });
  if (log) console.log('findingfield valueReturn: ' + valueReturn);
  return valueReturn;
};

export const getLong = (data, options, item) => {
    const long = find(options, (log) => log.id === data);
    return long && long[item];
};

export const getValue = (data, field) => {
  if (field.className === 'Long') {
    return data === undefined ? '' : getLong(data, field.options, 'id');
  }
  return data === undefined ? '' : data;
}

export const getName = (data, field) => {
  if (field.className === 'Long') {
    return data === undefined ? '' : getLong(data, field.options, 'name');
  }
  return data === undefined ? '' : data;
}
