import { includes } from 'lodash';
import * as moment from 'moment';
import * as momentz from 'moment-timezone';
import { circleColorState } from '../utils/stateUtils';
import { textFormat } from '../utils/textUtils';

export const hexToBase64 = (str) => {
  // if (!window.atob) {
  //   let tableStr =
  //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  //   let table = tableStr.split('');

  //   window.atob = function (base64) {
  //     const bin = []
  //     if (/(=[^=]+|={3,})$/.test(base64))
  //       throw new Error('String contains an invalid character');
  //     base64 = base64.replace(/=/g, '');
  //     let n = base64.length & 3;
  //     if (n === 1) throw new Error('String contains an invalid character');
  //     for (let i = 0, j = 0, len = base64.length / 4; i < len; ++i) {
  //       let a = tableStr.indexOf(base64[j++] || 'A'),
  //         b = tableStr.indexOf(base64[j++] || 'A');
  //       let c = tableStr.indexOf(base64[j++] || 'A'),
  //         d = tableStr.indexOf(base64[j++] || 'A');
  //       if ((a | b | c | d) < 0)
  //         throw new Error('String contains an invalid character');
  //       bin[bin.length] = ((a << 2) | (b >> 4)) & 255;
  //       bin[bin.length] = ((b << 4) | (c >> 2)) & 255;
  //       bin[bin.length] = ((c << 6) | d) & 255;
  //     }
  //     return String.fromCharCode.apply(null, bin).substr(0, bin.length + n - 4);
  //   };

  //   window.btoa = function (bin) {
  //     const base64 = []
  //     for (let i = 0, j = 0, len = bin.length / 3; i < len; ++i) {
  //       let a = bin.charCodeAt(j++),
  //         b = bin.charCodeAt(j++),
  //         c = bin.charCodeAt(j++);
  //       if ((a | b | c) > 255)
  //         throw new Error('String contains an invalid character');
  //       base64[base64.length] =
  //         table[a >> 2] +
  //         table[((a << 4) & 63) | (b >> 4)] +
  //         (isNaN(b) ? '=' : table[((b << 2) & 63) | (c >> 6)]) +
  //         (isNaN(b + c) ? '=' : table[c & 63]);
  //     }
  //     return base64.join('');
  //   };
  // }
  return btoa(
    String.fromCharCode.apply(
      null,
      str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ')
    )
  );
};

export const base64ToHex = (str) => {
  const hex = [];
  for (let i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')); i < bin.length; ++i) {
    let tmp = bin.charCodeAt(i).toString(16);
    tmp.length === 1 && (tmp = '0' + tmp);
    hex[hex.length] = tmp;
  }
  return hex.join('');
};

export const isHex = (str) => /([0-9A-F]{1}){1,2}$/i.test(str);
  // return /([0-9A-F]{3}){1,2}$/i.test(str);

export const isBase64 = (str) => /^@[a-zA-Z0-9+/]+={,2}$/i.test(str);

export const inputValidation = (id) => {
  if (id.className === 'String') {
    if (id.minSize) {
      // return new RegExp(`^[A-Za-z0-9.%^&()$#@!():\ -\>\<\/]{${id.minSize},${id.maxSize}}$`); 
      return new RegExp(`^[^\r\n]{${id.minSize},${id.maxSize}}$`);
    }
    return new RegExp(`[^\r\n]$`);
  }
  if (id.className === 'byte[]' ) {
    if (id.minSize) {
      return new RegExp(`^[0-9A-Fa-f]{${id.minSize * 2},${id.maxSize * 2}}$`);
    }
    return /^[0-9A-Fa-f]+$/;
  }

  if (includes(['Long', 'Integer', 'Double', 'Float', 'Short'], id.className)) {
    if (id.minValue && id.maxValue) {
      let regex = `^(`;
      if (id.maxValue.length > id.minValue.length) {
        for (let i = 0; id.minValue.length > i; i++) {
          if (i === 0) {
            id.minValue[i] !== 9 ? regex += '[' + id.minValue[i] + '-9]' : regex += '[9]';
          } else {
            regex += String(id.minValue)[i] === '0' ? '[0-9]' : '[' + String(id.minValue)[i] + '-9]';
          }
        }
        for (let i = id.minValue.length; id.maxValue.length - 1 > i; i++) {
          regex += '|';
          regex += '[1-9]';
          for (let k = 0; i > k; k++) {
            regex += '[0-9]';
          }
        }
        let maxim;
        if (id.maxValue.length > id.minValue.length && id.maxValue[1] !== 0) {
          maxim = id.maxValue;
          maxim = String(maxim);
          regex += '|';
          let isOne = false;
          for (let i = 0; maxim.length > i; i++) {
            i === 0 && maxim[i] === '1' && (regex += '1', isOne = true);
            i === 0 && maxim[i] !== '1' && (regex += '[1-' +  Number(maxim[i] - 1) + ']');
            i !== 0 && maxim[i] !== '1' && maxim[i] !== '0' && isOne && (regex += '[0-' +  Number(maxim[i] - 1) + ']', isOne = false);
            i !== 0 && maxim[i] === '1' && isOne && (regex += '[0]', isOne = false);
            i !== 0 && !isOne && (regex += '[0-9]');
          }
        }
        regex += '|';
        maxim = id.maxValue - 1;
        maxim = String(maxim);
        for (let i = 0; maxim.length > i; i++) {
          i === 0 && maxim[i] === '1' && (regex += '1');
          i === 0 && maxim[i] !== '0' && maxim[i] !== '1' && (regex += '[1-' + maxim[i] + ']');
          // i === 0 && maxim[i] !== '1' && (regex += '[' + maxim[i] + '-9]');
          i !== 0 && maxim[i] !== '0' && (regex += '[0-' + maxim[i] + ']');
          i !== 0 && maxim[i] === '0' && (regex += '0');
        }
        regex += `|` + id.maxValue + `)$`;
      } else {
        regex = id.minValue;
        for (let i = Number(id.minValue) + 1; i <= Number(id.maxValue); i++) {
          regex = regex + '|' + i;
        }
      }
      return new RegExp(regex);
    }
  }
};

export const convertToLocalTime = (date) => {
  let newDate = new Date(date);
  return new Date(newDate.getTime() + (newDate.getTimezoneOffset() * 60000));
}

export const formatDate = (date, format, sumGMT = 0) => {
  let newDate = date;
  const timezone = JSON.parse(localStorage.getItem('timezone'));
  const hoursGMT = (momentz.tz(timezone).utcOffset() * -1);
  let newHoursGMT = hoursGMT * sumGMT;
  if (hoursGMT === 0 || hoursGMT === -60 || hoursGMT === 60) {
    newHoursGMT -= 60;
  }
  newDate = momentz(newDate).tz(timezone).add(newHoursGMT, 'minutes').format(format);
  return newDate;
};

export const addGMT = (date) => {
  const timezone = JSON.parse(localStorage.getItem('timezone'));
  const hoursGMT = momentz.tz(timezone).utcOffset() / 60;
  let gmt;
  if (hoursGMT < 9 && hoursGMT > -10) {
    if (hoursGMT > 0) {
      gmt = addCharacterToString(String(hoursGMT), '+0', String(hoursGMT).length);
    } else {
      gmt = addCharacterToString(String(hoursGMT), '0', String(hoursGMT).length);
    }
  } else {
    gmt = hoursGMT;
  }
  return `${date} GMT${gmt}`;
};

export const addCharacterToString = (hoursGMT, character, position) => {
  return [hoursGMT.slice(0, position - 1), character, hoursGMT.slice(position - 1)].join('');
};

export const validateDate = (date) => date.split('-')[2] + '-' + date.split('-')[1] + '-' + date.split('-')[0] + 'T00:00:00Z';

export const validateDateTime = (date) => {
  const year = date.split('-')[2];
  return `${year.substr(0, 4)}-${date.split('-')[1]}-${date.split('-')[0]}T${date.split(' ')[1]}`;
};

export const showError = (data) => {
  let min = data.minSize;
  let max = data.maxSize;
  if (data.className === 'byte[]' ) {
    min = data.minSize * 2;
    max = data.maxSize * 2;
  }
  let info = 'Invalid Data';
  min && (info = info + ', min size: ' + min);
  max && (info = info + ' and max size: ' + max);
  data.minValue && (info = info + ', min value: ' + data.minValue);
  data.maxValue && (info = info + ' and max value: ' + data.maxValue);
  return info;
};

export const getEvents = async (events, date, getIntrospection, getStateActive) => { 
  const currentEvents = [];
  const campaignSchedules = 'campaignSchedules';
  const describeSchedule = await getIntrospection();
  events[campaignSchedules].map(async (event) => {
    if (event.deletedTs) return;
    const color = circleColorState(textFormat(event.state), JSON.parse(localStorage.getItem('states')));
    const stateActive = await getStateActive(event);
    const extendedProps = {
      campaignSchedule : event,
      describeSchedule : await describeSchedule['fields'],
      stateSelect : await stateActive.stateSelectPrincipal[0],
      stateSelectArray : await stateActive.stateSelectArray[0],
      campaignId : event.campaignId
    }

    const title = event.campaignName; 
    
    const fromTs = getInit(date.fromTs, event.fromTs);
    const toTs = getEnd(date.toTs, event.toTs);

    const days = getDays(fromTs, toTs, event);

    days.map((day, index) => {

      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? '0' + i : i;
        const hoursum = (i + 1) < 10 ? '0' + (i + 1) : (i + 1);

        if (event['h' + i]) {

          let isEqual = false
          if(currentEvents.length > 0) {
            isEqual = (currentEvents[currentEvents.length - 1].id === event.id )
                    && (currentEvents[currentEvents.length - 1].end === moment.utc(day).add(hour , 'h').format('YYYY-MM-DDTHH:mm:ss'));
          }
          if(isEqual) {
            const remplaceEvent = {
              id: event.id,
              title,
              start: currentEvents[currentEvents.length - 1].start,
              end: moment.utc(day).add(hoursum , 'h').format('YYYY-MM-DDTHH:mm:ss'),
              classNames: 'schudule-events',
              allDay: false,
              color,
              extendedProps
            };
            currentEvents.splice(currentEvents.length - 1, 1, remplaceEvent);
          } else {
            currentEvents.push({
              id: event.id,
              title,
              start: moment.utc(day).add(hour , 'h').format('YYYY-MM-DDTHH:mm:ss'),
              end: moment.utc(day).add(hoursum , 'h').format('YYYY-MM-DDTHH:mm:ss'),
              classNames: 'schudule-events',
              allDay: false,
              color,
              extendedProps
            });
          }
        }
      }

    });
 
  });
  return currentEvents;
};

export const getDiff = (date1, date2) => {
  date1 = new Date(formatDate(date1, 'YYYY-MM-DDTHH:mm:ss', 2));
  date2 = new Date(formatDate(date2, 'YYYY-MM-DDTHH:mm:ss', 2));
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getDays = (fromTs, toTs, event) => {
  const week = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const start = new Date(formatDate(fromTs, 'YYYY-MM-DDTHH:mm:ss', 2));//moment(fromTs);
  const end = new Date(formatDate(toTs, 'YYYY-MM-DDTHH:mm:ss', 2));//moment(toTs);
  const arr = [];
  let tmp = start;
  
  while (tmp.getTime() <= end.getTime()) {
    event[week[tmp.getDay()]] && arr.push(formatDate(tmp, 'YYYY-MM-DDT00:00:00', 2));
    tmp = new Date( tmp.getTime() + (1000 * 60 * 60 * 24));
  }
  return arr;
};

export const getInit = (date1, date2) => {
  const d1 = new Date(formatDate(date1, 'YYYY-MM-DDT00:00:00', 2));
  const d2 = new Date(formatDate(date2, 'YYYY-MM-DDT00:00:00', 2));
  return (d1.getTime() > d2.getTime()) ? date1 : date2;
};

export const getEnd = (date1, date2) => {
  const d1 = new Date(formatDate(date1, 'YYYY-MM-DDT00:00:00', 2));
  const d2 = new Date(formatDate(date2, 'YYYY-MM-DDT00:00:00', 2));
  return (d1.getTime() < d2.getTime()) ? date1 : date2;
};
