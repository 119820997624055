import { Component, OnInit } from '@angular/core';

import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-smpp-accounts',
  templateUrl: './smpp-accounts.component.html',
  styleUrls: ['./smpp-accounts.component.css']
})
export class SmppAccountsComponent implements OnInit {
  section = false;
  sectionId = 'smppAccounts';
  image = 'smpp_account';
  title;
  description;
  dataVO = 'SmppAccountVO';
  loadingDataList = true;
  main = 'main';
  fields = 'fields';
  dataFields;

  constructor( private introspectionService: IntrospectionService ) { }

  ngOnInit() {
    this.getAppletsInfo();
  }

  async getAppletsInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    this.title = resDescribe[this.main].pluralLabel;
    this.description = resDescribe[this.main].description;
    this.section = true;
    this.dataFields = resDescribe[this.fields];
  }

  showView() {
    this.loadingDataList = false;
  }

}
