import { textFormat } from '../utils/textUtils';

export const stateFormat = (newName, color1, id) => {
  return {
    title: newName,
    name: newName,
    circleColor: color1,
    id: 'state' + id,
    disabled: false,
  };
};

export const circleColorState = (newName, states) => {
  const findColor = states.find(({ label }) => label === newName);
  return findColor.rgbColor ? '#' + findColor.rgbColor : '#79c942';
};

export const stateActive = (type, stateSelectPrincipal, states) => {
  let newName;
  const stateSelectArray = [];
  stateSelectPrincipal = [];

  for (let i = 0; i < type.length; i++) {
    if (type[i].allowedTransitionStates) {
      if (type[i].allowedTransitionStates.length === 0) {
        stateSelectArray.push([]);
      } else {
        for (const allowedTransitionStates of type[i].allowedTransitionStates) {
          newName = textFormat(allowedTransitionStates);
          stateSelectArray[i]
          ? stateSelectArray[i].push(stateFormat(newName, circleColorState(newName, states), type[i].id))
          : stateSelectArray.push([stateFormat(newName, circleColorState(newName, states), type[i].id )]);
        }
      }

      newName = textFormat(type[i].state);
      stateSelectPrincipal.push(stateFormat(newName, circleColorState(newName, states), '-statePrincipal-' + type[i].id));

      if (stateSelectArray[i]) {
        stateSelectArray[i].push(stateFormat(newName, circleColorState(newName, states), type[i].id));
      }
    }
  }

  stateSelectArray.forEach((items) => items.splice(items.length - 1));
  return {stateSelectArray, stateSelectPrincipal};
};

export const stateSelected = (
  stateSelect,
  serviceStateSave,
  result,
  serviceSection,
  getSection,
  showSnackbar,
  showSnackbarErr = showSnackbar
) => {
  stateSelect.disabled = true;
  serviceStateSave.subscribe(
    (res) => {
      if (res[result] === 'OK') {
        serviceSection.subscribe(
          () => {
            getSection();
            showSnackbar('State Changed');
          },
          (err) => {
            console.log(err);
            showSnackbarErr(err.error.errorMessage);
            stateSelect.hidden = true;
            setTimeout(() => {
              stateSelect.hidden = false;
            }, 10);
          }
        );
      }
      setTimeout(() => (stateSelect.disabled = false), 2000);
    },
    (err) => {
      console.log(err);
      showSnackbarErr(err.error.errorMessage);
      setTimeout(() => (stateSelect.disabled = false), 2000);
      stateSelect.hidden = true;
      setTimeout(() => {
        stateSelect.hidden = false;
      }, 10);
    }
  );
};

export const findState = (sections) => {
  const foundHeader = sections.find((element) => element.value === 'HEADER');
  return foundHeader.field.some((element) => element.name === 'state' );
};
