import { Component, OnInit } from '@angular/core';

import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent implements OnInit {
  section = false;
  sectionId = 'parameters';
  image = 'parameter';
  title;
  description;
  loadingDataList = true;
  main = 'main';
  dataFields;
  fields = 'fields';
  parameterTypes;
  values = 'values';

  constructor(private introspectionService: IntrospectionService) { }

  ngOnInit() {
    this.getParametersInfo();
  }

  async getParametersInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('ParameterVO');
    this.title = resDescribe[this.main].pluralLabel;
    this.description = resDescribe[this.main].description;
    this.dataFields = resDescribe[this.fields];
    this.getParametersEnum();
  }

  async getParametersEnum() {
    const res = await this.introspectionService.getIntrospectionSection('ParameterType');
    this.parameterTypes = res[this.values];
    this.section = true;
  }

  showForm() {
    this.loadingDataList = false;
  }
}
