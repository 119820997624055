import { Component, OnInit } from '@angular/core';

import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-vaservices',
  templateUrl: './vaservices.component.html',
  styleUrls: ['./vaservices.component.css'],
})
export class VaServicesComponent implements OnInit {
  dataVO = 'VaServiceVO';
  section = false;
  sectionId = 'vaServices';
  image = 'va_service';
  title;
  description;
  loadingDataList = true;
  main = 'main';
  dataFields;
  fields = 'fields';

  constructor( private introspectionService: IntrospectionService ) { }

  ngOnInit() {
    this.getDataInfo();
  }

  async getDataInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    this.title = resDescribe[this.main].pluralLabel;
    this.description = resDescribe[this.main].description;
    this.section = true;
    this.dataFields = resDescribe[this.fields];
  }

  showView() {
    this.loadingDataList = false;
  }
}
