import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getNode } from '../global.component';

@Injectable({
  providedIn: 'root'
})
export class SimsService {

  constructor(private http: HttpClient) {}

  sim(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/sim/GetSimRequest', body, httpOptions);
  }

  saveSim(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/sim/SaveSimRequest', body, httpOptions);
  }

  getSim = async (body) => this.sim(body).toPromise();
}
