import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../global.component';
import { backTreeNavigation } from '../utils/navigationUtils';

@Injectable({
  providedIn: 'root'
})
export class SmppAccountsService {

  constructor(private http: HttpClient, private router: Router) {}

  smppAccountsList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/smpp_account/ListSmppAccountsRequest', body, httpOptions);
  }

  smppAccountDelete(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/smpp_account/DeleteSmppAccountRequest', body, httpOptions);
  }

  smppAccountStateSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  smppAccount(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/smpp_account/GetSmppAccountRequest', body, httpOptions);
  }

  smppAccountSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/smpp_account/SaveSmppAccountRequest', body, httpOptions);
  }

  clone(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/CloneEntityRequest', body, httpOptions);
  }

  getSmppAccountsList = async (body) => this.smppAccountsList(body).toPromise();

  getSmppAccount = async (body) => this.smppAccount(body).toPromise().then(res => res).catch(() => backTreeNavigation(this.router));
}
