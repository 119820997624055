import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EndpointsService } from '../../endpoints.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { textFormat } from '../../../utils/textUtils';
import { openCard } from '../../../utils/uiUtils';
import { circleColorState, stateFormat, stateSelected, findState } from '../../../utils/stateUtils';
import { organizeSection } from '../../../utils/sectionUtils';

@Component({
  selector: 'app-endpoint',
  templateUrl: './endpoint.component.html',
  styleUrls: ['./endpoint.component.css'],
})
export class EndpointComponent implements OnInit {

  constructor(
    public endpointsService: EndpointsService,
    public introspectionService: IntrospectionService,
    private route: ActivatedRoute
  ) {}

  resultService = 'result';
  type = 'endpoint';
  hasType = false;
  dataEndpoint;
  id;
  params = 'params';
  stateSelectArray = [];
  stateSelect = [];
  endpointEditedText = '';
  initEndpoint = false;
  sections = [];
  values = 'values';
  fields = 'fields';
  hasState = false;
  states = [];
  header = [];
  triggerSnackbar = false;
  snackbarTime = 5000;

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.route.paramMap.subscribe((params) => {
      this.id = params[this.params].id;
    });
    this.endpointSections();
  }

  async endpointSections() {
    const resSection = await this.introspectionService.getIntrospectionSection('EndpointSection');
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('EndpointVO');
    this.sections = organizeSection(
      resSection[this.values],
      resDescribe[this.fields]
    );
    this.hasState = findState(this.sections);
    this.getEndpoint();
    resDescribe[this.fields].find((element) => {
      element.section === 'HEADER' && this.header.push(element.name);
    });
  }

  getEndpoint = async () => {
    const res = await this.endpointsService.getEndpoint({ id: this.id });
    this.dataEndpoint = res[this.type];
    this.hasType = true;
    this.functionSelectArray();
    !this.initEndpoint && (this.initEndpoint = true, openCard(this.dataEndpoint.id));
  }

  functionSelectArray() {
    this.stateSelectArray = [];
    this.stateSelect = [];
    let newName;
    for (const allowedTransitionStates of this.dataEndpoint.allowedTransitionStates) {
      newName = textFormat(allowedTransitionStates);
      this.stateSelectArray.push(
        stateFormat(newName, circleColorState(newName, this.states), this.dataEndpoint.id )
      );
    }
    newName = textFormat(this.dataEndpoint.state);
    this.stateSelectArray.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataEndpoint.id)
    );
    this.stateSelect.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataEndpoint.id)
    );
    this.stateSelectArray.splice(this.stateSelectArray.length - 1);
  }

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect[0],
      this.endpointsService.endpointStateSave({id, type: 'ENDPOINT', state: $event.name.toUpperCase(), }),
      this.resultService,
      this.endpointsService.endpoint({ id }),
      this.getEndpoint,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.endpointEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  hideEditing(active) {
    active ? this.stateSelect[0].disabled = true : this.stateSelect[0].disabled = false;
  }

  resetSnackbar() {
    this.triggerSnackbar = false;
  }

  saveEndpoint({data, component}) {
    this.endpointsService.endpointSave({ endpoint: data }).subscribe(
      () => {
        this.showSnackbar('Endpoint Saved');
        this.getEndpoint();
        component.editing = false;
        this.hideEditing(false);
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  }
}
