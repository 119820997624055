import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

import { AppletsService } from '../applets.service';

import { openCard } from '../../utils/uiUtils';
import { stateFormat, circleColorState, stateSelected, stateActive } from '../../utils/stateUtils';
import { backTreeNavigation } from '../../utils/navigationUtils';

@Component({
  selector: 'app-applets-list',
  templateUrl: './applets-list.component.html',
  styleUrls: ['../applets.component.css'],
})
export class AppletsListComponent implements OnInit {
  hasApplets = false;
  appletsId = 'applets';
  section = 'applet';
  result = 'result';
  appletsList;
  stateSelectArray = [];
  stateSelect = [];
  initApplet = false;
  appletsEditedText = '';
  states = [];
  triggerHeader = false;
  triggerSnackbar = false;
  snackbarTime = 5000;
  dataListFilterInstrospection = 'ListAppletsRequest';
  body = {};

  @Output() showList = new EventEmitter();
  @Input() dataFields;

  constructor(
    private appletsService: AppletsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    // this.getApplets();
  }

  getApplets = async (body = null) => {
    if (body) this.body = body;
    const res = await this.appletsService.getAppletsList(this.body).then(data => data).catch(() => backTreeNavigation(this.router, true));
    this.appletsList = res[this.appletsId];
    this.showList.emit();
    this.getStates();
    this.triggerHeader = true;
    this.hasApplets = true;
    !this.initApplet && this.openCard();
  }

  openCard() {
    this.initApplet = true;
    openCard(this.appletsId);
  }

  stateSelected({$event, id, i}) {
    this.stateSelectArray[i] = [stateFormat($event.name, circleColorState($event.name, this.states), id)];
    stateSelected(
      this.stateSelect[i],
      this.appletsService.appletStateSave({id, type: this.section.toUpperCase(), state: $event.name.toUpperCase()}),
      this.result,
      this.appletsService.applet({ id }),
      this.getApplets,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.appletsEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  getStates() {
    const getStateActive = stateActive(this.appletsList, this.stateSelect, this.states);
    this.stateSelectArray = getStateActive.stateSelectArray;
    this.stateSelect = getStateActive.stateSelectPrincipal;
}

  cardSelectClick = (id) => this.router.navigate(['/' + this.section, id]);

  resetHeader = () => this.triggerHeader = false;

  resetSnackbar = () => this.triggerSnackbar = false;

  changeFilter = (body) => this.getApplets(body);

  cloneProcess = (body) => {
    body['type'] = this.section.toUpperCase();
    this.appletsService.clone(body).subscribe(
      () => {
        this.showSnackbar(this.section.charAt(0).toUpperCase() + this.section.slice(1)   + ' Cloned');
        this.getApplets();
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  };

}
