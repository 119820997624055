import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../global.component';
import { backTreeNavigation } from '../utils/navigationUtils';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {

  constructor(private http: HttpClient, private router: Router) {}

  subscriptionsList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/subscription/FindSubscriptionsRequest', body, httpOptions);
  }

  unsubscribe(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/subscription/UnsubscribeRequest', body, httpOptions);
  }

  data(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/subscription/GetSubscriptionsRequest', body, httpOptions);
  }

  getDataList = async (body) => this.subscriptionsList(body).toPromise();

  getData = async (body) => this.data(body).toPromise().then(res => res).catch((error) => {
                      console.log(error);
                      //backTreeNavigation(this.router);
                    } );

}
