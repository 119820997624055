import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StatsService } from './stats.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { convertToLocalTime, formatDate, validateDate } from '../../../utils/dataUtils';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  results = '_results';
  value = '_value';
  path;
  stats = [];
  values = 'values';
  statView = 'statView';
  id;
  showStats = false;
  tablePrimaryTextAlign = [];
  tablePrimaryHead = [];
  tablePrimaryBody = [];
  tablePrimaryArrayComponents = [];
  kpis = 'kpis';

  @ViewChildren('datepicker') datepicker: QueryList<[]>;

  constructor(private route: ActivatedRoute, private statsService: StatsService, private introspectionService: IntrospectionService) { }

  ngOnInit() {
    let statIntrospection;
    if (this.route.url[this.value][0].path === 'endpoint'){
      this.path = 'ENDPOINT';
      statIntrospection = 'Endpoint';
    } 
    else if (this.route.url[this.value][0].path === 'identifier'){
      this.path = 'IDENTIFIER';
      statIntrospection = 'Identifier';
    }
    else if (this.route.url[this.value][0].path === 'pricepoint'){
      this.path = 'PRICEPOINT';
      statIntrospection = 'Pricepoint';
    }
    else if (this.route.url[this.value][0].path === 'va_service'){
      this.path = 'VA_SERVICE';
      statIntrospection = 'VaService';
    }
    else if (this.route.url[this.value][0].path === 'campaign'){
      this.path = 'CAMPAIGN';
      statIntrospection = 'Campaign';
    }
    else if (this.route.url[this.value][0].path === 'smpp_account'){
      this.path = 'SMPP_ACCOUNT';
      statIntrospection = 'SmppAccount';
    }
    else if (this.route.url[this.value][0].path === 'landing'){
      this.path = 'LANDING';
      statIntrospection = 'Landing';
    }

    this.id = this.route.url[this.value][1].path;
    statIntrospection === 'Campaign' ?  this.setStatsCampaign(this.id) : this.setStats(statIntrospection);
  }

  async setStats(statIntrospection) {
    const stats = await this.introspectionService.getIntrospectionSection(`${statIntrospection}Stat`);
    stats[this.values].forEach((stat) => {
      if (stat.showByDefault) {
        this.stats.push(stat.name);
        this.tablePrimaryTextAlign.push('center');
        this.tablePrimaryArrayComponents.push(['normal']);
      }
    });
  }

  async setStatsCampaign(id) {
    const kpis = await this.statsService.getKpiCampaign({id});
    kpis[this.kpis].forEach(({type, stat, label, entityFk}) => {
      this.stats.push({type, stat, label, entityFk});
      this.tablePrimaryTextAlign.push('center');
      this.tablePrimaryArrayComponents.push(['normal']);
    });
  }

  validateForm() {
    this.showStats = false;
    let date1 = this.datepicker[this.results][0].DatepickerInput1;
    let date2 = this.datepicker[this.results][1].DatepickerInput1;
    if (date1 && date2) {
      date1 = convertToLocalTime(validateDate(date1));
      date2 = convertToLocalTime(validateDate(date2));
      if (this.path === 'CAMPAIGN') {
        const body = {
          from: date1,
          to: date2,
          statResultType: 'LIST_AND_SUM',
          statGranularity: 'DAY',
          kpis: this.stats
        };
        this.getStats(body);
      } else {
        const body = {
          from: date1,
          to: date2,
          statResultType: 'LIST_AND_SUM',
          statGranularity: 'HOUR',
          entityFks: [this.id],
          statClass: this.path,
          statEnums: this.stats
        };
        this.getStats(body);
      }
    }
  }

  async getStats(body) {
    let stats;
    if (this.path === 'CAMPAIGN') {
      stats = await this.statsService.getStatsCampaign(body);
    } else {
      stats = await this.statsService.getStats(body);
    }
    this.tablePrimaryHead = stats[this.statView].statLabels;
    this.tablePrimaryBody = stats[this.statView].stats;
    this.tablePrimaryBody.forEach((date) => date[0] = formatDate(date[0], 'YYYY-MM-DD HH:mm:ss'));
    let sum = stats[this.statView].sum;
    sum.forEach((element, index) => {
      sum[index] = parseFloat(element);
    });
    sum[0] = 'Total';
    this.tablePrimaryBody.push(sum);
    if (stats) this.showStats = true;
  }

}
