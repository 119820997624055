import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { EndpointsComponent } from './endpoints/endpoints.component';
import { EndpointComponent } from './endpoints/endpoints-list/endpoint/endpoint.component';
import { IdentifiersComponent } from './identifiers/identifiers.component';
import { IdentifierComponent } from './identifiers/identifiers-list/identifier/identifier.component';
import { PricepointsComponent } from './pricepoints/pricepoints.component';
import { PricepointComponent } from './pricepoints/pricepoints-list/pricepoint/pricepoint.component';
import { VaServicesComponent } from './vaservices/vaservices.component';
import { VaServiceComponent } from './vaservices/vaservices-list/vaservice/vaservice.component';
import { AppletsComponent } from './applets/applets.component';
import { AppletComponent } from './applets/applets-list/applet/applet.component';
import { TpRulesComponent } from './tp-rules/tp-rules.component';
import { TpRuleComponent } from './tp-rules/tp-rules-list/tp-rule/tp-rule.component';
import { SimProfilesComponent } from './sim-profiles/sim-profiles.component';
import { SimProfileComponent } from './sim-profiles/sim-profiles-list/sim-profile/sim-profile.component';
import { ParametersComponent } from './parameters/parameters.component';
import { SmppAccountsComponent } from './smpp-accounts/smpp-accounts.component';
import { SmppAccountComponent } from './smpp-accounts/smpp-accounts-list/smpp-account/smpp-account.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignComponent } from './campaigns/campaigns-list/campaign/campaign.component';
import { AddComponent } from './common/add/add.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { FilesComponent } from './files/files.component';
import { FileComponent } from './files/files-list/file/file.component';
import { AddFileComponent } from './files/files-list/file/add-file/add-file.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { StatsViewComponent } from './stats-view/stats-view.component';
import { SimsComponent } from './sims/sims.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './app.authguard';
import { LandingsComponent } from './landings/landings.component';
import { LandingComponent } from './landings/landings-list/landing/landing.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SimStatsComponent } from './sim-stats/sim-stats.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'endpoint',
    component: EndpointsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'endpoint/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'endpoint/:id',
    component: EndpointComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'identifier',
    component: IdentifiersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'identifier/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'identifier/:id',
    component: IdentifierComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pricepoint',
    component: PricepointsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pricepoint/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pricepoint/:id',
    component: PricepointComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'va_service',
    component: VaServicesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'va_service/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'va_service/:id',
    component: VaServiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'landing',
    component: LandingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'landing/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'landing/:id',
    component: LandingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'applet',
    component: AppletsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'applet/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'applet/:id',
    component: AppletComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tp_rule',
    component: TpRulesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tp_rule/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tp_rule/:id',
    component: TpRuleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sim_profile',
    component: SimProfilesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sim_profile/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sim_profile/:id',
    component: SimProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'parameter',
    component: ParametersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'smpp_account',
    component: SmppAccountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'smpp_account/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'smpp_account/:id',
    component: SmppAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign',
    component: CampaignsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign/add',
    component: AddComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'campaign/:id',
    component: CampaignComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'schedule',
    component: ScheduleComponent,
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'file',
    component: FilesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'file/lists',
    redirectTo: 'files',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'file/applets',
    redirectTo: 'files',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'file/add',
    component: AddFileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'file/lists/:id',
    component: FileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'file/applets/:id',
    component: FileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'stats',
    component: StatsViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sim',
    component: SimsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'subscription_event',
    component: SubscriptionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sim_stats',
    component: SimStatsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
