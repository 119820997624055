import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

import { SimProfilesService } from '../sim-profiles.service';

import { openCard } from '../../utils/uiUtils';
import { stateFormat, circleColorState, stateSelected, stateActive } from '../../utils/stateUtils';
import { backTreeNavigation } from '../../utils/navigationUtils';

@Component({
  selector: 'app-sim-profiles-list',
  templateUrl: './sim-profiles-list.component.html',
  styleUrls: ['./sim-profiles-list.component.css']
})
export class SimProfilesListComponent implements OnInit {
  hasSimProfiles = false;
  simProfilesId = 'simProfiles';
  section = 'sim_profile';
  result = 'result';
  simProfilesList;
  stateSelectArray = [];
  stateSelect = [];
  initSimProfile = false;
  simProfilesEditedText = '';
  states = [];
  triggerHeader = false;
  triggerSnackbar = false;
  snackbarTime = 5000;
  dataListFilterInstrospection = 'ListSimProfilesRequest';
  body = {};

  @Output() showList = new EventEmitter();
  @Input() dataFields;

  constructor(
    private simProfilesService: SimProfilesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    // this.getSimProfiles();
  }

  getSimProfiles = async (body = null) => {
    if (body) this.body = body;
    const res = await this.simProfilesService.getSimProfilesList(this.body).then(data => data).catch(() => backTreeNavigation(this.router, true));
    this.showList.emit();
    this.simProfilesList = res[this.simProfilesId];
    this.getStates();
    this.hasSimProfiles = true;
    !this.initSimProfile && this.openCard();
    this.triggerHeader = true;
  }

  openCard() {
    this.initSimProfile = true;
    openCard(this.simProfilesId);
  }

  stateSelected({$event, id, i}) {
    this.stateSelectArray[i] = [stateFormat($event.name, circleColorState($event.name, this.states), id)];
    stateSelected(
      this.stateSelect[i],
      this.simProfilesService.simProfileStateSave({id, type: this.section.toUpperCase(), state: $event.name.toUpperCase()}),
      this.result,
      this.simProfilesService.simProfile({ id }),
      this.getSimProfiles,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.simProfilesEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  getStates() {
    const getStateActive = stateActive(this.simProfilesList, this.stateSelect, this.states);
    this.stateSelectArray = getStateActive.stateSelectArray;
    this.stateSelect = getStateActive.stateSelectPrincipal;
  }

  cardSelectClick = (id) => this.router.navigate(['/' + this.section, id]);

  resetHeader = () => this.triggerHeader = false;

  resetSnackbar = () => this.triggerSnackbar = false;

  changeFilter = (body) => this.getSimProfiles(body);

  cloneProcess = (body) => {
    body['type'] = this.section.toUpperCase();
    this.simProfilesService.clone(body).subscribe(
      () => {
        this.showSnackbar(this.section.charAt(0).toUpperCase() + this.section.slice(1)   + ' Cloned');
        this.getSimProfiles();
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  };

}
