import { Component, OnInit } from '@angular/core';
import { mapKeys, concat } from 'lodash';

import { NodeService } from '../services/node/node.service';
// import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isFetching = true;
  dashboardData = [];
  dashboard = 'dashboard';

  constructor(
    // private introspectionService: IntrospectionService,
    private nodeService: NodeService
    ) { }

  async ngOnInit() {
    // const resDescribe = await this.introspectionService.getIntrospectionDescribe('CampaignPreviewVO');
    // console.log(resDescribe)
    const nodeDashboard = await this.nodeService.getNodeDashboard();
    this.dashboardData = [nodeDashboard[this.dashboard]];
    this.isFetching = false;
  }

  getPreviews = preview => {
    let totalPreviews = [];
    mapKeys(preview[0], value => {
      typeof value === 'object' ? totalPreviews = concat(totalPreviews, value) : totalPreviews.push(value);
    });
    console.log(totalPreviews)
    return totalPreviews;
  }

}
