import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent implements OnInit, OnChanges {

  @Input() hasType;
  @Input() hasState;
  @Input() data;
  @Input() image;
  @Input() typeEditedText;
  @Input() snackbarTime;
  @Input() header;
  @Input() triggerSnackbar;
  @Input() type;
  @Output() resetSnackbar = new EventEmitter();

  imagePath;

  @ViewChild('typeSnackbar', { static: false }) typeSnackbar;

  constructor() { }

  ngOnInit() {
    localStorage.setItem('id', JSON.stringify({section: this.type, name: this.data.title, id: this.data.id}));
    this.imagePath = 'assets/images/' + this.image + '.svg'
  }

  ngOnChanges(change) {
    setTimeout(() => {
      change.triggerSnackbar && change.triggerSnackbar.currentValue && (this.activeSnackbar(), this.resetSnackbar.emit());
    }, 1000);
  }

  findHeader(data) {
    return this.header.some((element) => element === data);
  }

  activeSnackbar() {
    this.typeSnackbar.snackbarShow();
  }

}
