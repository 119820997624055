import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StatsService } from '../common/section/stats/stats.service';
import { IntrospectionService } from '../services/introspection/introspection.service';

import { formatDate } from '../utils/dataUtils';
import { openCard } from '../utils/uiUtils';

@Component({
  selector: 'app-sim-stats',
  templateUrl: './sim-stats.component.html',
  styleUrls: ['./sim-stats.component.css']
})
export class SimStatsComponent implements OnInit {

  title = 'Sim Stats';
  description = 'Sim Stats';
  loadingDataList = true;
  showStats = false;
  arrayStats = [];
  arrayStatsT = {};
  tablePrimaryTextAlign = [];
  tablePrimaryHead = [];
  tablePrimaryBody = [];
  tablePrimaryArrayComponents = [];
  fields = "fields";
  statView = 'statView';
  sectionId = 'sim_stats';
  params = 'params';
  section = false;
  image = 'sim_stats';
  dataVO = 'SimStatVO';
  main = 'main';

  constructor(
    private statsService: StatsService,
    private introspectionService: IntrospectionService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.loadingDataList = false;
    this.getDataInfoT();
    this.getStatsT();
    openCard(this.sectionId);
  }

  async getDataInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    resDescribe[this.fields].forEach((field) => {
      this.arrayStats.push(field.name);
      this.tablePrimaryTextAlign.push('center');
      this.tablePrimaryArrayComponents.push(['normal']);
    });
    // this.title = resDescribe[this.main].pluralLabel;
    // this.description = resDescribe[this.main].description;
    this.section = true;
  }

  async getDataInfoT() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    resDescribe[this.fields].forEach((field) => {
      this.arrayStatsT[field.name] = [field.name == "ts" ? "" : field.name];
      // this.arrayStatsT[field.name] = [field.name];
    });
    this.section = true;
  }

  async getStats() {
    let stats;
    stats = await this.statsService.getSimStats({});
    this.tablePrimaryHead = this.arrayStats;
    stats.stats.forEach((stat) => {
      stat.ts = formatDate(stat.ts, 'YYYY-MM-DD HH:mm:ss')
      let statView = [];
      this.arrayStats.forEach((field) => {
        statView.push( stat[field] != null ? stat[field] : "");
      });
      this.tablePrimaryBody.push(statView);
    } );
    if (stats) this.showStats = true;
  }

  async getStatsT() {
    let stats;
    stats = await this.statsService.getSimStats({});
    for(let i=0; i <= stats.stats.length; i++){
      this.tablePrimaryTextAlign.push('center');
      this.tablePrimaryArrayComponents.push(['normal']);
    }
    Object.entries(this.arrayStatsT).forEach(([key, value]) => {
      stats.stats.forEach((stat) => {
        stat.ts = formatDate(stat.ts, 'YYYY-MM-DD HH:mm:ss');
        this.arrayStatsT[key].push( stat[key] != null ? stat[key] : "");
      } );
    });
    this.tablePrimaryHead = this.arrayStatsT["ts"];
    Object.entries(this.arrayStatsT).forEach(([key, value]) => {
      if (key != "ts"){
        this.tablePrimaryBody.push(this.arrayStatsT[key]);
      }
    });
    if (stats) {
      this.showStats = true;
      setTimeout(() => this.setTableStyle(), 1000);
    }
  }

  setTableStyle () {
    let divTable = document.getElementById("table-primary-responsive-1") as HTMLElement;
    divTable.className = "fixTableHeader";
    divTable.style.height = "480px";
    divTable.style.overflowY = "auto";
    divTable.style.overflowX = "auto";
    divTable.style.width = "100%";
    divTable.style.borderBottom = "1px solid #ccc";
    let theadsDiv = divTable.getElementsByTagName("thead")
    for (let i=0;  i < theadsDiv.length; i++) {
      theadsDiv[i].style.position = "-webkit-sticky";
      theadsDiv[i].style.position = "sticky";
      theadsDiv[i].style.top = "0px";
    }
  }

}
