export const openCard = (element) => {
  setTimeout(() => {
    if ( document.getElementById(element + '-icon') ) {
      if (document.getElementById(element + '-icon').innerHTML.indexOf('down') != -1)
        setTimeout(() => document.getElementById(element + '-displaceable-click').click(), 1000);
    }
    else openCard (element);
  }, 2000);
};

export const inputsStyles = (class1, className, bottom) => {
  const styleInput = document.getElementsByClassName( class1 ) as HTMLCollectionOf<HTMLElement>;
  Array.from(styleInput).forEach((data) => { data.style.marginBottom = bottom; });
  const styleLabel = document.getElementsByClassName( className ) as HTMLCollectionOf<HTMLElement>;
  Array.from(styleLabel).forEach((data) => { data.style.textAlign = 'right'; });
};

export const inputChange = () => {
  inputsStyles('input-simple', 'input-simple-name', '20px');
  inputsStyles('select-option', 'select-option-name', '20px');
  inputsStyles('datapicker-container', 'datepicker-label', '20px');
  inputsStyles('textarea-simple', 'textarea-simple-name', '20px');
  inputsStyles('checkbox', '', '12px');
};

export const activeCalendar = (active = false) => {
  if (active) {
    document.getElementById('calendar').style.opacity = '1';
    document.getElementById('calendar').style.pointerEvents = 'auto';
  } else {
    document.getElementById('calendar').style.opacity = '0.6';
    document.getElementById('calendar').style.pointerEvents = 'none';
  }
};
