import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { getNode } from '../global.component';
import { backTreeNavigation } from '../utils/navigationUtils';

@Injectable({
  providedIn: 'root'
})
export class TpRulesService {

  constructor(private http: HttpClient, private router: Router) {}

  tpRulesList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/tp_rule/ListTpRulesRequest', body, httpOptions);
  }

  tpRuleDelete(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/tp_rule/DeleteTpRuleRequest', body, httpOptions);
  }

  tpRuleStateSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  tpRule(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/tp_rule/GetTpRuleRequest', body, httpOptions);
  }

  tpRuleSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/tp_rule/SaveTpRuleRequest', body, httpOptions);
  }

  clone(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/CloneEntityRequest', body, httpOptions);
  }

  getTpRulesList = async (body) => this.tpRulesList(body).toPromise();

  getTpRule = async (body) => this.tpRule(body).toPromise().then(res => res).catch(() => backTreeNavigation(this.router));
}
