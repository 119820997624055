import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SimProfilesService } from '../../sim-profiles.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { textFormat } from '../../../utils/textUtils';
import { openCard } from '../../../utils/uiUtils';
import { circleColorState, stateFormat, stateSelected, findState } from '../../../utils/stateUtils';
import { organizeSection } from '../../../utils/sectionUtils';

@Component({
  selector: 'app-sim-profile',
  templateUrl: './sim-profile.component.html',
  styleUrls: ['./sim-profile.component.css']
})
export class SimProfileComponent implements OnInit {

  constructor(
    public simProfilesService: SimProfilesService,
    public introspectionService: IntrospectionService,
    private route: ActivatedRoute
  ) {}

  resultService = 'result';
  typeId = 'simProfile';
  type = 'sim_profile';
  hasData = false;
  dataSimProfile;
  id;
  params = 'params';
  stateSelectArray = [];
  stateSelect = [];
  simProfileEditedText = '';
  initSimProfile = false;
  sections = [];
  values = 'values';
  fields = 'fields';
  hasState = false;
  states = [];
  header = [];
  triggerSnackbar = false;
  snackbarTime = 5000;

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.route.paramMap.subscribe((params) => {
      this.id = params[this.params].id;
    });
    this.simProfileSections();
  }

  async simProfileSections() {
    const resSection = await this.introspectionService.getIntrospectionSection('SimProfileSection');
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('SimProfileVO');
    this.sections = organizeSection(resSection[this.values], resDescribe[this.fields]);
    this.hasState = findState(this.sections);
    this.getSimProfile();
    resDescribe[this.fields].find((element) => element.section === 'HEADER' && this.header.push(element.name));
  }

  getSimProfile = async () => {
    const res = await this.simProfilesService.getSimProfile({ id: this.id });
    this.dataSimProfile = res[this.typeId];
    this.hasData = true;
    this.functionSelectArray();
    !this.initSimProfile && (this.initSimProfile = true, openCard(this.dataSimProfile.id));
  }

  functionSelectArray() {
    this.stateSelectArray = [];
    this.stateSelect = [];
    let newName;
    for (const allowedTransitionStates of this.dataSimProfile.allowedTransitionStates) {
      newName = textFormat(allowedTransitionStates);
      this.stateSelectArray.push(
        stateFormat(newName, circleColorState(newName, this.states), this.dataSimProfile.id )
      );
    }
    newName = textFormat(this.dataSimProfile.state);
    this.stateSelectArray.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataSimProfile.id)
    );
    this.stateSelect.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataSimProfile.id)
    );
    this.stateSelectArray.splice(this.stateSelectArray.length - 1);
  }

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect[0],
      this.simProfilesService.simProfileStateSave({id, type: 'SIM_PROFILE', state: $event.name.toUpperCase(), }),
      this.resultService,
      this.simProfilesService.simProfile({ id }),
      this.getSimProfile,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.simProfileEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  hideEditing(active) {
    active ? this.stateSelect[0].disabled = true : this.stateSelect[0].disabled = false;
  }

  resetSnackbar() {
    this.triggerSnackbar = false;
  }

  saveSimProfile({data, component}) {
    this.simProfilesService.simProfileSave({ simProfile: data }).subscribe(
      () => {
        this.showSnackbar('SimProfile Saved');
        this.getSimProfile;
        component.editing = false;
        this.hideEditing(false);
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  }
}
