import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { NodeService } from '../../services/node/node.service';

import { openCard } from '../../utils/uiUtils';
import { FilesService } from '../files.service';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.css']
})
export class FilesListComponent implements OnInit {

  @Input() data;
  @Input() list;
  @Input() fields;
  @Input() loadingSectionList;
  @Input() sectionId;

  imageType = 'assets/images/file.svg';
  resultService = 'result';
  fileId = 'file';

  constructor(
    private router: Router, 
    private nodeService: NodeService,
    private filesService: FilesService 
    ) { }

  ngOnInit(): void {
    openCard(this.sectionId);
  }

  async cardSelect(file) {
    const appletFileView = await this.nodeService.resourceAccess('applet-file-view');
    if (appletFileView) {
      localStorage.setItem('id', JSON.stringify({section: 'files', name: file.name, id: file.id}));
      this.router.navigate(['/files', file.type.toLowerCase() + 's', file.id]);
    }
  }

  callMenu = (id) => {
    const menu = document.getElementById(`menu-${id}`).style;
    if (menu.visibility === 'hidden') {
      menu.visibility = 'visible';
      menu.opacity = '1';
    } else {
      menu.visibility = 'hidden';
      menu.opacity = '0';
    }
  }

  delete = (id, i) => {
    console.log('delete: '+ id);
    this.callMenu(id);
    this.filesService.fileDelete({id}).subscribe(
      (res) => {
        if (res[this.resultService] === 'OK') {
          console.log('File Deleted');
          this.list.splice(i, 1);
        }
      },
      (err) => console.log(err)
    );
  }

  process(action, id, i) {
    if (action === 'delete') this.delete(id,i);
    if (action === 'purgeRelatedObjects') this.purgeRelatedObjects(id,i);
    if (action === 'refresh') this.refresh(id,i);
  } 

  purgeRelatedObjects = (id, i) => {
    console.log('purgeRelatedObjects: '+ id);
    this.callMenu(id);
    this.filesService.purgeRelatedObjects({id}).subscribe(
      async (res) => {
        if (res[this.resultService] === 'OK') {
          const newFile = await this.filesService.getFile({id});
          this.list.splice(i,1,newFile[this.fileId]);
        }
      },
      (err) => console.log(err)
    );
  }

  refresh = async (id, i) => {
    console.log('refresh: '+ id);
    this.callMenu(id);
    const newFile = await this.filesService.getFile({id});
    this.list.splice(i,1,newFile[this.fileId]);
  }

  
}
