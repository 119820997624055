import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { Router } from '@angular/router';
import { CampaignsService } from '../campaigns.service';
import { openCard } from '../../utils/uiUtils';
import { stateActive } from '../../utils/stateUtils';
import { backTreeNavigation } from '../../utils/navigationUtils';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['../campaigns.component.css']
})

export class CampaignsListComponent implements OnInit {
  hasCampaigns = false;
  campaignsId = 'campaigns';
  section = 'campaign';
  result = 'result';
  campaignsList;
  stateSelect = [];
  stateSelectArray = [];
  initCampaign = false;
  snackbarTime = 5000;
  campaignsEditedText = '';
  states = [];
  triggerHeader = false;
  triggerSnackbar = false;
  dataListFilterInstrospection = 'ListCampaignsRequest';
  body = {};

  @Output() showList = new EventEmitter();
  @Input() dataFields;

  constructor(
    private campaignsService: CampaignsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
  }

  getCampaigns = async (body = null) => {
    if (body) this.body = body;
    const res = await this.campaignsService.getCampaignsList(this.body).then(data => data).catch(() => backTreeNavigation(this.router, true));
    this.campaignsList = res[this.campaignsId];
    this.stateActive();
    this.hasCampaigns = true;
    this.showList.emit();
    if (!this.initCampaign) this.openCard();
    this.triggerHeader = true;
  }

  openCard() {
    this.initCampaign = true;
    openCard(this.campaignsId);
  }

  stateSelected({$event, id, i}) {
    this.campaignsService.campaignStateSave({ id, type: 'CAMPAIGN', state: $event.name.toUpperCase() }).subscribe(
      (res) => {
        if (res[this.result] === 'OK') {
              this.showSnackbar('State Changed');
              this.campaignsList[i].state = res['state'];
              this.campaignsList[i].allowedTransitionStates = res['allowedTransitionStates'];
              this.getStateActive(this.campaignsList[i], i, 1);
            }
      },
      (err) => {
        console.log(err);
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        this.getStateActive(this.campaignsList[i], i, 1);
      }
    );
  }

  getStateActive(type: any, i: any, replace: any) {
    const types = [];
    types.push(type);
    const getStateActive = stateActive(types, [], this.states);
    this.stateSelect.splice(i, replace, getStateActive.stateSelectPrincipal[0]);
    this.stateSelectArray.splice(i, replace, getStateActive.stateSelectArray[0]);
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.snackbarTime = snackbar.time;
    this.campaignsEditedText = snackbar.text;
    this.triggerSnackbar = true;
  }

  stateActive() {
    const getStateActive = stateActive(this.campaignsList, this.stateSelect, this.states);
    this.stateSelectArray = getStateActive.stateSelectArray;
    this.stateSelect = getStateActive.stateSelectPrincipal;
  }

  cardSelectClick = (id) => this.router.navigate(['/' + this.section, id]);

  resetHeader = () => this.triggerHeader = false;

  resetSnackbar = () => this.triggerSnackbar = false;

  changeFilter = (body) => this.getCampaigns(body);

  cloneProcess = (body) => {
    body['type'] = this.section.toUpperCase();
    this.campaignsService.clone(body).subscribe(
      () => {
        this.showSnackbar(this.section.charAt(0).toUpperCase() + this.section.slice(1)   + ' Cloned');
        this.getCampaigns();
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  };

  processActionMenu(parameters) {
    if (parameters.action === 'recalcAvailableUsers') this.recalcAvailableUsers(parameters.id, parameters.index);
    else if (parameters.action === 'refresh') this.refresh(parameters.id, parameters.index);
  } 

  recalcAvailableUsers(id, i) {
    this.campaignsService.recalcAvailableUsers({id}).subscribe(
      async (res) => {
        if (res[this.result] === 'OK') {
          this.showSnackbar('Update Available Users In Process');
          const campaignUpdate = await this.campaignsService.getCampaign({id});
          this.campaignsList[i] = await campaignUpdate[this.section];
        }
      },
      (err) => {
        console.log(err);
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
      }
    );
  }

  async refresh(id, i) {
    const campaignUpdate = await this.campaignsService.getCampaign({id});
    this.campaignsList[i] = await campaignUpdate[this.section];
  }

}
