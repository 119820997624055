import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getNode } from '../global.component';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) {}

  scheduleList(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign_schedule/ListCampaignSchedulesRequest', body, httpOptions);
  }

  schedule(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign_schedule/GetCampaignScheduleRequest', body, httpOptions);
  }

  saveSchedule(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign_schedule/SaveCampaignScheduleRequest', body, httpOptions);
  }

  deleteSchedule(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/campaign_schedule/DeleteCampaignScheduleRequest', body, httpOptions);
  }

  scheduleStateSave(body) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/generic_entity/ToggleEntityStateRequest', body, httpOptions);
  }

  getScheduleList = async (body) => this.scheduleList(body).toPromise();

  getSchedule = async (body) => this.schedule(body).toPromise();

  setScheduleStateSave = async (body) => this.scheduleStateSave(body).toPromise();
}
