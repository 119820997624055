import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilesService } from '../../files.service';

import { openCard } from '../../../utils/uiUtils';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {

  loadingDataList = true;
  description;
  title;
  id = 'file';
  section = true;
  value = '_value';
  path;
  file = 'file';

  constructor(private route: ActivatedRoute, private filesService: FilesService) { }

  ngOnInit(): void {
    this.path = this.route.url[this.value][1].path;
    this.id = this.route.url[this.value][2].path;
    this.getData();
  }

  async getData() {
    const res = await this.filesService.getFile({id: this.id});
    console.log(res);
    this.title = res[this.file].name;
    this.description = res[this.file].type;
    this.loadingDataList = false;
    openCard(this.id);
  }

}
