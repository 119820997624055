import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getNode } from '../../global.component';


@Injectable({
  providedIn: 'root'
})
export class ListService {

  constructor(private http: HttpClient) { }

  list(body) {
    body.type === 'List' && (body.type = 'LIST');
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(getNode() + '/api/pub/v1/file_upload/ListFilesRequest', body, httpOptions);
  }

  getList = async (body) => this.list(body).toPromise();
}
