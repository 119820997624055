import { Component, OnInit, Input } from '@angular/core';
import { max, orderBy } from 'lodash';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.css']
})
export class ChartCardComponent implements OnInit {

  @Input() dataCard;

  head = ['Week', 'Active', 'Total'];
  body = [];
  options = {
    title: 'Company Performance',
    vAxis: {
      viewWindow: {
        min: 0,
        max: 1
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
    if (this.dataCard.stats) {
      this.dataCard.stats = orderBy(this.dataCard.stats, ['week'], ['asc']);
      this.dataCard.stats.forEach(stat => {
        this.body.push([String(stat.week), stat.active, stat.total]);
      });
    }
    if (this.dataCard.statDetails) {
      this.head = ['Hour', 'Sent', 'Delivered'];
      this.dataCard.statDetails = orderBy(this.dataCard.statDetails, ['hour'], ['asc']);
      this.dataCard.statDetails.forEach(stat => {
        this.body.push([String(stat.hour), stat.sent, stat.delivered]);
      });
    }
  }

  addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  validateTodaySend(dataCard) {
    return dataCard.todaySent ? `Today sent: ${dataCard.todaySent}` : `Sent: ${dataCard.sent}`
  }

  validateTodayDelivery(dataCard) {
    return dataCard.todaySent ? `Today delivery: ${dataCard.todayDelivered}` : `Delivery: ${dataCard.delivered}`
  }

}
