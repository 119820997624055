import {
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  OnChanges,
  QueryList,
  OnInit,
  AfterViewChecked
} from '@angular/core';
import { Router } from '@angular/router';

import { NodeService } from '../../services/node/node.service';
import { IntrospectionService } from '../../services/introspection/introspection.service';
import { TpRulesService } from '../../tp-rules/tp-rules.service';
import { AppletsService } from '../../applets/applets.service';
import { SmppAccountsService } from '../../smpp-accounts/smpp-accounts.service';
import { EndpointsService } from '../../endpoints/endpoints.service';
import { PricepointsService } from '../../pricepoints/pricepoints.service';
import { VaServicesService } from '../../vaservices/vaservices.service';
import { SimProfilesService } from '../../sim-profiles/sim-profiles.service';
import { CampaignsService } from '../../campaigns/campaigns.service';
import { FilesService } from '../../files/files.service';
import { inputChange } from '../../utils/uiUtils';
import { base64ToHex, hexToBase64, isBase64, inputValidation, formatDate, showError , validateDate } from '../../utils/dataUtils';
import {
  isEditing,
  validateClose,
  showSection,
  validateSections,
  validateLabels,
} from '../../utils/sectionUtils';
import {
  formReset,
  validateForm,
  validateInputsWithFields,
  detectChangesForm,
  allowedOptions,
  getFilterField,
  setNullArrays,
  setNullInputSimple
} from '../../utils/formUtils';
import { IdentifiersService } from 'src/app/identifiers/identifiers.service';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css'],
})
export class SectionComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  @Input('section') section = '';
  @Input('data') data = { state: '', binds: [], sleepyFlowActions: [], ads: [] };
  @Input('sections') sections = [];
  @Input('smsc') smsc = 'SmppBindVO';
  @Input('isNew') isNew = false;
  @Output() showSnackbar = new EventEmitter();
  @Output() showSnackbarErr = new EventEmitter();
  @Input('hiddenText') hiddenText = false;
  @Output() hideEditing = new EventEmitter();
  @Output() saveType = new EventEmitter();

  @ViewChildren('Input') inputs: QueryList<[]>;

  showEdit = false;
  showSections = false;
  results = '_results';
  state = 'state';
  values = 'values';
  files = 'files';
  editing = false;
  newSections = [];
  fields = [];
  copyFields;
  tpRules = 'tpRules';
  smppAccounts = 'smppAccounts';
  campaigns = 'campaigns';
  customListType = 'CUSTOM_LIST';
  listType = 'LIST';
  simProfiles = 'simProfiles';
  applets = 'applets';
  endpoints = 'endpoints';
  pricepoints = 'pricepoints';
  vaServices = 'vaServices';
  identifiers = 'identifiers';
  setBinds = false;
  setRams = false;
  setSFA = false;
  setAds = false;
  restore = false;
  specialSections = true;
  binds = 'binds';
  actions = 'sleepyFlowActions';
  ads = 'ads';
  ramCommand;
  ramInstallParameters = 'ramInstallParameters';
  ramDeleteParameters = 'ramDeleteParameters';
  currentRam = '';
  type = 'type';
  hasSimProfile = false;
  selectSimProfile;
  selectApplet;
  formatDate = formatDate;
  showSection = showSection;
  saveActions = false;
  saveAds = false;
  showUploadSection = false;
  countGetOptions = 0;
  countGetEnumIntrospection = 0;
  countGetEntityList = 0;
  afterViewInit = false;
  afterViewChecked = false;


  constructor(
    private introspectionService: IntrospectionService,
    private tpRulesService: TpRulesService,
    private appletsService: AppletsService,
    private smppAccountsService: SmppAccountsService,
    private endpointsService: EndpointsService,
    private pricepointsService: PricepointsService,
    private identifiersService: IdentifiersService,
    private vaServicesService: VaServicesService,
    private simProfilesService: SimProfilesService,
    private campaignsService: CampaignsService,
    private filesService: FilesService,
    private router: Router,
    private nodeService: NodeService
  ) {}

  setNullInputSimple = (inputs, data, nullTextArea) => setNullInputSimple(inputs, data, nullTextArea);
  setNullArrays = (data) => setNullArrays(data);

  ngOnInit() {
    this.initApp();
    this.validateAccess();
  }

  ngAfterViewInit() {
    setTimeout( () =>  this.validateInputsWithFields(), 2000);
    this.afterViewInit = true;
  }

  ngAfterViewChecked(){
    setTimeout ( () => this.afterViewChecked = true, 2000);
  }

  validateAccess = async () => {
    let hasRol = await this.nodeService.resourceAccess(this.section + '-save');
    this.showEdit = hasRol ? true : false;
    hasRol = await this.nodeService.resourceAccess(this.section + '-view');
    !hasRol && this.router.navigate(['home']);
  }

  async initApp() {
    this.setChangesDetect();
    validateClose(this.newSections, this.data);
    const hasRol = await this.nodeService.resourceAccess(this.section + '-save');
    if (!hasRol) {
      this.data[this.state] = 'NOEDIT';
    }
    this.showSections = true;
    this.hasSimProfile = true;
  }

  showButton() {
    this.editing = true;
    this.hideEditing.emit(true);
  }

  validateInputsWithFields() {
    this.newSections = validateLabels(this.newSections, this.fields);
    validateInputsWithFields(this.inputs, this.sections, this.fields);
    this.fields.map((field) => {
      field.className === 'RamCommand' && (this.ramCommand = field.data);
    });
    this.validateCheckboxs();
    inputChange();
    if( this.data.state === 'EDITING') {
      const sectionTargetUsers = this.newSections.find(section => section.value === 'TARGET_USERS');
      if (sectionTargetUsers !== undefined) this.showUploadSection = sectionTargetUsers.field.find( field => field.name === "listType").show;
      if (this.showUploadSection){
        setTimeout(() => {
          let uploadElement = document.getElementById('idUploadSection');
          if (uploadElement && uploadElement.parentElement && uploadElement.parentElement.id !== 'TARGET_USERS-content') document.getElementById('TARGET_USERS-content').prepend(uploadElement);
        }, 500);
      }
    }
  }

  setCheckbox = (data, value, setDOM = true) => {
    this.fields.map((field) => {
      if (field.label === data){
        field.data = value;
        // if (field.required && (field.data === undefined || field.data === null || field.data === '') ){
        if (field.required && field.data !== true){
            field.data = false;
        }
        this.data[field.label] = field.data;
      }
    });
  }

  async ngOnChanges() {
    const hasRol = await this.nodeService.resourceAccess(this.section + '-save');
    if (!hasRol) {
      this.data[this.state] = 'NOEDIT';
    }
    this.newSections = [];
    if (this.data[this.state] === 'EDITING'){
      this.isEditing();
      this.validateCheckboxs();
    }
    else {
      this.sections.map(
        (section) =>
          section.value !== 'HEADER' && this.newSections.push(section)
      );

    }
    validateClose(this.newSections, this.data);
    this.fields = validateSections(this.sections, this.data);
    this.copyFields = this.fields;
    this.newSections = validateLabels(this.newSections, this.fields);
    this.fields.map((field) => field.className === 'RamCommand' && (this.ramCommand = field.data));

  }

  callDisplay(sections) {
    validateLabels(sections, this.fields);
  }

  isEditing() {
    isEditing(this.sections, this.newSections);
    inputChange();
    this.setChangesDetect();
  }

  setChangesDetect() {
    setTimeout(() => {
      detectChangesForm(
        this.inputs[this.results],
        () => this.showButton(),
        () => this.validateInputsWithFields(),
        this.setCheckbox
      );
    }, 1000);
  }

  isRequired = (input) => input ? true : false;

  validateForm() {
    this.setRams = true;
    this.setBinds = true;
    this.setSFA = true;
    this.setAds = true;
    setTimeout(async () => {
      const hasEmpty = await validateForm(this.inputs, this.data);

      if (this.data[this.type] === 'ADVERTISING') {
        console.log('if 5');
        if (!hasEmpty && this.data[this.ads] && this.saveAds )
          this.saveSection();
        return;
      }

      this.validateAds();

      if (this.data[this.binds] === undefined && (this.data[this.actions] === undefined || this.data[this.actions].length === 0) && this.data[this.currentRam] === undefined && ( this.data[this.ads] === undefined || this.data[this.ads].length === 0)) {
        console.log('if 1');
        !hasEmpty && this.saveSection();
      } else {
        if (this.data[this.actions] === undefined && this.data[this.currentRam] === undefined && ( this.data[this.ads] === undefined || this.data[this.ads].length === 0)) {
          console.log('if 2');
          !hasEmpty && this.data[this.binds] && this.saveSection();
        }
        if (this.data[this.binds] === undefined && this.data[this.type] === 'SLEEPY_FLOW') {
          console.log('if 3');
          !hasEmpty && this.data[this.actions] && this.saveActions && this.saveSection();
        }
        if (this.data[this.binds] === undefined && this.data[this.type] === 'RAM') {
          console.log('if 4');
          !hasEmpty && this.data[this.currentRam] && this.saveSection();
        }
      }
    }, 1000);
  }

  async getBinds(binds) {
    this.data[this.binds] = await binds;
    this.setBinds = false;
  }

  async getRams({rams, ramCommand}) {
    this.currentRam = ramCommand;
    this.data[ramCommand] = await rams;
    this.setRams = false;
  }

  async getSFA(actions) {
    this.saveActions = await actions;
    this.data[this.actions] = await actions;
    this.setSFA = false;
  }

  async getAds(ads) {
    this.saveAds = await ads;
    this.data[this.ads] = await ads;
    this.setAds = false;
  }

  saveSection() {
    const data = this.data;
    const ads = this.data[this.ads];
    // delete fields with show false
    this.sections.map((items) =>
      items.field.map(
        (field) =>
          !field.show &&
          data[field.name] &&
          field.name !== 'state' &&
          delete data[field.name]
      )
    );
    data[this.actions] && data[this.actions].map((item) => delete item.new);
    data[this.actions] && data[this.actions].map((field) => Object.keys(field).map((key) => field[key] === '' && delete field[key]));

    data[this.ads] = ads;

    this.fields.map((field) => {
      field.widgetType === 'HEX_TEXT' &&
        data[field.label] &&
        !isBase64(data[field.label]) &&
        (data[field.label] = hexToBase64(data[field.label]));
      field.widgetType === 'DATE' && data[field.label] &&
        (data[field.label] = formatDate(validateDate(data[field.label]), 'YYYY-MM-DDTHH:mm:ss.ssssss') + 'Z');
    });
    const component = this;
    this.setNullInputSimple(this.inputs, data, true);
    this.setNullArrays(data);
    this.saveType.emit({ data, component });
  }

  getOptions = (field) => {
    if (!field.inProcess){
      field.inProcess = true;
      field.options = [];
      if ((field.widgetType === 'SELECT' && field.classType === 'ENUM') || (field.widgetType === 'SELECT_MULTIPLE' && field.subClassType === 'ENUM')) this.getEnumIntrospection(field);
      else this.getEntityList(field);
    }
  }; 

  async getEnumIntrospection(field) {
    const res = await this.introspectionService.getIntrospectionSection((field.widgetType === 'SELECT_MULTIPLE') ? field.subClassName : field.className);
    if (!field.required && field.widgetType !== 'SELECT_MULTIPLE') res[this.values].unshift({ name: '', value: 'null' });
    const options = res[this.values];
    allowedOptions(field, options);
  }

  cancel() {
    this.restore = true;
    this.specialSections = false;
    formReset(this.newSections, this.inputs[this.results], this.data);
    this.newSections.map((newSection) => {
      if (newSection.name === 'ADS' && newSection.show){
        newSection.show = false;
        setTimeout(() => {
          newSection.show = true;
        }, 500);
      }
    });
    this.editing = false;
    this.hideEditing.emit(false);
    this.fields = this.copyFields;
    this.validateInputsWithFields();
    setTimeout(() => {
      this.specialSections = true;
    }, 500);
    setTimeout(() => {
      this.restore = false;
      inputChange();
    }, 500);
  }

  validateCheckboxs = () => {
    setTimeout(() => {
      this.fields.map(
        (field) => {
          if (field.widgetType === 'CHECKBOX'){
            let checked = false;
            if ( this.data[field.name] === undefined || this.data[field.name] === '' || this.data[field.name] === null ){
              if ( field.required ) checked = true;
            }
            else checked = this.data[field.name] ? false : true;
            let chekBox = document.getElementById(field.label+'false') as HTMLInputElement;
            if (chekBox) chekBox.checked = checked;
          }
        }
      );
    }, 500);
  }

  validateHex = (data) => (isBase64(data) ? data : base64ToHex(data));

  inputValidation = (id) => inputValidation(id);

  async getEntityList(field, searchAttribute = null) {
    const entityVO = field.targetClassName;
    let options = [];
    const filterField = getFilterField(field);
    if (searchAttribute != null){
      filterField['searchAttribute'] =  searchAttribute;
    }

    switch (entityVO){
      case 'AppletVO': {
        const res = await this.appletsService.getAppletsList(filterField);
        options = res[this.applets];
        break;
      }
      case 'TpRuleVO': {
        const res = await this.tpRulesService.getTpRulesList(filterField);
        options = res[this.tpRules];
        break;
      }
      case 'SmppAccountVO': {
        const res = await this.smppAccountsService.getSmppAccountsList(filterField);
        options = res[this.smppAccounts];
        break;
      }
      case 'EndpointVO': {
        const res = await this.endpointsService.getEndpointsList(filterField);
        options = res[this.endpoints];
        break;
      }
      case 'PricepointVO': {
        const res = await this.pricepointsService.getDataList(filterField);
        options = res[this.pricepoints];
        break;
      }
      case 'VaServiceVO': {
        const res = await this.vaServicesService.getDataList(filterField);
        options = res[this.vaServices];
        break;
      }
      case 'CampaignVO': {
        const res = await this.campaignsService.getCampaignsList(filterField);
        options = res[this.campaigns];
        break;
      }
      case 'SimProfileVO': {
        const res = await this.simProfilesService.getSimProfilesList(filterField);
        options = res[this.simProfiles];
        break;
      }
      case 'UploadedFileVO': {
        const res = await this.filesService.getFilesList(filterField);
        options = res[this.files];
        break;
      }
      case 'IdentifierVO': {
        const res = await this.identifiersService.getDataList(filterField);
        options = res[this.identifiers];
        break;
      }
    }
    if (options.length > 0) {
      if (!field.required && field.widgetType !== 'SELECT_MULTIPLE' && field.widgetType !== 'BAG' ) options.unshift({ name: '', value: 'null' });
      options.map((item) => item.value = item.id);
    }

    field.options = options;
    field.inProcess = false;
  }

  getNameValue = (field, id) => {
    if (!field.inProcess){
      field.inProcess = true;
      this.getEntityName(field, id);
    }
  };

  async getEntityName (field, id) {
    const entityVO = field.targetClassName;
    let entity;

    switch (entityVO){
      case 'AppletVO': {
        const res = await this.appletsService.getApplet({id});
        entity = res['applet'];
        break;
      }
      case 'TpRuleVO': {
        const res = await this.tpRulesService.getTpRule({id});
        entity = res['tpRule'];
        break;
      }
      case 'SmppAccountVO': {
        const res = await this.smppAccountsService.getSmppAccount({id});
        entity = res['smppAccount'];
        break;
      }
      case 'EndpointVO': {
        const res = await this.endpointsService.getEndpoint({id});
        entity = res['endpoint'];
        break;
      }
      case 'PricepointVO': {
        const res = await this.pricepointsService.getData({id});
        entity = res['pricepoint'];
        break;
      }
      case 'VaServiceVO': {
        const res = await this.vaServicesService.getData({id});
        entity = res['vaService'];
        break;
      }
      case 'CampaignVO': {
        const res = await this.campaignsService.getCampaign({id});
        entity = res['campaign'];
        break;
      }
      case 'SimProfileVO': {
        const res = await this.simProfilesService.getSimProfile({id});
        entity = res['simProfile'];
        break;
      }
      case 'UploadedFileVO': {
        const res = await this.filesService.getFile({id});
        entity = res['file'];
        break;
      }
      case 'IdentifierVO': {
        const res = await this.identifiersService.getData({id});
        entity = res['identifier'];
        break;
      }
    }

    if (await entity){
      if (!field.values) field.values = [];
      field.values[id] = await entity.name;
      field.inProcess = false;
    }

  };
  

  showError = (data) => showError(data);

  setEditing() {
    if (this.data.state === 'EDITING'){
      this.editing = true;
      this.hideEditing.emit(true);
    }
  }

  showLabel = (show, status) => (status === 'EDITING') ? true : show;

  validateSFA() {
    !this.data[this.actions] && (this.data[this.actions] = []);
    return this.data[this.actions];
  }

  validateAds() {
    !this.data[this.ads] && (this.data[this.ads] = []);
    return this.data[this.ads];
  }

  checkboxFalse(field) {
    let value = this.data[field.name];
    if ( value === true ) return false;
    if ( value !== false ){
      if ( field.required ){
        this.setCheckbox(field.name, false);
        return true;
      }
      else return false;
    }
    else return true;
  }

  showSectionEditing(section) {
    // if (this.afterViewChecked){
      // console.log(section.name + ': ' + this.afterViewInit); 
    if (this.data.state === 'EDITING') {
      if (document.getElementById(section.name)) {
        return document.getElementById(section.name).clientHeight > 0;
      }
      return false;
    }
    else {
      if (document.getElementById(section.name + '-content')) {
        return document.getElementById(section.name + '-content').clientHeight > 0;
      }
      return true;
    }
    // }
    // else setTimeout (() => {return this.showSectionEditing(section)}, 2000);
  }

  datetimeFormat = (text) => formatDate(text, 'DD-MM-YYYY HH:mm:ss', 2);

  showSnackbarS(text) {
    this.showSnackbar.emit(text);
  }

  showSnackbarErrS(text) {
    this.showSnackbarErr.emit(text);
  }

  uploadFinish(id = null){
    this.showSnackbarS('File uploaded successfully');
    this.newSections.find(section => section.value === 'TARGET_USERS').field.map( (field) => {
      if (field.targetClassName === "UploadedFileVO"){
        field.options = null;
        field.inProcess = false;
      }
    });
  }

  changeSelect(name, $event) {
    console.log({name, $event});
    console.log("this.data[name]= " + this.data[name]);
    console.log(this.inputs);
  }
}

