

export const backTreeNavigation = (router, backHome = false) => {
  if (backHome) {
    router.navigate(['home']);
  } else {
    const browserUrlTree = 'browserUrlTree';
    router.navigate([router[browserUrlTree].root.children.primary.segments[0].path]);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
};
