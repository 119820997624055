import { Component, OnInit } from '@angular/core';

import { IntrospectionService } from '../services/introspection/introspection.service';

@Component({
  selector: 'app-tp-rules',
  templateUrl: './tp-rules.component.html',
  styleUrls: ['./tp-rules.component.css']
})
export class TpRulesComponent implements OnInit {
  section = false;
  sectionId = 'tpRules';
  image = 'tp_rule';
  dataVO = 'TpRuleVO';
  title;
  description;
  loadingDataList = true;
  main = 'main';
  fields = 'fields';
  dataFields;

  constructor( private introspectionService: IntrospectionService ) { }

  ngOnInit() {
    this.getAppletsInfo();
  }

  async getAppletsInfo() {
    const resDescribe = await this.introspectionService.getIntrospectionDescribe(this.dataVO);
    this.title = resDescribe[this.main].pluralLabel;
    this.description = resDescribe[this.main].description;
    this.section = true;
    this.dataFields = resDescribe[this.fields];
  }

  showView() {
    this.loadingDataList = false;
  }

}
