import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getNode } from '../global.component';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) {}

  filesList(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/ListFilesRequest', body, httpOptions);
  }

  file(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/GetFileRequest', body, httpOptions );
  }

  fileUpload(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/FileUploadRequest', body, httpOptions);
  }

  fileCreate(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/CreateFileRequest', body, httpOptions);
  }

  fileChunk(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/ChunkUploadRequest', body, httpOptions);
  }

  fileAssemble(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/AssembleFileRequest', body, httpOptions);
  }

  fileDelete(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/DeleteFileRequest', body, httpOptions);
  }
  
  purgeRelatedObjects(body) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.http.post(getNode() + '/api/pub/v1/file_upload/PurgeRelatedObjectsRequest', body, httpOptions);
  }

  getFilesList = async (type = {}) => this.filesList(type).toPromise();

  getFile = async (body) => this.file(body).toPromise();
}
