import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { IntrospectionService } from '../../services/introspection/introspection.service';
import { NodeService } from '../../services/node/node.service';

@Component({
  selector: 'app-list-and-view',
  templateUrl: './list-and-view.component.html',
  styleUrls: ['./list-and-view.component.css']
})
export class ListAndViewComponent implements OnInit {

  @Input() section;
  @Input() image;
  @Input() list = [];
  @Input() fields;
  @Input() sectionShow;
  @Input() loadingSectionList;
  @Input() dataListInstrospection;
  @Input() isFilter = true;
  @Input() sectionEditedText;
  @Input() snackbarTime;
  @Input() triggerHeader;
  @Input() triggerSnackbar;
  @Output() resetSnackbar = new EventEmitter();
  @Output() processActionMenu = new EventEmitter();
  @Output() callListFilter = new EventEmitter();

  imageType;
  resultService = 'result';
  filterList = [];
  showEdit = false;
  showView = false;
  body;

  @ViewChild('sectionSnackbar', { static: false }) sectionSnackbar;

  constructor(
    private introspectionService: IntrospectionService,
    private nodeService: NodeService,
    private router: Router
    ) { }

  async ngOnInit() {
    this.imageType = 'assets/images/' + this.image + '.svg';
    this.validateAccess();
    if ((!this.filterList || isEmpty(this.filterList)) && this.dataListInstrospection){
      if (await this.getFilters()) {
        if (this.isFilter) this.changeFilter();
        else this.removeStoragedFilter();
      };  
    }
  }

  async ngOnChanges() {
    console.log('ngOnChanges list');
    setTimeout(() => {
      // this.triggerHeader && this.fields && (this.getHeader(), this.resetHeader.emit());
      this.triggerSnackbar && (this.activeSnackbar(), this.resetSnackbar.emit());
    }, 500);
  }

  activeSnackbar = () => {
    this.sectionSnackbar.snackbarShow();
  }

  validateAccess = async () => {
    let hasRol = await this.nodeService.resourceAccess(this.section + '-list');
    if (!hasRol) hasRol = await this.nodeService.resourceAccess(this.section);
    if (!hasRol) this.router.navigate(['home']);
    hasRol = await this.nodeService.resourceAccess(this.section + '-save');
    this.showEdit = hasRol ? true : false;
    hasRol = await this.nodeService.resourceAccess(this.section + '-view');
    this.showView = hasRol ? true : false;
  }

  callMenu = (id) => {
    const menu = document.getElementById('menu').style;
    if (menu.visibility === 'hidden') {
      menu.visibility = 'visible';
      menu.opacity = '1';
    } else {
      menu.visibility = 'hidden';
      menu.opacity = '0';
    }
  }

  filterSelected = (name) => { 
    return (this.isFilter ? localStorage.getItem(name) : '');
  }
  
  changeFilter = (name = null, value = null) => {
    let getDataList = true;
    if ( name !== null ) localStorage.setItem(name, value);
    this.body = {};
    if (this.dataListInstrospection){
      this.filterList.forEach(filter => {
        const filterValue = localStorage.getItem(filter.name);
        if (!filterValue || filterValue === 'ALL'){
          if (this.isFilter) this.body[filter.name] =  null;
          else getDataList = false;
        }
        else {
          this.body[filter.name] =  filterValue;
        }
      });
    }
    if (getDataList) this.callListFilter.emit(this.body);
  }

  changeFilterChekbox = (name, $event) => {
    if ($event.target.tagName === 'INPUT') {
      const radioButton = (document.querySelector(`input[type="radio"][name="${name}"]:checked`) as HTMLInputElement)?.value;
      const value = (radioButton === undefined) ? null : (radioButton === 'true');
      this.changeFilter(name, value);
   }
  }

  async getFilters () {
    const filters = await this.introspectionService.getIntrospectionDescribe(this.dataListInstrospection);
    this.filterList = filters['fields'];
    this.filterList.map ( async (filter, index) => { 
      const widgetType = filter.widgetType;
      if ( widgetType ){
        if ( widgetType === 'SELECT' && filter.className ){
          const options = await this.introspectionService.getIntrospectionSection(filter.className);
          filter.options = options['values'];
          if (this.isFilter) {
            filter.options.unshift({ name: 'ALL', value: 'ALL' });
            if (!localStorage.getItem(filter.name) || !this.filterValid(localStorage.getItem(filter.name), filter.options)) localStorage.setItem(filter.name, 'ALL');
          }
        }
        if ( widgetType === 'CHECKBOX' ){
          filter.value = null;
          localStorage.setItem(filter.name, null);
        } 
      }
    });
    return true;
  }

  removeStoragedFilter = () => {
    this.filterList.forEach(filter => {
      localStorage.removeItem(filter.name);
    });
  };

  filterValid = (value, options) => {
    let valid = false;
    options.forEach ( option => {
      if (option.value === value){
        valid = true;
        return;
      }
    });
    return valid;
  };

  process = (action, id, index) => {
    this.callMenu(id);
    this.processActionMenu.emit({action, id, index});
  };
  
  hasMenu = (item) => {
    let hasMenu = false;
    this.fields.forEach( field => {
      if (field.section === 'MENU' && item[field.name])
        hasMenu = true;
    });
    return hasMenu;
  };
  
}
