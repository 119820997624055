import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppletsService } from '../../applets.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { textFormat } from '../../../utils/textUtils';
import { openCard } from '../../../utils/uiUtils';
import {
  circleColorState,
  stateFormat,
  stateSelected,
  findState,
} from '../../../utils/stateUtils';
import { organizeSection } from '../../../utils/sectionUtils';

@Component({
  selector: 'app-applet',
  templateUrl: './applet.component.html',
  styleUrls: ['./applet.component.css'],
})
export class AppletComponent implements OnInit {
  constructor(
    public appletsService: AppletsService,
    public introspectionService: IntrospectionService,
    private route: ActivatedRoute
  ) {}

  resultService = 'result';
  type = 'applet';
  hasType = false;
  dataApplet;
  id;
  image = 'applet';
  params = 'params';
  stateSelectArray = [];
  stateSelect = [];
  appletEditedText = '';
  initApplet = false;
  sections = [];
  values = 'values';
  fields = 'fields';
  hasState = false;
  states = [];
  header = [];
  triggerSnackbar = false;
  snackbarTime = 5000;

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.route.paramMap.subscribe((params) => {
      this.id = params[this.params].id;
    });
    this.appletSections();
  }

  async appletSections() {
    const resSection = await this.introspectionService.getIntrospectionSection('AppletSection');
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('AppletVO');
    this.sections = organizeSection(
      resSection[this.values],
      resDescribe[this.fields]
    );
    this.hasState = findState(this.sections);
    this.getApplet();
    resDescribe[this.fields].find((element) =>  element.section === 'HEADER' && this.header.push(element.name));
  }

  getApplet = async () => {
    // this.appletSections();
    const res = await this.appletsService.getApplet({ id: this.id });
    this.dataApplet = res[this.type];
    this.hasType = true;
    this.functionSelectArray();
    !this.initApplet && (this.initApplet = true, openCard(this.dataApplet.id));
  }

  functionSelectArray() {
    this.stateSelectArray = [];
    this.stateSelect = [];
    let newName;
    for (const allowedTransitionStates of this.dataApplet.allowedTransitionStates) {
      newName = textFormat(allowedTransitionStates);
      this.stateSelectArray.push(
        stateFormat(
          newName,
          circleColorState(newName, this.states),
          this.dataApplet.id
        )
      );
    }
    newName = textFormat(this.dataApplet.state);
    this.stateSelectArray.push(
      stateFormat(
        newName,
        circleColorState(newName, this.states),
        this.dataApplet.id
      )
    );
    this.stateSelect.push(
      stateFormat(
        newName,
        circleColorState(newName, this.states),
        this.dataApplet.id
      )
    );
    this.stateSelectArray.splice(this.stateSelectArray.length - 1);
  }

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect[0],
      this.appletsService.appletStateSave({ id, type: 'APPLET', state: $event.name.toUpperCase(), }),
      this.resultService,
      this.appletsService.applet({ id }),
      this.getApplet,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.appletEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  hideEditing(active) {
    active ? (this.stateSelect[0].disabled = true) : (this.stateSelect[0].disabled = false);
  }

  resetSnackbar() {
    this.triggerSnackbar = false;
  }

  saveApplet({ data, component }) {
    this.appletsService.appletSave({ applet: data }).subscribe(
      () => {
        this.showSnackbar('Applet Saved');
        this.getApplet();
        component.editing = false;
        this.hideEditing(false);
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  }
}
