import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SmppAccountsService } from '../../smpp-accounts.service';
import { IntrospectionService } from '../../../services/introspection/introspection.service';

import { textFormat } from '../../../utils/textUtils';
import { openCard } from '../../../utils/uiUtils';
import { circleColorState, stateFormat, stateSelected, findState } from '../../../utils/stateUtils';
import { organizeSection } from '../../../utils/sectionUtils';

@Component({
  selector: 'app-smpp-account',
  templateUrl: './smpp-account.component.html',
  styleUrls: ['./smpp-account.component.css']
})
export class SmppAccountComponent implements OnInit {

  constructor(
    public smppAccountsService: SmppAccountsService,
    public introspectionService: IntrospectionService,
    private route: ActivatedRoute
  ) {}

  resultService = 'result';
  smppAccount = 'smppAccount';
  type = 'smpp_account';
  hasData = false;
  dataSmppAccount;
  id;
  params = 'params';
  stateSelectArray = [];
  stateSelect = [];
  smppAccountEditedText = '';
  initSmppAccount = false;
  sections = [];
  values = 'values';
  fields = 'fields';
  hasState = false;
  states = [];
  header = [];
  triggerSnackbar = false;
  snackbarTime = 5000;

  ngOnInit() {
    this.states = JSON.parse(localStorage.getItem('states'));
    this.route.paramMap.subscribe((params) => {
      this.id = params[this.params].id;
    });
    this.smppAccountSections();
  }

  async smppAccountSections() {
    const resSection = await this.introspectionService.getIntrospectionSection('SmppAccountSection');
    const resDescribe = await this.introspectionService.getIntrospectionDescribe('SmppAccountVO');
    this.sections = organizeSection(
      resSection[this.values],
      resDescribe[this.fields]
    );
    this.hasState = findState(this.sections);
    this.getSmppAccount();
    resDescribe[this.fields].find((element) => {
      element.section === 'HEADER' && this.header.push(element.name);
    });
  }

  getSmppAccount = async () => {
    const res = await this.smppAccountsService.getSmppAccount({ id: this.id });
    this.dataSmppAccount = res[this.smppAccount];
    this.hasData = true;
    this.functionSelectArray();
    !this.initSmppAccount && (this.initSmppAccount = true, openCard(this.dataSmppAccount.id));
  }

  functionSelectArray() {
    this.stateSelectArray = [];
    this.stateSelect = [];
    let newName;
    for (const allowedTransitionStates of this.dataSmppAccount.allowedTransitionStates) {
      newName = textFormat(allowedTransitionStates);
      this.stateSelectArray.push(
        stateFormat(newName, circleColorState(newName, this.states), this.dataSmppAccount.id )
      );
    }
    newName = textFormat(this.dataSmppAccount.state);
    this.stateSelectArray.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataSmppAccount.id)
    );
    this.stateSelect.push(
      stateFormat(newName, circleColorState(newName, this.states), this.dataSmppAccount.id)
    );
    this.stateSelectArray.splice(this.stateSelectArray.length - 1);
  }

  stateSelected($event, id) {
    stateSelected(
      this.stateSelect[0],
      this.smppAccountsService.smppAccountStateSave({id, type: 'SMPP_ACCOUNT', state: $event.name.toUpperCase(), }),
      this.resultService,
      this.smppAccountsService.smppAccount({ id }),
      this.getSmppAccount,
      this.showSnackbar,
      this.showSnackbarErr
    );
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.smppAccountEditedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  hideEditing(active) {
    active ? this.stateSelect[0].disabled = true : this.stateSelect[0].disabled = false;
  }

  resetSnackbar() {
    this.triggerSnackbar = false;
  }

  saveSmppAccount({data, component}) {
    this.smppAccountsService.smppAccountSave({ smppAccount: data }).subscribe(
      () => {
        this.showSnackbar('SmppAccount Saved');
        this.getSmppAccount;
        component.editing = false;
        this.hideEditing(false);
      },
      (err) => {
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
        console.log(err);
      }
    );
  }
}

