import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionsService } from '../subscriptions.service';
import { openCard } from '../../utils/uiUtils';
import { backTreeNavigation } from '../../utils/navigationUtils';

@Component({
  selector: 'app-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrls: ['../subscriptions.component.css'],
})
export class SubscriptionsListComponent implements OnInit {
  hasData = false;
  result = 'result';
  dataList = [];
  init = false;
  editedText = '';
  triggerHeader = false;
  snackbarTime = 5000;
  triggerSnackbar = false;
  dataListInstrospection = 'FindSubscriptionsRequest';
  dataId = 'subscription';
  isFilter = false;
  body: {};

  @Input() dataFields;
  @Input() section;
  @Input() sectionId;
  @Input() image;
  @Input() sectionShow;
  @Output() showList = new EventEmitter();

  constructor(
    private dataService: SubscriptionsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.showList.emit();
    if (!this.init) this.openCard();
  }

  getDataList = async (body = {}) => {
    this.body = body;
    const res = await this.dataService.getDataList(body).then(data => data).catch(() => backTreeNavigation(this.router, true));
    this.dataList = res[this.sectionId];
    this.hasData = true;
    this.showList.emit();
    if (!this.init) this.openCard();
    this.triggerHeader = true;
  }

  openCard() {
    this.init = true;
    openCard(this.section);
  }

  showSnackbar = (text) => {
    this.showSnackbarNew({text: text, time: 5000});
  }

  showSnackbarErr = (text) => {
    this.showSnackbarNew({text: text, time: 15000});
  }

  showSnackbarNew = (snackbar) => {
    this.editedText = snackbar.text;
    this.snackbarTime = snackbar.time;
    this.triggerSnackbar = true;
  }

  processActionMenu(parameters) {
    if (parameters.action === 'canBeUnsubscribed') this.unsubscribed(parameters.id,parameters.index);
  } 

  unsubscribed = (id, index) => {
    this.dataService.unsubscribe({id}).subscribe(
      async (res) => {
        if (res[this.result] === 'OK') {
          this.showSnackbar('Unsubscribed Succesfully');
          const newData = await this.dataService.getData({id});
          this.dataList.splice(index,1,newData[this.dataId]);
          // this.getDataList(this.body);
        }
      },
      (err) => {
        console.log(err);
        this.showSnackbarErr(err.error.errorMessage ? err.error.errorMessage : err.error.result) ;
      }
    );
  }

  resetHeader = () => this.triggerHeader = false;

  resetSnackbar = () => this.triggerSnackbar = false;

  changeFilter = (body) => this.getDataList(body);

}
