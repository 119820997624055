import { Component, OnInit, ViewChildren, QueryList, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

import { NodeService } from '../../../../services/node/node.service';
import { FilesService } from '../../../files.service';

import { validateForm } from '../../../../utils/formUtils';

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.css']
})
export class AddFileComponent implements OnInit {

  @ViewChildren('Input') inputs: QueryList<[]>;

  @Input() externAdd;
  @Input() loadFile;
  @Output() uploadFinish = new EventEmitter();

  results = '_results';
  resultService = 'result';
  fileLoading = false;
  progresBar = 0;
  chunks = [];
  fileName = 'file-name';
  idName = 'id';

  constructor(private router: Router, private nodeService: NodeService, private filesService: FilesService) { }

  async ngOnInit() {
    const listFileSave = await this.nodeService.resourceAccess('list-file-save');
    (!listFileSave) && this.router.navigate(['home']);
  }

  addItem() {
    this.loadFile = !this.loadFile;
  }

  async validateForm() {
    console.log('validateForm');
    console.log(this.inputs);
    const isEmpty = await validateForm(this.inputs, {});
    !isEmpty && this.validateFile();
  }

  async validateFile() {
    this.inputs[this.results][0].validateFile();
    const isEmpty = this.inputs[this.results][0].inputFileError;
    const file = this.inputs[this.results][0].inputFileData;
    const chunksAmount = Math.ceil(this.inputs[this.results][0].inputFileData.size / 2000000);
    const chunks = await this.chunksPrimise(this.sliceFile(file, chunksAmount, file.type)).then(res => res);
    !isEmpty && this.saveData( file.name, chunks, chunksAmount);
  }

  chunksPrimise = async (chunks) => {
    return Promise.all(await chunks.map(async (chunk) => Promise.resolve( await this.toBase64(chunk).then(res => res))));
  }

  sliceFile(file, chunksAmount, type) {
    let byteIndex = 0;
    const chunks = [];

    for (let i = 0; i < chunksAmount; i += 1) {
      const byteEnd = Math.ceil((file.size / chunksAmount) * (i + 1));
      chunks.push(file.slice(byteIndex, byteEnd, type));
      byteIndex += (byteEnd - byteIndex);
    }
    return chunks;
  }

  toBase64(chunk) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(chunk);
      reader.onloadend = () => {
        resolve((reader.result as string).split(',')[1]);
      };
      reader.onerror = err => reject(err);
    });
  }

  saveData(name, chunks, chunksAmount) {
    this.fileLoading = true;
    chunksAmount === 1 ? this.uploadFile(name, chunks) : this.createFile(name, chunks, chunksAmount);
  }

  uploadFile(name, chunks) {
    this.progressBar(0, 1);
    const body = {
      name: name,
      data: chunks[0]
    };
    this.filesService.fileUpload(body).subscribe(
      (res) => {
        this.progressBar(1, 1);
        if (res[this.resultService] === 'OK' )
          this.uploadFileFinish(res[this.idName]);
      },
      (err) =>  console.log(err)
    );
  }

  createFile(name, chunks, chunksAmount) {
    this.progressBar(0, chunksAmount);
    const body = {
      // type: 'LIST',
      name: name
    };
    this.filesService.fileCreate(body).subscribe(
      (res) => {
        this.uploadChunks(res[this.idName], chunks, chunksAmount, 0);
      },
      (err) =>  console.log(err)
    );
  }

  uploadChunks(id, chunks, chunksAmount, chunkCurrent) {
    this.progressBar(chunkCurrent, chunksAmount);
    const body = {
      id,
      chunkNumber: chunkCurrent + 1,
      data: chunks[chunkCurrent]
    };
    this.filesService.fileChunk(body).subscribe(
      () => {
        chunkCurrent++;
        this.progressBar(1, 1);
        if (chunkCurrent === chunks.length) {
          this.assembleFile(id);
        } else {
          this.uploadChunks(id, chunks, chunksAmount, chunkCurrent);
        }
      },
      (err) =>  console.log(err)
    );
  }

  assembleFile(id) {
    this.filesService.fileAssemble({id}).subscribe(
      (res) => {
        if (res[this.resultService] === 'OK' )
          this.uploadFileFinish(id);
      },
      (err) =>  console.log(err)
    );
  }

  progressBar = (currentChunk, chunksAmount) => this.progresBar = Math.ceil((currentChunk / chunksAmount) * 100);

  uploadFileFinish(id) {
    this.fileLoading = false;
    this.loadFile = false;
    this.uploadFinish.emit(id);
  }

}
